<ul class="media-box-list padding-top-10">
	<li uiSrefActive="active">
		<a class="media-video-item theme-accent-txt" uiSref="portal.media.all" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_AllVideos' | translate }}</span>
		</a>
	</li>
	<li uiSrefActive="active" [ngClass]="{'animated fadeIn': MediaMenuService.isFetching}" [hidden]="!MediaMenuService.isCategoriesEnabled" *ngIf="MediaMenuService">
		<a class="media-video-item theme-accent-txt" uiSref="portal.media.browse" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_BrowseCategories' | translate }}</span>
		</a>
	</li>
	<li uiSrefActive="active" *vbAuthorizationKey="'media.videoOwner'">
		<a class="media-video-item theme-accent-txt" uiSref="portal.media.uploads" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_UserVideos' | translate }}</span>
		</a>
	</li>
	<li uiSrefActive="active">
		<a class="media-video-item theme-accent-txt" uiSref="portal.media.my-playlists" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_Menu_Playlists' | translate }}</span>
		</a>
	</li>
	<li uiSrefActive="active" [ngClass]="MediaMenuService.isFetching ? 'animated fadeIn' : ''" [hidden]="!MediaMenuService.isMySubscriptionEnabled">
		<a class="media-video-item theme-accent-txt" uiSref="portal.media.my-subscriptions" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label"> {{ 'Media_MySubscriptions' | translate }} </span>
		</a>
	</li>
	<li uiSrefActive="active" *vbAuthorizationKey="'media.add'">
		<a class="media-video-item theme-accent-txt" uiSref="portal.media.expirations" [uiOptions]="{ inherit: false }" uiSrefActive="active">
			<span class="text-label">{{ 'Media_Expirations' | translate }}</span>
		</a>
	</li>
</ul>
