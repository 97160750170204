import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import './search-header-dropdown.less';

@Component({
	selector: 'search-header-dropdown',
	templateUrl: './SearchHeaderDropdown.Component.html'
})

export class SearchHeaderDropdownComponent implements OnInit, OnDestroy {

	private themeServiceSub: Subscription;

	public themeStyleOverrides: IRules;

	constructor(
		private ThemeService: ThemeService
	) { }

	public ngOnInit(): void {
		this.themeServiceSub = this.ThemeService.brandingSettings$.subscribe(brandingSettings => {
			this.applyCssRules(brandingSettings);
		});
	}

	public ngOnDestroy(): void {
		this.themeServiceSub?.unsubscribe();
	}

	public applyCssRules(brandingSettings: BrandingSettings): void {
		const headerBackgroundColor = brandingSettings.headerSettings.backgroundColor;
		this.themeStyleOverrides = {
			[`
				search-header-dropdown .search-cell vb-ui-typeahead-angular input,
				search-header-dropdown .search-cell .main-nav-btn
			`]: {
				'border-top-color': `${headerBackgroundColor}`,
				'border-bottom-color': `${headerBackgroundColor}`
			},
			'search-header-dropdown .search-cell vb-ui-typeahead-angular input': {
				'border-left-color': `${headerBackgroundColor}`
			},
			'search-header-dropdown .search-cell .main-nav-btn': {
				'border-right-color': `${headerBackgroundColor}`,
				'border-left-color': `${headerBackgroundColor}`
			}
		};
	}

}
