import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { MediaStateService } from '../MediaState.Service';

import './playlist-detail.less';
import { PlaylistDetailsTableComponent } from './PlaylistDetailsTable.Component';
@Component({
	selector: 'playlist-detail',
	templateUrl: './PlaylistDetail.Component.html'
})
export class PlaylistDetailComponent implements OnInit {
	@Input() public canEdit: boolean;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public mediaFeatures: any;
	@Input() public playlist: any;
	@Input() public userId: string;

	public canShare: boolean;
	public isTableViewMode: boolean;

	@ViewChild(PlaylistDetailsTableComponent)
	public tableComp: PlaylistDetailsTableComponent;

	constructor(
		public MediaStateService: MediaStateService
	) {}

	public ngOnInit(): void {
		this.canShare = this.canEdit;
		this.isTableViewMode = this.MediaStateService.isTableViewMode;
	}

	public removeSelectedVideos(): void {
		this.tableComp.removeSelectedVideos();
	}

}
