<ng-template [vbCssRules]="cssRules$ | async"></ng-template>
<header class="flex-container theme-accent-border" [ngClass]="[
			styles.header,
			styles.marginBottom
		]">
			<div class="flex-fill" [ngClass]="styles.headerPrimaryWrapper">
				<h3 [ngClass]="styles.title">
						<a *ngIf="headerSref" [ngClass]="styles.titleLink" [title]="headerText" [uiSref]="headerSref" [uiParams]="headerSrefParams">
							{{headerText}}
						</a>
						<span *ngIf="!headerSref" [title]="headerText">
							{{headerText}}
						</span>
						<div flex="1"></div>
				</h3>
			</div>
			<aside [ngClass]="styles.browseAll" *ngIf="headerSref">
				<a class="theme-primary-txt" uiSref="{{ headerSref }}" [uiParams]="headerSrefParams">
					<small>{{ 'Dashboard_SeeAll' | translate }}</small>
				</a>
			</aside>
</header>


<no-carousel-results *ngIf="!hasItems">
</no-carousel-results>

<vb-flickity-carousel class="dashboard-carousel" *ngIf="hasItems" [flickityConfig]="flickityConfig" (onReady)="onCarouselReady($event.carousel)">

	<ng-content></ng-content>

</vb-flickity-carousel>
