import { Component } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, AgEvent, GridApi, RowNode } from '@ag-grid-community/core';

import styles from './TogglesCellRenderer.module.less';

export interface ToggleClickEvent extends AgEvent {
	data: any,
	field: string
}

export const TOGGLES_CELL_TOGGLE: string = 'toggles';

@Component({
	selector: 'toggles-cell-renderer',
	template: `
	<div [ngClass]="styles.toggleContainer">
		<vb-toggle-button [(ngModel)]="data[field]"
			(change)="onToggle()">
		</vb-toggle-button>
	</div>
	`
})
export class TogglesCellRendererComponent implements ICellRendererAngularComp {
	public data: any;
	public field: string;
	public readonly styles = styles;

	private gridApi: GridApi;
	private rowNode: RowNode;

	public refresh(params: ICellRendererParams): boolean {
		this.updateCell(params);
		return true;
	}
	public agInit(params: ICellRendererParams): void {
		this.updateCell(params);
	}

	public onToggle(): void {
		this.gridApi.dispatchEvent({
			data: this.rowNode.data,
			field: this.field,
			type: TOGGLES_CELL_TOGGLE
		} as ToggleClickEvent);
	}

	private updateCell(params: ICellRendererParams) {
		this.data = params.data;
		this.field = params.colDef.field;
		this.gridApi = params.api;
		this.rowNode = params.node;
	}
}
