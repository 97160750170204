<div [hidden]="isApprovalApproved
		&& videoIsActive
		&& !isProcessingFailed
		&& !isProcessing" class="video-thumbnail-status" [ngClass]="{
		fill: !videoThumbnailUri && !videoIsActive,
		error: isProcessingFailed,
		rejected: isApprovalRejected,
		pending: isApprovalPending,
		inactive: !videoIsActive
				&& (!approvalStatus || isApprovalApproved),
		processing: isProcessing || isUploading
		}">
	<span *ngIf="!videoIsActive
			&& (!approvalStatus || isApprovalApproved)
			&& !isProcessing
			&& !isUploading" class="status-inactive glyphicons eye_close"></span>

	<span *ngIf="isProcessingFailed" class="glyphicons circle_exclamation_mark video-error">
	</span>

	<span *ngIf="approvalStatus
			&& !isApprovalApproved
			&& !isApprovalRejected
			&& !isUploading
			&& !isProcessingFailed
			&& !isProcessing" class="glyphicons cogwheel rotate-360-slow stop-animation-flickering">
	</span>

	<span *ngIf="isApprovalRejected" class="glyphicons remove_2">
	</span>

	<div *ngIf="isProcessing || isUploading" class="status-processing">
		<vb-loading-spinner [block]="true" [iconClass]="'vb-icon vb-icon-camera-plus'" [msg]="(isUploading ? 'Video_Playback_VideoUploading' : 'Video_Playback_VideoProcessing') | translate" [msgDisplayBelowSpinner]="true" [size]="spinnerSize">
		</vb-loading-spinner>
	</div>
</div>
