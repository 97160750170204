import {
	Component,
	OnInit
} from '@angular/core';
import { map, Observable, Subscription } from 'rxjs';

import { MainHeaderPositionService } from './navigation/mainHeader/MainHeaderPosition.Service';

@Component({
	selector: 'portal',
	template: `
		<div id="global-nav"
			class="global-nav-wrap navbar-fixed-top theme-accent-txt">
			<main-header></main-header>
		</div>
		<div
			class="padding-top-1 height-full"
			ui-view
			[ngStyle]="{
				'margin-top': (headerHeight$ | async)
			}">
		</div>
		<branding-preview-banner></branding-preview-banner>
	`
})
export class PortalComponent implements OnInit {
	public headerHeight$: Observable<string>;

	private subscription: Subscription;

	constructor(
		private MainHeaderPosition: MainHeaderPositionService
	) {}

	public ngOnInit(): void {
		this.headerHeight$ = this.MainHeaderPosition.height$
			.pipe(
				map(val => (val ? val - 2 : 0) + 'px')
			);
	}
}
