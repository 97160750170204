import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { addQueryParams, urlEncode } from 'rev-shared/util/Util.Service';
import { formatUrlTimespan } from 'rev-shared/date/DateFormatters';
import { isChrome } from 'rev-shared/util/UserAgentUtil';
import { isString } from 'rev-shared/util';

import styles from './vb-ui-share-link.module.less';

@Component({
	selector: 'vb-ui-share-link',
	templateUrl: './VbUiShareLink.Component.html'
})
export class VbUiShareLink implements OnChanges {
	@Input() private playbackPosition: number;
	@Input() public video: any;
	@Input() public enableSpark: boolean;
	@Input() public hideLinkOptions: boolean;
	@Input() public externalLinkUrl: string;
	@Input() public externalEmailBody: string;
	@Input() public externalEmailSubject: string;

	private readonly isChrome: boolean = isChrome();
	public readonly styles = styles;

	private form: any;
	public startAt: number = 0;
	public startAtManualUpdate: boolean;
	public isAutoplay: boolean;
	public isVideoOnlyLink: boolean = false;
	public showPassword: boolean;
	public showStartAt: boolean;

	constructor(
		private TranslateService: TranslateService,
		private Dialog: DialogService
	) { }

	public ngOnChanges(changes: SimpleChanges){
		if (changes.playbackPosition && !this.form?.startAt?.$dirty) {
			this.startAt = this.playbackPosition;
		}
	}

	public onToggleStartAt(): void {
		this.startAt = this.showStartAt ? this.playbackPosition : 0;
	}

	public get linkUrl(): string {
		if (this.externalLinkUrl) {
			return this.externalLinkUrl;
		}
		const url = addQueryParams(
			`${window.location.origin}/sharevideo/${this.video.id}`, {
				startAt: this.showStartAt && this.startAt >= 0 ? formatUrlTimespan(this.startAt) : undefined,
				autoplay: this.isAutoplay || undefined,
				videoOnly: this.isVideoOnlyLink || undefined
			});
		return [url, this.getPasswordMsg()].join('\n\n').trim();
	}


	public onEmailClick(): void {
		const mailTo: string = 'mailto:?' + urlEncode({
			subject: this.externalEmailSubject || this.TranslateService.instant('Media_Videos_Sharing_Email_Subject'),
			body: this.externalEmailBody || this.videoEmailBody()
		});

		this.isChrome
			? this.mailToRedirect(mailTo) // chrome doesn't like mailto via window.open()
			: this.mailToNewWindow(mailTo);
	}

	protected shareToSparkInternal(): void {
		const passwordMessage = this.getPasswordMsg();

		this.Dialog.getDialog('ShareVideoToSpark').open({
			initialState: {
				message: passwordMessage.length ? passwordMessage.trim() : null,
				title: this.video.title,
				videoUrl: this.linkUrl
			}
		});
	}

	private getPasswordMsg(): string {
		return this.video.password && this.showPassword ?
			`${this.TranslateService.instant('Password')}: ${this.video.password}` : '';
	}

	private mailToNewWindow(mailTo: string): void {
		const mailToWindow = window.open(mailTo, 'shareMailToWindow');

		if (mailToWindow && mailToWindow.open && !mailToWindow.closed) {
			mailToWindow.close();
		}
	}

	private mailToRedirect(mailTo: string): void {
		window.location.href = mailTo;
	}

	private videoEmailBody(): string {
		const description = isString(this.video.plainTextDescription) ? ' - ' + this.video.plainTextDescription : '';

		return `${this.TranslateService.instant('Media_Videos_Sharing_Email_Intro')}\n\n${this.video.title}${description}\n${this.linkUrl}`;
	}
}
