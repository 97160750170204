<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>
<nav class="main-nav" role="navigation" transitionBackground [transitionBackground]="MainHeaderPosition.isTransparencyActive" [transitionBackgroundClass]="'theme-header-bg theme-accent-border'">

	<section class="navbar">
		<mobile-menu [hidden]="isRegisteredGuest" [theme]="currentTheme" [headerSettings]="headerSettings" [themeSettings]="themeSettings" [isAnalystAdmin]="isAnalystAdmin" [showManageSubscriptions]="showManageSubscriptions" [landingState]="landingState">
		</mobile-menu>

		<div class="brand-cell" flex="logo">
			<div *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
				<a [attr.aria-label]="('Application_CompanyLogo' | translate) + ' - ' + ('Home' | translate)" href="#/">
					<branding-logo></branding-logo>
				</a>
			</div>
			<div *vbAuthorizationKey="['authenticated', 'distribution.authenticated']; fallback true">
				<span *ngIf="guestPortalRestricted; else guestPortalAllowed;" class="navbar-brand">
					<branding-logo></branding-logo>
				</span>
				<ng-template #guestPortalAllowed>
					<a [attr.aria-label]="('Application_CompanyLogo' | translate) + ' - ' + ('Home' | translate)" [href]="'#/guest'" class="navbar-brand" [tabindex]="0">
						<branding-logo></branding-logo>
					</a>
				</ng-template>
			</div>
		</div>

		<div class="flexDisplay search" flex-justify="end" flex-basis="auto" flex="15">
			<div *vbAuthorizationKey="'distribution.authenticated'; fallback true">
				<search-header-dropdown *ngIf="!guestPortalRestricted" role="search"></search-header-dropdown>
			</div>

			<go-live [bgNone]="true" [dropdownCssClass]="'netrix-dropdown'" [iconCssClass]="'theme-header-txt'" [showRemove]="true">
			</go-live>

			<div class="dropdown upload-cell netrix-dropdown" dropdown #headerUploadMenuDropdown="bs-dropdown" [insideClick]="true" *vbAuthorizationKey="'media.add'">
				<button [attr.aria-label]="('Upload' | translate) + ' - ' + ('UI_Menu' | translate)" class="dropdown-toggle btn main-nav-btn upload bg-none" [ngClass]="{ open: headerUploadMenuDropdown.isOpen}" dropdownToggle>
					<span class="glyphicons animated theme-header-txt" [ngClass]="{
							'disk_open': !headerUploadMenuDropdown.isOpen,
							'remove_2 rotate-360-once': headerUploadMenuDropdown.isOpen
						}">
					</span>
				</button>
				<div class="dropdown-menu navdrop-menu upload-media" *dropdownMenu>
					<upload-menu (onCloseUploadMenu)="headerUploadMenuDropdown.hide()">
					</upload-menu>
				</div>
			</div>

			<notifications-menu-button *ngIf="isUserAuthenticated" class="notification-cell count-bubble" [alwaysShowIcon]="true" [dropdownCssClass]="'netrix-dropdown'" [glyphiconCssClass]="'theme-header-txt'" [showRemove]="true">
			</notifications-menu-button>
		</div>

	</section>
</nav>
