import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { INotification } from 'rev-portal/notifications/Notification.Contract';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { UserNotificationsService } from 'rev-portal/notifications/UserNotifications.Service';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import './NotificationsMenuContent.less';

@Component({
	selector: 'notifications-menu-content',
	templateUrl: './NotificationsMenuContent.Component.html'
})

export class NotificationsMenuContentComponent implements OnInit {
	@Output() public close: EventEmitter<void> = new EventEmitter<void>();
	public themeStyleOverrides: IRules;

	constructor(
		private UserNotifications: UserNotificationsService,
		private ThemeService: ThemeService
	) {
	}

	public ngOnInit(): void {
		this.addStyleTag();
	}

	private addStyleTag(): void {
		const accentColor = this.ThemeService.brandingSettings.themeSettings.accentColor;
		this.themeStyleOverrides = {
			'vb-notification .notification-complete .glyphicons': {
				'color': `${accentColor} !important`
			}
		};
	}

	public get unreadNotifications(): INotification[] {
		return this.UserNotifications.unreadNotifications;
	}

	public get allNotifications(): INotification[] {
		return this.UserNotifications.notifications;
	}

	public markAsRead(notificationId: string): void {
		this.UserNotifications.markAsRead(notificationId);
		this.contentClosed();
	}

	public markAllAsRead(): void {
		this.UserNotifications.markAllAsRead();
		this.contentClosed();
	}

	public contentClosed(): void {
		this.close.emit();
	}
}
