import { Component, DoCheck, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { VideoChaptersModel } from './VideoChaptersModel';

import { isNumber } from 'rev-shared/util';

import './video-chapter-renderer.less';

const defaultImage = '/img/chapter-placeholder.svg';

@Component({
	selector: 'video-chapter-renderer',
	template: ''
})
export class VideoChapterRenderer implements DoCheck, OnChanges {
	@Input() public chapterInfo: VideoChaptersModel;
	@Input() public currentTime: number; // milliseconds

	@HostBinding('innerHTML')
	public backgroundImageStyle: SafeHtml | string;
	public slideUrl: string;

	constructor(
		private sanitzer: DomSanitizer
	) {}

	public ngDoCheck(): void {
		const currentChapter = this.chapterInfo.currentChapter;
		const newSlideUrl = currentChapter?.url || defaultImage;

		if (newSlideUrl !== this.slideUrl) {
			this.slideUrl = newSlideUrl;
			this.backgroundImageStyle = this.getbackgroundImageStyle();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.currentTime && isNumber(this.currentTime)) {
			this.chapterInfo.updatePlaybackTime(this.currentTime);
		}
	}

	/**
	 * This approach is taken instead of a simpler style property binding to work around Chrome requesting the same image multiple times.
	 */
	public getbackgroundImageStyle(): SafeHtml {
		return this.sanitzer.bypassSecurityTrustHtml(`
			<style>
				video-chapter-renderer {
					background-image: url("${this.slideUrl}") !important;
				}
			</style>
		`);
	}
}
