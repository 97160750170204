
<div flex="fill" [ngClass]="mainContentClass">
	<ng-content select="[slot='mainContent']"></ng-content>
</div>

<vb-ui-sidebar #sidebar [hidden]="hideSidebar" [sidebarConfig]="sidebarConfig" [themed]="themed" (onToggle)="onSidebarToggleInternal($event)">

	<div slot="headerControls">
		<ng-content select="[slot='sidebarHeaderControls']"></ng-content>
	</div>

	<div slot="panelBody">
		<ng-content select="[slot='sidebarPanel']"></ng-content>
	</div>
</vb-ui-sidebar>
