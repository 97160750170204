import { Component, Input, OnInit } from '@angular/core';

import styles from './media-upload-btn.module.less';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { ThemeSettings } from 'rev-portal/branding/BrandingSettings.Contract';

@Component({
	selector: 'media-upload-btn',
	templateUrl: './MediaUploadBtn.Component.html'
})
export class MediaUploadBtnComponent implements OnInit {
	@Input() public btnText: string;
	@Input() public teamId: string;

	public readonly styles = styles;
	public themeStyles: IRules;

	constructor(
		private ThemeService: ThemeService
	) {}

	public ngOnInit(): void {
		this.themeStyles = {
			'.dropdown-toggle:before': {
				'border-bottom-color': this.themeSettings.accentColor
			}
		};
	}

	public get themeSettings(): ThemeSettings {
		return this.ThemeService.brandingSettings.themeSettings;
	}
}
