import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { NgModel } from '@angular/forms';

import { InsightSearchHelper } from 'rev-portal/search/InsightSearchHelper.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { noop } from 'rev-shared/util';

import { AdminCategoryService } from './AdminCategory.Service';

import styles from './category.module.less';

interface IEditCategoryStatus {
	categoryNameInUse?: boolean;
	error?: boolean;
	saving?: boolean;
}

@Component({
	selector: 'edit-category',
	templateUrl: './EditCategory.Component.html'
})
export class EditCategoryComponent implements OnInit {
	@Input() public accountId: string;
	@Input() public category: any;
	@Input() public cancelSref: { name: string, params: any };

	@ViewChild('categoryName') public categoryName: NgModel;
	@ViewChild(VbConfirmationDialogComponent) public deleteConfirmation: VbConfirmationDialogComponent;
	public readonly styles = styles;

	public accessControlInsightOptions: any;
	public status: IEditCategoryStatus = {};

	constructor(
		private $state: StateService,
		private AdminCategoryService: AdminCategoryService,
		private InsightSearchHelper: InsightSearchHelper
	) {
	}

	public ngOnInit(): void {
		this.accessControlInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: true,
			groups: true,
			getEntityIds: () => this.category.categoryPolicyItems?.map(i => i.id) || []
		});
	}

	public removeCategory(): void {
		this.deleteConfirmation.open().result
			.then(() => {
				this.AdminCategoryService.removeCategory(this.accountId, this.category.id)
					.then( () => this.$state.go(this.cancelSref.name, this.cancelSref.params))
					.catch(e => {
						console.error('Error deleting category: ', e);
					});
			}, noop);
	}

	public saveCategory(): void {
		this.status = { saving: true };

		if (!this.category.restricted) {
			this.category.categoryPolicyItems = [];
		}

		( this.category.id ?
			this.AdminCategoryService.saveCategory(this.category) :
			this.AdminCategoryService.createCategory(this.category)
		)
			.then(() => this.$state.go(this.cancelSref.name, this.cancelSref.params))
			.catch(err => {
				this.status = { error: true };

				if (err.hasIssue('CategoryNameInUse')) {
					this.categoryName.control.setErrors({ isCategoryNameInUse: true });
				}

				if (err.hasIssue('CategoryNameTooLong')) {
					this.categoryName.control.setErrors({ vbMaxLength: true });
				}
			});
	}
}
