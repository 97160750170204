<widget-wrapper [headerText]="'Title_Categories' | translate" [headerLink]="'portal.media.browse'">

	<a uiSref="portal.media.browse" vbUiBtnPrimaryNgx [ngClass]="styles.seeAllLink">
		{{ 'Dashboard_SeeAll' | translate }}
	</a>

	<vb-loading-spinner [block]="true" [size]="'large'" [hidden]="!isFetching">
	</vb-loading-spinner>

	<section [hidden]="isFetching" [ngClass]="{'animated-extended fadeIn': !isFetching}">
		<ul [ngClass]="styles.listWrap" *ngIf="hasCategories">
			<li *ngFor="let item of categories" [ngClass]="styles.listItem">
				<a [ngClass]="styles.listItemText" uiSref="portal.media.category-detail" [uiParams]="{categoryId: item.id}">
					<span>
						{{ item.name }}
					</span>
				</a>
			</li>
			<li *ngIf="uncategorizedCategory" [ngClass]="styles.listItem">
				<a [ngClass]="styles.listItemText" uiSref="portal.media.category-detail" [uiParams]="{categoryId: uncategorizedCategory.id}">
					<span [ngClass]="styles.listItemText">
						{{ 'Media_Uncategorized' | translate }}
					</span>
				</a>
			</li>
		</ul>
		<section [hidden]="hasCategories" [ngClass]="styles.noResultsContainer" class="text-align-center padding-top-15">
			<span class="control-label">
				{{ 'NoCategoriesAvailable' | translate }}
			</span>
		</section>
	</section>
</widget-wrapper>
