<vb-confirmation-dialog #applyBulkHoldConfirmation [title]="'Video_LegalHold' | translate " [message]="'Video_LegalHold_BulkApply_Dialog_Message' | translate : { '0': VideoSelectionModel.selectionCount } " [cancelText]="'Cancel' | translate " [actionText]="'Save' | translate ">
</vb-confirmation-dialog>

<vb-confirmation-dialog #removeBulkHoldConfirmation [title]="'Video_LegalHold' | translate " [message]="'Video_LegalHold_BulkRemove_Dialog_Message' | translate : { '0': VideoSelectionModel.selectionCount } " [cancelText]="'Cancel' | translate " [actionText]="'Save' | translate ">
</vb-confirmation-dialog>

<div class="sidebar-item-light sidebar" *ngIf="isAccountAdminUser" [hidden]="hasMixedLegalHoldValues" dropdown [insideClick]="true">
	<a id="legal-hold-dropdown" [ngClass]="{legalhold: isOnLegalHold !== undefined}" [attr.aria-label]="'Admin_LegalHold' | translate " dropdownToggle [tooltip]="'Admin_LegalHold' | translate" placement="left" container="body" [delay]="1000">
		<span class="glyphicons lock"></span>
	</a>
	<div class="dropdown-menu sidebar-menu align-right upload-media" *dropdownMenu role="menu">
		<div class="list-group sidebar-dropdown util-nobreak" role="group" [attr.aria-label]="'LegalHold_Menu' | translate ">
			<button class="menu-link" role="menuitem" [hidden]="!(hasVideosSelected && !isOnLegalHold)" (click)="lockVideos()">
					{{ 'Video_LegalHold_Apply' | translate }}
			</button>
			<button class="menu-link" role="menuitem" [hidden]="!(hasVideosSelected && isOnLegalHold)" (click)="unLockVideos()">
					{{ 'Video_LegalHold_Remove' | translate }}
			</button>
			<span class="menu-link" role="menuitem" [hidden]="hasVideosSelected">
					{{ 'Video_LegalHold_NoVideos' | translate }}
			</span>
		</div>
	</div>
</div>
