import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { IImageComponentMetadata, IImageData } from 'rev-shared/ui/image-selector/ImageSelector.Contract';
import { addQueryParams } from 'rev-shared/util/Util.Service';
import { formatUrlTimespan } from 'rev-shared/date/DateFormatters';
import { mapObject } from 'rev-shared/util';

import styles from 'rev-shared/media/shareLink/vb-ui-share-link.module.less';
import { EmbedSize } from './EmbedSize';


export enum Menus {
	Controls = 'controls',
	Layout = 'layout',
	Playback = 'playback',
	Styling = 'styling'
}

const embedOptionsDisplayContext = {
	startAt: true,
	[Menus.Controls]: {
		allControls: true,
		centerButtons: true,
		playbar: true,
		cc:  true,
		chapters: true,
		settings: true,
		fullscreen: true
	},
	[Menus.Styling]: {
		playerAccentColor: true
	},
	[Menus.Layout]: {
		popoutPlayer: true,
		size: true
	},
	[Menus.Playback]: {
		loop: true,
		autoPlay: true,
		chapters: true,
		cc: true
	}
};

interface IEmbedOptionsDisplayContext {
	startAt: boolean;
	[Menus.Controls]: any | boolean;
	[Menus.Styling]: any | boolean;
	[Menus.Layout]: any | boolean;
	[Menus.Playback]: any | boolean;
}

@Component({
	selector: 'vb-ui-share-embed',
	templateUrl: './VbUiShareEmbed.Component.html'
})
export class VbUiShareEmbedComponent implements OnInit, OnChanges {
	@Input() public defaultAutoplay: boolean;
	@Input() public defaultSizeName: string;
	@Input() public playbackPosition: number;
	@Input() public sizes: EmbedSize[];
	@Input() public video: any;
	@Input() public playlist: any;
	@Input() public embedOptionsDisplayContext: IEmbedOptionsDisplayContext;
	@ViewChild('widthInput') public widthInput: NgModel;
	@ViewChild('startAtInput') public startAtInput: NgModel;

	public readonly styles = styles;
	public readonly menus = Menus;

	public imageComponentMetadata: IImageComponentMetadata = {
		requiredValidation: false,
		showResetButton: true,
		readOnly: false
	};

	public controls = {
		centerButtons: true,
		closedCaptions: true,
		fullscreen: true,
		playBar: true,
		settings: true
	};

	public playback = {
		autoplay: false,
		forceClosedCaptions: false,
		hideChapters: false,
		loopVideo: false
	};

	public accentColor: string;
	public activeMenu: string;
	public allControlsToggle: boolean = true;
	public ccEnabled: boolean;
	public chaptersEnabled: boolean;
	public embedCode: string;
	public imageData: IImageData;
	public isPopupPlayer: boolean = false;
	public showStartAt: boolean;
	public startAt: number = 0;
	public sizeName: string;
	public useAccentColor: boolean;
	public width: number;
	public height: number;
	public optionsDisplayContext: IEmbedOptionsDisplayContext;
	// Will be used later
	//private logoHasLink: boolean;
	//private logoLink: string;
	//private useLogo: boolean;

	constructor(
		public ThemeService: ThemeService
	) { }

	public ngOnInit(): void {
		this.optionsDisplayContext = { ...embedOptionsDisplayContext, ...this.embedOptionsDisplayContext };
		if (this.video) {
			this.imageData = { url: this.ThemeService.brandingSettings.themeSettings.logoUri };
			this.accentColor = this.ThemeService.accentColor;
			this.playback.autoplay = this.defaultAutoplay;
			this.ccEnabled = this.video.closedCaptionsEnabled || this.video.subtitles.length;
			this.chaptersEnabled = this.video.chapterInfo?.chapters.length;
		}

		this.selectSize(this.defaultSizeName);
		this.updateEmbedCode();

	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.playbackPosition && !this.startAtInput?.dirty) {
			this.startAt = this.playbackPosition;
			this.updateEmbedCode();
		}
	}

	public toggleAll() {
		Object.keys(this.controls).forEach(t => this.controls[t] = this.allControlsToggle);
		this.updateEmbedCode();
	}

	public checkToggle() {
		this.allControlsToggle = !Object.values(this.controls).includes(false);
		this.updateEmbedCode();
	}

	public onSizeMenuChange(): void {
		if (this.sizeName !== 'custom' && this.sizeName !== 'responsive') {
			this.selectSize(this.sizeName);
		}
		this.updateEmbedCode();
	}

	public onToggleStartAt(): void {
		this.startAt = this.showStartAt ? this.playbackPosition : 0;
		this.updateEmbedCode();
	}


	public onEmbedWidthChange(): void {
		const preset = this.sizes.find(size => size.width === this.width);
		if (preset) {
			this.height = preset.height;
			this.sizeName = preset.name;
		} else {
			this.sizeName = 'custom';
			this.height = Math.round(this.width * 9 / 16);
		}
		this.updateEmbedCode();
	}

	public colorChange() {
		this.updateEmbedCode();
	}

	public activateMenu(menu: string): void {
		this.activeMenu = this.activeMenu == menu ? '' : menu;
	}

	private updateEmbedCode(): void {
		this.embedCode = this.sizeName === 'responsive' ? this.getResponsiveEmbedCode() : this.getEmbedCode(this.width, this.height);
	}

	private getResponsiveEmbedCode(): string {
		const iframeHtml = this.getEmbedCode('100%', '100%', 'position: absolute; left: 0; top: 0;');
		return `<div style="position: relative; height: 0; padding-bottom: 56.25%;">${iframeHtml}</div>`;
	}

	private getEmbedCode(width: number | string, height: number | string, style: string = ''): string {
		const params = this.video?.id ? this.getVideoEmebedParams() : this.getPlaylistEmebedParams();
		const url = addQueryParams(`${window.location.origin}/embed`, params);
		return `<iframe width="${width}" height="${height}" src="${url}" style="${style || ''}" frameborder="0" allowfullscreen></iframe>`;
	}

	private selectSize(name: string): void {
		const { width, height } = (this.sizes || []).find(size => size.name === name) || {};
		this.width = width;
		this.height = height;
		this.sizeName = name;
	}

	private getVideoEmebedParams(): any {
		return mapObject({
			id: this.video.id,
			autoplay: this.playback.autoplay,
			startAt: this.showStartAt ? formatUrlTimespan(this.startAt) : undefined,
			placeholder: this.isPopupPlayer,
			accent: this.useAccentColor && this.accentColor,
			noCenterButtons: !this.controls.centerButtons,
			noCc: this.ccEnabled && !this.controls.closedCaptions,
			forceClosedCaptions: this.ccEnabled && this.playback.forceClosedCaptions,
			noFullscreen: !this.controls.fullscreen,
			noPlayBar: !this.controls.playBar,
			noSettings: !this.controls.settings,
			noChapters: this.playback.hideChapters && this.chaptersEnabled,
			loopVideo: this.playback.loopVideo
		}, x => x || undefined);
	}

	private getPlaylistEmebedParams(): any {
		return mapObject({
			playlist: this.playlist.id,
			autoplay: this.playback.autoplay
		}, x => x || undefined);
	}
}
