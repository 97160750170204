<div class="row" [hidden]="hideTable()">
	<div class="col-sm-12">
		<vb-media-table #vbMediaTable class="file-list-wrap stop-3d-transform" [columns]="videoSearchResults.mediaState.columns" [sortAscending]="videoSearchResults.sortAscending" [sortField]="videoSearchResults.sortField" (onColumnHeaderClick)="videoSearchResults.sortVideos($event.field, $event.isDefaultDescending)">
			<div class="file-list">
				<div [hidden]="baseStateName === 'portal.media.browse'" class="file-list-row file-list-header flex-container flex-table">
					<vb-media-table-select-column-header [field]="'selection'" [ariaLabel]="'SelectAll' | translate" [videoSelectionModel]="videoSearchResults.selectionModel" class="table-cell-order padding-left-8" flex="none">
					</vb-media-table-select-column-header>

					<vb-media-table-column-header [field]="'title.sort'" [header]="'Media_TableColumn_Name' | translate" class="flex-fill flex-3">
					</vb-media-table-column-header>

					<vb-media-table-column-header [field]="'whenUploaded'" [header]="'Media_Videos_UploadDate' | translate" [isDefaultDescending]="true" [mobileAlign]="'right'">
					</vb-media-table-column-header>

					<vb-media-table-column-header [field]="'ownerName.sort'" [header]="'Media_Videos_Owner' | translate" [isMobileHidden]="true">
					</vb-media-table-column-header>

					<vb-media-table-column-header [field]="'duration'" [header]="'Media_Videos_Time' | translate" [isMobileHidden]="true">
					</vb-media-table-column-header>

					<vb-media-table-column-header [field]="'viewCount'" [header]="'Media_Videos_Views' | translate" [isMobileHidden]="true">
					</vb-media-table-column-header>

					<vb-media-table-column-header [field]="'lastViewed'" [header]="'Media_Videos_LastViewedDate' | translate" [isMobileHidden]="true">
					</vb-media-table-column-header>

					<vb-media-table-column-header [field]="'deleteOnExpiration'" [header]="'Media_Videos_ExpirationStatus' | translate" [isMobileHidden]="true">
					</vb-media-table-column-header>

					<vb-media-table-column-header [field]="'expiryDate'" [header]="'Media_Videos_ExpirationEffectiveDate' | translate" [mobileAlign]="'right'">
					</vb-media-table-column-header>
				</div>
				<div *ngFor="let category of videoSearchResults.categories">

					<div class="file-list-row flex-container flex-table">
						<a class="flex-fill table-cell primary-cell flex-3" uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }">
							<div class="file-icon">
								<div class="file-folder-icon">
									<svg version="1.1" x="0px" y="0px" viewBox="0 0 48 33" enable-background="new 0 0 48 33">
										<polygon points="48,5.8 21.7,5.8 16,0 0,0 0,33 48,33 "/>
									</svg>
								</div>
							</div>

							<span class="file-name">{{ category.isUncategorized ?
								('Media_Uncategorized' | translate) : category.name }}
							</span>

							<span class="glyphicons lock margin-right-5" [ngClass]="videoSearchResults.styles.icon" [hidden]="!category.restricted">
							</span>
						</a>
						<div class="table-cell mobile-hidden" flex-justify="end">
							<a *ngIf="category.canEdit" [ngClass]="videoSearchResults.styles.actionBtn" vbUiBtnSecondary uiSref="portal.edit-category" [uiParams]="{categoryId: category.id}">
								{{ 'Edit' | translate }}
							</a>
						</div>
					</div>
				</div>
				<div *ngIf="videoSearchResults.uncategorizedCatEntry">
					<div class="file-list-row flex-container flex-table">
						<a class="flex-fill table-cell primary-cell flex-3" uiSref="portal.media.category-detail" [uiParams]="{categoryId: videoSearchResults.uncategorizedCatEntry.id}">
							<div class="file-icon">
								<div class="file-folder-icon">
									<svg version="1.1" x="0px" y="0px" viewBox="0 0 48 33" enable-background="new 0 0 48 33">
										<polygon points="48,5.8 21.7,5.8 16,0 0,0 0,33 48,33 "/>
									</svg>
								</div>
							</div>
							<span class="file-name">
								{{'Media_Uncategorized' | translate}}
							</span>
						</a>
					</div>
				</div>
				<div *ngFor="let video of videoSearchResults.videos" class="file-list-row flex-container flex-table" [ngClass]="{
							'video-owner': userId === video.uploaderUserId,
							'uploading': isUploading(video),
							'not-ready': isUploading(video) || isProcessing(video),
							'processing-error': processingFailed(video),
							'inactive': video.isActive === false,
							'pending-approval': video.approval.status
								&& video.approval.status !== videoSearchResults.approvalStatusOptions.APPROVED
								&& video.approval.status !== videoSearchResults.approvalStatusOptions.REJECTED,
							'rejected': video.approval.status === videoSearchResults.approvalStatusOptions.REJECTED
						}">

					<a uiSref="portal.video" [uiParams]="{ videoId:video.id, config: videoSearchResults.videoPlaybackConfig }" [ngClass]="styles.videoLink" [attr.aria-label]="'Media_Video_GoToVideo' | translate : { '0' : video.title }">
					</a>
					<vb-media-table-column [field]="'selection'" class="table-cell-buttons" flex="none">
						<button *ngIf="videoSearchResults.mediaState.columns.selection > -1" class="btn btn-checkbox" type="button" role="checkbox" [attr.aria-checked]="!!videoSearchResults.selectionModel.isSelected(video) " (click)="videoSearchResults.selectionModel.toggleSelection(video)" [ngClass]="{'active': videoSearchResults.selectionModel.isSelected(video)}" [attr.aria-label]="video.title ">
							<span class="glyphicons ok_2"></span>
						</button>
					</vb-media-table-column>

					<vb-media-table-column [field]="'title.sort'" class="flex-fill flex-3 primary-cell thumbnail-cell">
						<div class="file-icon">
							<span class="glyphicons lock" [hidden]="!(video.legalHold)">
							</span>
							<span class="glyphicons volume_up" [hidden]="!(video.hasAudioOnly)"></span>
							<a class="image-wrapper" uiSref="portal.video" [attr.aria-label]="'Media_Video_GoToVideo' | translate : { '0' : video.title }" [uiParams]="{ videoId:video.id, config: videoSearchResults.videoPlaybackConfig }">
								<img [alt]="video.title" [src]="video.thumbnailUri||(video.hasAudioOnly?'/shared/img/audio-thumbnail.png':'/shared/img/default-thumbnail.png')" loading="lazy">
								<video-thumbnail-status [approvalStatus]="video.approval.status" [videoIsActive]="video.isActive" [videoStatus]="video.status" [videoThumbnailUri]="video.thumbnailUri">
								</video-thumbnail-status>

								<div class="video-status-public-password" *ngIf="videoSearchResults.isGuest && video.hasPassword" [uibTooltip]="'PasswordProtected' | translate " tooltipAppendToBody="true" tooltipPlacement="right">
									<span class="vb-icon vb-icon-password"></span>
								</div>
							</a>
						</div>

						<a class="file-name primary-cell-link" uiSref="portal.video" [uiParams]="{ videoId:video.id, config: videoSearchResults.videoPlaybackConfig }" [attr.aria-label]="'Media_Video_GoToVideo' | translate : { '0' : video.title }">
							{{video.title }}
						</a>
						<unlisted-video-indicator [isVideoUnlisted]="video.unlisted" [hasMediaEditAuth]="videoSearchResults.hasMediaEditAuth || videoSearchResults.hasEditVideoAuth(video)" [showOnlyIcon]="true">
						</unlisted-video-indicator>
						<video-360-indicator *ngIf="video.is360">
						</video-360-indicator>
					</vb-media-table-column>

					<vb-media-table-column [field]="'whenUploaded'" [mobileAlign]="'right'">
						<span class="mobile-hidden">{{ video.whenUploaded | vbDateTimeMedium }}</span>
						<span class="mobile-visible">{{ video.whenUploaded | date : 'mediumDate' }}</span>
					</vb-media-table-column>

					<vb-media-table-column [field]="'ownerName.sort'" [isMobileHidden]="true">
						<vb-profile-picture class="margin-right-10" [altTxt]="'UserProfile_ProfilePicture' | translate" [profileUrl]="video.ownerProfileImageUri">
						</vb-profile-picture>
						<span>{{ video.ownerName }}</span>
					</vb-media-table-column>

					<vb-media-table-column [field]="'duration'" [isMobileHidden]="true">
						<span [hidden]="!(video.isLive)">{{ 'Live' | translate }}</span>
						<span [hidden]="video.isLive">{{ (video.duration | vbTimespan) || 'NotAvailable' | translate
							}}</span>
					</vb-media-table-column>

					<vb-media-table-column [field]="'viewCount'" [isMobileHidden]="true">
						{{ video.viewCount > -1 ? video.viewCount : 0 }}
					</vb-media-table-column>

					<vb-media-table-column [field]="'lastViewed'" [isMobileHidden]="true">
						<span>{{ video.lastViewed | vbDateTimeMedium }}</span>
					</vb-media-table-column>

					<vb-media-table-column [field]="'deleteOnExpiration'" [isMobileHidden]="true">
						<span [hidden]="!(video.expiryDate)">{{ (video.deleteOnExpiration ?
							'Media_Videos_ExpirationStatus_DeletePending' :
							'Media_Videos_ExpirationStatus_ExpirationPending') | translate }}</span>
					</vb-media-table-column>

					<vb-media-table-column [field]="'expiryDate'" [mobileAlign]="'right'">
						<span>{{ video.expiryDate | date: 'mediumDate' }}</span>
					</vb-media-table-column>

					<speech-search-results-list *ngIf="video.innerHits && video.innerHits.length" [videoId]="video.id" [results]="video.innerHits" [query]="videoSearchResults.query">
					</speech-search-results-list>
				</div>
			</div>
		</vb-media-table>
	</div>
</div>
