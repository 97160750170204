import { isEqual, isObject, omit } from 'underscore';

export {
	chain,
	difference,
	escape,
	identity,
	isArray,
	isBoolean,
	isDate,
	isEqual,
	isFunction,
	isNumber,
	isObject,
	isString,
	isUndefined,
	keys,
	mapObject,
	noop,
	pick,
	sortBy
} from 'underscore';

export const isDefined = v => void 0 !== v;

export function equals(a: any, b: any): boolean {
	return isEqual(
		omitAngularJsProps(a),
		omitAngularJsProps(b)
	);
}

function omitAngularJsProps(obj: any): any {
	return omit(obj, (_value, key: string) => key.startsWith('$'));
}

export function deepMergeObjects(...args): any {
	return (args || []).reduce((output, current) => {
		if (!output) {
			return { ...current };
		}

		Object.entries(current)
			.forEach(([key, value]) => {
				const isMergeObjects: boolean = key in output && isObject(output[key]) && isObject(value);

				output[key] = isMergeObjects ?
					deepMergeObjects(output[key], value) :
					value;
			});

		return output;
	});
}

export function getKeysFromObjects(arr: any[]): any[] {
	return Array.from(new Set((arr || []).flatMap(obj => Object.keys(obj))));
}

export function collectionShallowEqual(array1: any[], array2: any[], mapFunc: (obj: any) => any): boolean {
	const length = array1?.length;
	if (length === array2?.length) {
		for (let key = 0; key < length; key++) {
			const [obj1, obj2] = mapFunc ? [mapFunc(array1[key]), mapFunc(array2[key])]
				: [array1[key], array2[key]];

			if (!objectShallowEqual(obj1, obj2)) {
				return false;
			}
		}
		return true;
	}
}

export function objectShallowEqual(object1: any, object2: any): boolean {
	const keys1 = Object.keys(object1 || {});
	const keys2 = Object.keys(object2 || {});

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}

	return true;
}

export function clamp(val: unknown, min: number, max: number): number {
	return Math.max(Math.min(+val, max), min);
}

export function uniq<T>(items: T[]): T[] {
	return [...new Set(items)];
}
