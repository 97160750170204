import { Component, ViewChild, OnInit } from '@angular/core';

import { MediaStateService } from 'rev-portal/media/MediaState.Service';
import { VideoSelectionModelService } from 'rev-portal/media/search/bulkEdit/VideoSelectionModel.Service';
import { VideoService } from 'rev-shared/media/Video.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { noop } from 'rev-shared/util';

import './legal-hold.less';

const timeoutInMilliseconds: number = 2000;

@Component({
	selector: 'legal-hold-sidebar',
	templateUrl: './LegalHoldSidebar.Component.html'
})

export class LegalHoldSidebarComponent {
	public videos: any[];
	public isAccountAdminUser: boolean;

	@ViewChild('applyBulkHoldConfirmation')
	public applyBulkHoldConfirmation: VbConfirmationDialogComponent;
	@ViewChild('removeBulkHoldConfirmation')
	public removeBulkHoldConfirmation: VbConfirmationDialogComponent;

	constructor(
		private MediaStateService: MediaStateService,
		private SecurityContext: SecurityContextService,
		private UserContext: UserContextService,
		public VideoSelectionModel: VideoSelectionModelService,
		private VideoService: VideoService
	) {

		this.isAccountAdminUser = !!this.SecurityContext.checkAuthorization('admin.accounts.edit');
	}

	public get hasVideosSelected(): number {
		return this.VideoSelectionModel.selectionCount;
	}

	public get isOnLegalHold(): boolean {
		if (this.VideoSelectionModel.totalVideos) {
			return this.VideoSelectionModel.selectedVideos.some(video => video.legalHold);
		}

		return false;
	}

	public get hasMixedLegalHoldValues(): boolean {
		if (this.VideoSelectionModel.selectionCount > 0) {
			return this.VideoSelectionModel.hasMixedLegalHoldSelected;
		}
	}

	public lockVideos(): void {
		this.videos = this.VideoSelectionModel.selectedVideos.map(video => video.id);
		this.applyBulkHoldConfirmation.open().result
			.then(() => {
				this.VideoService.lockVideos(this.UserContext.getAccount().id, this.videos)
					.then(videoIds => {
						videoIds.forEach(videoId => {
							const videoItem = this.VideoSelectionModel.selectedVideos.find(video => videoId === video.id);
							if (videoItem) {
								videoItem.legalHold = true;
								videoItem.isActive = false;
							}
						});
					});
			})
			.then(() => this.redirectToState())
			.catch(() => noop);
	}

	public unLockVideos(): void {
		this.videos = this.VideoSelectionModel.selectedVideos.map(video => video.id);
		this.removeBulkHoldConfirmation.open().result
			.then(() => {
				this.VideoService.unlockVideos(this.UserContext.getAccount().id, this.videos)
					.then(videoIds => {
						videoIds.forEach(videoId => {
							const videoItem = this.VideoSelectionModel.selectedVideos.find(video => videoId === video.id);
							if (videoItem) {
								videoItem.legalHold = false;
								videoItem.isActive = true;
							}
						});
					});
			})
			.then(() => this.redirectToState())
			.catch(() => noop);
	}

	private redirectToState(): void {
		window.setTimeout(() => this.MediaStateService.navigateBack(), timeoutInMilliseconds);
	}
}
