import { NgModule } from '@angular/core';

import { VideoChapterRenderer } from './VideoChapterRenderer.Component';

const components = [
	VideoChapterRenderer
];

@NgModule({
	declarations: components,
	exports: components
})
export class VideoChapterModule {}
