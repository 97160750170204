import { Component, Input } from '@angular/core';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { ApprovalStatus } from 'rev-shared/media/MediaConstants';
import './video-thumbnail-status-component.less';

@Component({
	selector: 'video-thumbnail-status',
	templateUrl: './VideoThumbnailStatus.Component.html'
})
export class VideoThumbnailStatusComponent {
	@Input() public approvalStatus: ApprovalStatus;
	@Input() public isVideoOwner: boolean;
	@Input() public spinnerSize: string;
	@Input() public videoIsActive: boolean;
	@Input() public videoStatus: string;
	@Input() public videoThumbnailUri: string;

	public videoStatusOptions: typeof VideoStatus = VideoStatus;

	public get isUploading(): boolean {
		return this.videoStatus === VideoStatus.NOT_UPLOADED ||
			this.videoStatus === VideoStatus.UPLOADING;
	}

	public get isProcessing(): boolean {
		return this.videoStatus === VideoStatus.PROCESSING ||
			this.videoStatus === VideoStatus.UPLOADING_FINISHED;
	}
	public get isProcessingFailed(): boolean {
		return this.videoStatus === VideoStatus.UPLOAD_FAILED ||
			this.videoStatus === VideoStatus.PROCESSING_FAILED ||
			this.videoStatus === VideoStatus.READY_BUT_PROCESSING_FAILED;
	}

	public get isApprovalApproved(): boolean {
		return this.approvalStatus === ApprovalStatus.APPROVED;
	}

	public get isApprovalRejected(): boolean {
		return this.approvalStatus === ApprovalStatus.REJECTED;
	}

	public get isApprovalPending(): boolean {
		return this.approvalStatus === ApprovalStatus.PENDING_APPROVAL;
	}
}
