import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import { formatUrlTimespan } from 'rev-shared/date/DateFormatters';
import { VideoPlaybackSidebarButton } from '../videos/videoPlayback/VideoPlaybackSidebarModel';

import './SpeechSearchResult.less';

@Component({
	selector: 'speech-search-result',
	templateUrl: './SpeechSearchResult.Component.html'
})
export class SpeechSearchResultComponent implements OnInit {
	@Input() public query: string;
	@Input() public result: any;
	@Input() public videoId: string;

	public startAtParam: string;
	public readonly pulse = VideoPlaybackSidebarButton.PULSE;

	public ngOnInit(): void {
		this.startAtParam = formatUrlTimespan(this.result.start * 1000);
	}
}
