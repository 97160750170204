import { StateService, Transition } from '@uirouter/angular';

import { IPlaylist } from 'rev-shared/media/IPlaylist';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { StateChangeStatus } from 'rev-shared/util/StateChangeStatus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { MediaStateService, MediaType, MediaViewMode } from 'rev-portal/media/MediaState.Service';

import { BrowsePlaylistsHostComponent } from './BrowsePlaylistsHost.Component';
import { PlaylistDetailComponent } from './PlaylistDetail.Component';
import { PlaylistSharing } from './PlaylistSharing.Component';
import { VIDEO_PLAYLIST_PLAYBACK_STATE_NAME } from '../videos/videoPlayback/Constants';

export const PLAYLIST_DETAIL_STATE_NAME: string = 'portal.media.playlist-detail';
export const FEATURED_PLAYLIST_DETAIL_STATE_NAME: string = 'portal.media.playlists-featured-playlist';

const FEATURED_PLAYLIST_ID: string = 'featured';

function getFirstFourThumbnailUris(thumbnailUris: string[]): string[] {
	return thumbnailUris.filter(Boolean).slice(0, 4);
}

const playlistDetailOnEnter = (transition: Transition) => {
	const injector = transition.injector();
	return Promise.all([
		injector.getAsync<MediaStateService>(MediaStateService),
		injector.getAsync<StateChangeStatus>(StateChangeStatus),
		injector.getAsync<any>('playlist')
	]).then(([mediaStateService, stateChangeStatus, playlist]) => {

		if (!mediaStateService.isTableViewMode) {
			// reset the sort direction for tiles view (provides no means of toggling)
			mediaStateService.setIsSortDesc(false);
		}

		if (!stateChangeStatus.isStateReloading) {
			mediaStateService.setViewMode(MediaViewMode.TABLE);
		}

		mediaStateService.searchResultsState = {
			playlist,
			mediaCount: playlist.videos.length,
			mediaType: MediaType.VIDEO
		};
	});
};

const playlistDetailResolve = [
	{
		token: 'playlistId',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().playlistId
	},
	{
		token: 'playlist',
		deps: [PlaylistService, 'playlistId'],
		resolveFn: (PlaylistService: PlaylistService, playlistId: string) => PlaylistService.getPlaylist(playlistId)
	},
	{
		token: 'mediaFeatures',
		deps: [MediaFeaturesService],
		resolveFn: (MediaFeatures: MediaFeaturesService) => MediaFeatures.getFeatures()
	},
	{
		token: 'canEdit',
		deps: ['playlist'],
		resolveFn: (playlist: any) => playlist.canEdit
	},
	{
		token: 'hasMediaEditAuth',
		deps: [SecurityContextService],
		resolveFn: (SecurityContext: SecurityContextService) => !!SecurityContext.checkAuthorization('media.edit')
	},
	{
		token: 'playlistPlaybackUrl',
		deps: [StateService, 'playlistId'],
		resolveFn: ($state: StateService, playlistId: string) => $state.href(VIDEO_PLAYLIST_PLAYBACK_STATE_NAME, { playlistId }, { absolute: true, inherit: false })
	}
];

export const states: IVbNg2StateDeclaration[] = [
	{
		name: 'portal.media.my-playlists',
		url: '/playlists',
		views: {
			'content@portal.media': { component: BrowsePlaylistsHostComponent }
		},
		scrollToTop: true,
		resolve: [
			{
				token: 'userId',
				deps: [UserContextService],
				resolveFn: (UserContext: UserContextService) => UserContext.getUser().id
			},
			{
				token: 'userPlaylists',
				deps: [PlaylistService, 'userId'],
				resolveFn: (PlaylistService: PlaylistService, userId: string) => PlaylistService
					.getUserPlaylists(userId)
					.then((playlists: IPlaylist[]) => {
						playlists.forEach((p: any) => Object.assign(p, {
							featured: false,
							thumbnailUris: getFirstFourThumbnailUris(p.videoThumbnails.map((t: any) => t.thumbnailUri)),
							date: p.whenVideoAddedOrDeleted.when || p.createdBy.when
						}));

						return playlists;
					})
			},
			{
				token: 'featuredPlaylist',
				deps: [PlaylistService, 'accountId'],
				resolveFn: (PlaylistService: PlaylistService, accountId: string) => PlaylistService
					.getFeaturedVideos(accountId)
					.then((featuredPlaylist: any) => {
						if (!featuredPlaylist.videos.length) {
							return null;
						}

						return {
							featured: true,
							name: null,
							date: featuredPlaylist.whenVideoAddedOrDeleted.when || featuredPlaylist.createdBy.when,
							thumbnailUris: getFirstFourThumbnailUris(featuredPlaylist.videos.map((video: any) => video.thumbnailUri)),
							videoThumbnails: featuredPlaylist.videos.map((video: any) => {
								return {
									id: video.id,
									thumbnailUri: video.thumbnailUri
								};
							})
						};
					})
			},
			{
				token: 'playlists',
				deps: ['featuredPlaylist', 'userPlaylists'],
				resolveFn: (featuredPlaylist, userPlaylists) => {
					if (featuredPlaylist) {
						userPlaylists.push(featuredPlaylist);
					}

					return userPlaylists;
				}
			}
		],
		onEnter(transition: Transition) {
			const inj = transition.injector();
			const state = inj.get<StateService>(StateService);
			return Promise.all([
				inj.getAsync<MediaStateService>(MediaStateService),
				inj.getAsync<StateChangeStatus>(StateChangeStatus),
				inj.getAsync<IPlaylist[]>('playlists')
			]).then(([mediaStateService, stateChangeStatus, playlists]) => {
				// filtering and sorting was never implemented here, so disable for the time being
				mediaStateService.setIsFilteringDisabled(true);
				mediaStateService.setIsSortingDisabled(true);
				mediaStateService.setIsBulkEditDisabled(true);

				if (!stateChangeStatus.isStateReloading) {
					mediaStateService.searchResultsState = {
						mediaCount: playlists.length,
						mediaType: MediaType.PLAYLIST
					};

					mediaStateService.setViewMode(MediaViewMode.TILES);

				}
			});
		},
		onExit(transition: Transition) {
			const inj = transition.injector();
			return inj.getAsync<MediaStateService>(MediaStateService).then(mediaStateService => {
				mediaStateService.setIsFilteringDisabled(false);
				mediaStateService.setIsSortingDisabled(false);
				mediaStateService.setIsBulkEditDisabled(false);
			});
		},
	},
	{
		name: FEATURED_PLAYLIST_DETAIL_STATE_NAME,
		url: '/featured-playlist',
		views: {
			'content@portal.media': { component: PlaylistDetailComponent }
		},
		onEnter: playlistDetailOnEnter,
		scrollToTop: true,
		resolve: [
			...playlistDetailResolve,
			{
				token: 'playlistId',
				resolveFn: () => FEATURED_PLAYLIST_ID
			},
			{
				token: 'playlist',
				deps: [PlaylistService, UserContextService],
				resolveFn: (PlaylistService: PlaylistService, UserContext: UserContextService) => PlaylistService.getFeaturedVideos(UserContext.getAccount().id)
					.then((playlist: IPlaylist) => Object.assign(playlist, {
						id: 'featured',
						isFeatured: true
					}))
			},
			{
				token: 'canEdit',
				deps: [SecurityContextService],
				resolveFn: (SecurityContext: SecurityContextService) => SecurityContext.checkAuthorization('media.featuredPlaylist')
			}
		]
	},
	{
		name: PLAYLIST_DETAIL_STATE_NAME,
		url: '/playlists/:playlistId',
		views: {
			'content@portal.media': { component: PlaylistDetailComponent }
		},
		scrollToTop: true,
		onEnter: playlistDetailOnEnter,
		resolve: [...playlistDetailResolve]
	},
	{
		...getPlaylistSharingSidebar(PLAYLIST_DETAIL_STATE_NAME)
	},
	{
		...getPlaylistSharingSidebar(FEATURED_PLAYLIST_DETAIL_STATE_NAME)
	}
];

function getPlaylistSharingSidebar(playlistDetailStateName: string) {
	return {
		name:`${playlistDetailStateName}.sharing-sidebar`,
		url: '/sharing',
		views: {
			'right-sidebar-content': { component: PlaylistSharing }
		},
		resolve: [
			{
				token: 'embedsEnabled',
				deps: [MediaFeaturesService],
				resolveFn: (MediaFeatures: MediaFeaturesService) => MediaFeatures.getFeatures()
					.then((features: any) => features.enableEmbeds)
			},
			{
				token: 'playlistEmbedUrl',
				deps: ['playlistId'],
				resolveFn: (playlistId: string) => `${window.location.origin}/embed?playlist=${playlistId}`
			},
			{
				token: 'playlistName',
				deps: ['playlist'],
				resolveFn: (playlist: any) => playlist.name
			}
		]
	};
}
