<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>
<header role="banner" [ngClass]="[
		'theme-' + currentTheme,
		$state.includes('portal.video') ? 'no-border' : '',
		!$state.includes('portal.dashboard') ? 'theme-accent-border' : ''
	]" (vbOffsetHeightModel)="MainHeaderPosition.setHeight($event.offsetHeight)">
	<ng-container [ngSwitch]="currentTheme">
		<classic-header *ngSwitchCase="'Classic'"></classic-header>
		<nettrix-header *ngSwitchCase="'Nettrix'"></nettrix-header>
		<twotier-header *ngSwitchCase="'TwoTier'"></twotier-header>
	</ng-container>
</header>
