<header class="theme-primary right-sidebar-content-header">
	<h4 id="mediaSharing">{{ 'Media_Sharing' | translate }}</h4>

	<div vbUiRadioBtnGroup class="margin-left-15" *ngIf="embedsEnabled" aria-labelledby="mediaSharing">
		<button class="btn-icon-left" type="button" name="shareLinkOpt" [(ngModel)]="currentShareView" [vbUiRadioBtn]="PlaylistSharingViews.LINK">
			<span class="glyphicons link"></span>
			{{ 'Link' | translate }}
		</button>
		<button class="btn-icon-left" type="button" name="shareEmbedOpt" [(ngModel)]="currentShareView" [vbUiRadioBtn]="PlaylistSharingViews.EMBED">
			<span class="glyphicons embed"></span>
			{{ 'Media_Videos_Sharing_Embed' | translate }}
		</button>
	</div>
</header>

<vb-ui-share-link *ngIf="currentShareView === PlaylistSharingViews.LINK" [externalEmailBody]="playlistSharingEmailBody" [externalEmailSubject]="'Media_Videos_Playlists_Sharing_Email_Subject' | translate" [externalLinkUrl]="playlistPlaybackUrl" [hideLinkOptions]="true">
</vb-ui-share-link>

<vb-ui-share-embed *ngIf="currentShareView === PlaylistSharingViews.EMBED" [defaultAutoplay]="false" [defaultSizeName]="'medium'" [playlist]="{id: playlistId}" [embedOptionsDisplayContext]="embedOptionsDisplayContext" [sizes]="embedPresetSizes">
</vb-ui-share-embed>
