<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>
<section class="theme-accent-border">
	<div class="media-videos theme-accent-bg">
		<div class="arrow-container">
			<aside class="media-arrow-bg"></aside>
			<aside class="media-arrow-fg"></aside>
		</div>
		<h1 class="media-box-header theme-accent-txt">{{ 'Navigation_Videos' | translate }}</h1>
		<my-videos-menu-items>
		</my-videos-menu-items>
	</div>

	<div class="media-teams theme-header-bg" flex="fill">
		<div class="teams-section-header theme-header-font-border-color">
			<div class="teams-header-text-section">
				<div class="media-box-header theme-header-txt text-hover-none">
					{{ 'Navigation_MyTeams' | translate }}
				</div>
				<div *ngIf="TeamService.isCreateTeamAccess" class="create-new-team-section theme-header-font-border-color">
					<a class="theme-accent-link teams-section-header-link" uiSref="portal.team-edit">
						<span class="glyphicons plus theme-accent-link"></span>
						{{ 'Teams_Create_New' | translate }}
					</a>
				</div>
			</div>
			<div class="all-teams-section" *ngIf="MediaMenuService.hasTeams">
				<a class="theme-accent-link teams-section-header-link" uiSref="portal.team">
					#&nbsp;{{ 'Navigation_AllTeams' | translate }}
				</a>
			</div>
		</div>
		<vb-loading-spinner [block]="true" [size]="'large'" [hidden]="!MediaMenuService.isFetching">
		</vb-loading-spinner>

		<div class="no-teams" [ngClass]="{'animated fadeIn': MediaMenuService.isFirstFetchComplete}" *ngIf="!MediaMenuService.hasTeams">
			<h3 class="theme-header-txt">{{ 'Teams_NotAvailable' | translate }}</h3>
		</div>

		<my-teams-menu-items *ngIf="MediaMenuService.hasTeams" [teamData]="MediaMenuService.TeamListingsService.teamData" [maxVisibleTeams]="MediaMenuService.maxVisibleTeams">
		</my-teams-menu-items>
	</div>
</section>
