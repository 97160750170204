import _ from 'underscore';

import {
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';


import { Subscription } from 'rxjs';

import { Filter, MultiValueFilter } from './SearchFilterTypes';
import { SearchFilterStateService } from './SearchFilterState.Service';

import './VbSearchFilterList.less';
import { SearchQueryBuilder } from 'rev-portal/search/SearchQueryBuilder';

@Component({
	selector: 'vb-search-filter-list',
	templateUrl: './VbSearchFilterList.Component.html'
})
export class VbSearchFilterListComponent implements OnDestroy, OnInit {
	public filters: Array<{
		clear: () => void;
		format: any;
		name: string;
	}>;

	public filterNames = {
		title:'Title',
		description:'Description',
		whenUploaded:'Media_Search_Filters_UploadDate',
		ownerUserId:'Media_Videos_Owner',
		uploaderUserId:'Media_Videos_Uploader',
		isActive:'Media_Videos_Status',
		is360:'Filter360Video',
		isLive:'VideoType',
		teamIds:'Title_Team',
		unlisted:'Unlisted',
		userTags:'InThisVideo'
	};

	private searchFilterStateSub: Subscription;
	public showClearAllButton: boolean;

	constructor(
		private SearchFilterState: SearchFilterStateService,
		private $state: StateService,
		private TranslateService: TranslateService
	) {
	}

	public ngOnInit(): void {
		this.searchFilterStateSub = this.SearchFilterState.change$.subscribe(() => this.initializeFilters());

		window.setTimeout(() => this.initializeFilters());
	}

	public ngOnDestroy(): void {
		this.searchFilterStateSub.unsubscribe();
	}

	public clearAll(): void {
		this.SearchFilterState.go({}, this.$state);
	}

	private getFormatter(filter: Filter): (value: any) => string {
		if(filter.formatterFactory){
			return filter.formatterFactory(this.TranslateService);
		}

		return (filter.formatter || _.identity).bind(filter);
	}

	private initializeFilters(): void {
		this.filters = Object.keys(this.SearchFilterState.filters)
			.map(name => {
				const filter = this.SearchFilterState.filters[name];

				const hasEditableOverride: boolean = filter.hasValueOverride && !filter.blockUserUpdates;

				if (!filter.hasUserEnteredValue && !hasEditableOverride) {
					return;
				}

				const formatter = this.getFormatter(filter);

				if (filter.isSingleValue) {
					return {
						name,
						format: () => formatter(filter.value),
						clear: () => {
							filter.clear();
							this.sanitizeFiltersAndApply();
						}
					};
				}

				//multivalue
				return filter.value.map((value, i) => ({
					name,
					format: () => formatter(value),
					clear: () => {
						(filter as MultiValueFilter).removeSingleValue(i);
						this.sanitizeFiltersAndApply();
					}
				}));
			})
			.reduce((a, b) => a.concat(b), [])
			.filter(Boolean);

		this.showClearAllButton = !!this.filters.length;
	}

	public sanitizeFiltersAndApply(): void {
		const filters = {};
		this.filters.map(filter => filter.name)
			.reduce((unique, item) => unique.includes(item) ? unique: [...unique, item], [])
			.forEach(name => {
				const filter = this.SearchFilterState.filters[name];
				if (filter.hasValue) {
					filters[name] = this.SearchFilterState.getFilterValue(filter);
				}
			});
		this.SearchFilterState.go(filters, this.$state);
	}
}
