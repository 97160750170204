export enum WebcastVideoSource {
	CAPTURE = 'Capture',
	MSTEAMS = 'MicrosoftTeams',
	PEXIP = 'Pexip',
	PRESENTATION = 'PresentationProfile',
	PRODUCER = 'Producer',
	RTMP = 'Rtmp',
	SIP_ADDRESS = 'SipAddress',
	VOD = 'Vod',
	WEBEX = 'WebexTeam',
	WEBEX_EVENTS = 'WebexEvents',
	WEBEX_LIVE_STREAM = 'WebexLiveStream',
	WEBRTC_SINGLE_PRESENTER = 'WebrtcSinglePresenter',
	ZOOM = 'Zoom'
}
