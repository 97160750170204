import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'bulk-edit-submission-dialog',
	templateUrl: './BulkEditSubmissionDialog.Component.html'
})
export class BulkEditSubmissionDialogComponent {
	@Input() public legalHoldVideosCount: number;
	@Input() public isEditing: number;
	@Input() public isDeleting: number;

	@Output() public onHide = new EventEmitter();

	constructor(
		public readonly modalInstance: BsModalRef
	)
	{ }
}
