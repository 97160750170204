<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>
<nav class="theme-header-bg" role="navigation">

	<section class="navbar-wrapper">
		<section class="navbar" layout="row" flex-wrap="false">

			<mobile-menu [hidden]="isRegisteredGuest" flex-gt-md="hide" [theme]="currentTheme" [headerSettings]="headerSettings" [themeSettings]="themeSettings" [isAnalystAdmin]="isAnalystAdmin" [showManageSubscriptions]="showManageSubscriptions" [landingState]="landingState">
			</mobile-menu>

			<div class="brand-cell" flex="branding" flex-align-self="start">
				<a [attr.aria-label]="('Application_CompanyLogo' | translate) + ' - ' + ('Home' | translate)" class="theme-logo padding-0" href="#/" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
					<branding-logo></branding-logo>
				</a>
				<div *vbAuthorizationKey="['authenticated', 'distribution.authenticated']; fallback true">
					<span *ngIf="guestPortalRestricted; else guestPortalAllowed;" class="navbar-brand">
						<branding-logo></branding-logo>
					</span>
					<ng-template #guestPortalAllowed>
						<a [attr.aria-label]="('Application_CompanyLogo' | translate) + ' - ' + ('Home' | translate)" [href]="'#/guest'" class="navbar-brand" [tabindex]="0">
							<branding-logo></branding-logo>
						</a>
					</ng-template>
				</div>
			</div>

			<div *vbAuthorizationKey="'distribution.authenticated'; fallback true" class="search-cell" flex="search" flex-align-self="center" role="search">
				<search-header *ngIf="!guestPortalRestricted" class="twoTier-search-header"></search-header>
			</div>

			<div class="margin-right-30 xs-mbl-left-30" layout="row" flex="1" flex-align="center" flex-justify="end">

				<go-live [bgNone]="true" [iconCssClass]="'theme-header-txt'" [dropdownCssClass]="'align-right'">
				</go-live>

				<div class="dropdown upload-cell align-right" dropdown #headerUploadMenuDropdown="bs-dropdown" [insideClick]="true" *vbAuthorizationKey="'media.add'">
					<button [attr.aria-label]="('Upload' | translate) + ' - ' + ('UI_Menu' | translate)" class="dropdown-toggle btn main-nav-btn upload bg-none" dropdownToggle>
						<span class="glyphicons disk_open theme-header-txt">
						</span>
					</button>
					<div class="dropdown-menu navdrop-menu upload-media" *dropdownMenu>
						<upload-menu (onCloseUploadMenu)="headerUploadMenuDropdown.hide()">
						</upload-menu>
					</div>
				</div>
				<notifications-menu-button class="notification-cell count-bubble" *ngIf="isUserAuthenticated" [alwaysShowIcon]="true" [glyphiconCssClass]="'theme-header-txt'">
				</notifications-menu-button>
			</div>
		</section>
	</section>

	<section class="navbar-tier-two" layout="row" flex-align="center" flex-wrap="false">
		<div flex="1" flex-justify="center">
			<ul class="menu-list-tight">
				<li class="margin-right-30 box-inline-block media-menu-list-item" *vbAuthorizationKey="'authenticated'">
					<media-menu-dropdown class="type-14 util-uppercase" [currentTheme]="currentTheme">
					</media-menu-dropdown>
				</li>
				<li class="margin-right-30 box-inline-block" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
					<a class="theme-header-txt util-uppercase util-no-underline type-14 padding-top-2" uiSref="portal.scheduledEvents.all" [uiOptions]="{reload: true, inherit: false}" data-toggle="tab" uiSrefActive="active">
							{{ 'Events' | translate }}
					</a>
				</li>
				<li class="margin-right-30 box-inline-block" *vbAuthorizeState="'portal.admin'">
					<a class="theme-header-txt util-uppercase util-no-underline type-14" uiSref="portal.load-admin" [uiParams]="{accountId: accountId}" data-toggle="tab">
						{{ (isAnalystAdmin ? 'Analytics' : 'Admin') | translate }}
					</a>
				</li>
				<ng-container *ngIf="!account.readonlyUserProfile">
					<li class="box-inline-block" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
						<a class="theme-header-txt util-uppercase util-no-underline type-14" uiSref="portal.userProfile" data-toggle="tab">
							{{ 'UserProfile_MyProfile' | translate }}
						</a>
					</li>
				</ng-container>
				<ng-container *ngIf="showManageSubscriptions">
					<li *vbAuthorizationKey="['authenticated']" class="margin-left-30 margin-bottom-2 box-inline-block">
						<a class="theme-header-txt util-uppercase util-no-underline type-14" [uiSref]="'portal.manageSubscriptions'">
							{{ 'ManageSubscriptions' | translate }}
						</a>
					</li>
				</ng-container>
			</ul>
		</div>

		<!-- Event Title -->
		<div class="event-title-container" [hidden]="!event" flex="1">
			<a class="util-uppercase event-display theme-accent-link" flex-justify="end" *vbAuthorizationKey="'authenticated'" uiSref="portal.scheduledEvents.webcast" [uiParams]="{webcastId: event?.id}">
				<p class="event-display-text">{{ event?.startDate | vbDateTimeMedium }}</p>
				<span>&nbsp;-&nbsp;</span>
				<p class="event-display-text event-display-link">{{ event?.title }}</p>
			</a>
		</div>

		<div flex="1">
			<ul class="menu-list-tight margin-right-30" layout="row" flex-align="baseline" flex-justify="end">
				<!-- Username -->
				<li class="theme-header-txt text-hover-none box-inline-block type-13" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
					<vb-profile-picture [altTxt]="'UserProfile_ProfilePicture' | translate" [profileUrl]="user.profileImageUri">
					</vb-profile-picture>
					{{ user.fullName | slice:0:30 }}
				</li>
				<li class="util-uppercase box-inline-block margin-left-30">
					<!-- Login (unauthenticated) -->
					<a class="theme-accent-link type-12" flex="hide" flex-gt-md (click)="loginRedirect()" [hidden]="isRegisteredGuest" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']; fallback true">
						<span class="glyphicons user"></span>
						<span>{{ 'SignIn' | translate }}</span>
					</a>
					<!-- Logout -->
					<a class="theme-accent-link type-12" uiSref="logout" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
						{{ 'Logout' | translate }}
					</a>
					&nbsp;<span class="theme-accent-link" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">|</span>&nbsp;
					<help-menu class="type-12" *vbAuthorizationKey="['authenticated', 'distribution.authenticated']">
					</help-menu>
				</li>
			</ul>
		</div>
	</section>

</nav>
