<div class="list-group" [ngClass]="[isBaseHeaderBgColor ? 'theme-header' : 'theme-accent']">
	<a class="list-group-item btn-icon-left logout-tray theme-accent-btn" [ngClass]="[isBaseHeaderBgColor ? 'theme-header-txt' : 'theme-accent-txt']" *ngIf="!readonlyUserProfile" [uiSref]="'portal.userProfile'">
		<span class="glyphicons user" [ngClass]="[isBaseHeaderBgColor ? 'theme-header-txt' : 'theme-accent-txt']">
		</span>
		{{ 'UserProfile_MyProfile' | translate }}
	</a>
	<a *ngIf="showManageSubscriptions" class="list-group-item btn-icon-left logout-tray theme-accent-btn" [ngClass]="[isBaseHeaderBgColor ? 'theme-header-txt' : 'theme-accent-txt']" [uiSref]="'portal.manageSubscriptions'">
		<span class="glyphicons bell" [ngClass]="[isBaseHeaderBgColor ? 'theme-header-txt' : 'theme-accent-txt']">
		</span>
		{{ 'ManageSubscriptions' | translate }}
	</a>
	<a class="list-group-item btn-icon-left logout-tray theme-accent-btn" [ngClass]="[isBaseHeaderBgColor ? 'theme-header-txt' : 'theme-accent-txt']" [uiSref]="'logout'">
		<span class="glyphicons power" [ngClass]="[isBaseHeaderBgColor ? 'theme-header-txt' : 'theme-accent-txt']">
		</span>
		{{ 'Logout' | translate }}
	</a>
</div>