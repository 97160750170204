import {
	Component,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';

import { VideoSearchResultsComponent } from './VideoSearchResults.Component';
import { MediaViewMode } from '../MediaState.Service';

const SEARCH_TEMPLATE = `
	<video-search-results
		[accountId]="accountId"
		[bulkEdit]="bulkEdit"
		[categoryId]="categoryId"
		[categoryRoot]="categoryRoot"
		[forceMediaView]="forceMediaView"
		[mediaFeatures]="mediaFeatures"
		[searchParams]="searchParams"
		[teamId]="teamId"
		[userHasEditableVideos]="userHasEditableVideos">
	</video-search-results>
`;
@Component({
	selector: 'search-results',
	template: ``
})
export class SearchResultsComponent {
	@Input() public accountId: string;
	@Input() public bulkEdit: boolean;
	@Input() public categoryId: string;
	@Input() public categoryRoot: boolean;
	@Input() public forceMediaView: MediaViewMode;
	@Input() public mediaFeatures: any;
	@Input() public searchParams: any;
	@Input() public teamId: string;
	@Input() public userHasEditableVideos: boolean;
}


@Component({
	selector: 'my-video-search-results',
	template: SEARCH_TEMPLATE
})
export class MyVideoSearchResultsComponent extends SearchResultsComponent {
}

@Component({
	selector: 'global-video-search-results',
	template: SEARCH_TEMPLATE
})
export class GlobalVideoSearchResultsComponent extends SearchResultsComponent {
}

@Component({
	selector: 'bulk-edit-video-search-results',
	template: SEARCH_TEMPLATE
})
export class BulkEditVideoSearchResultsComponent extends SearchResultsComponent {
}

@Component({
	selector: 'category-browse-search-results',
	template: SEARCH_TEMPLATE
})
export class CategoryBrowseSearchResultsComponent extends SearchResultsComponent {
}

@Component({
	selector: 'category-details-search-results',
	template: SEARCH_TEMPLATE
})
export class CategoryDetailsSearchResultsComponent extends SearchResultsComponent {
}

@Component({
	selector: 'pending-video-search-results',
	template: SEARCH_TEMPLATE
})
export class PendingVideoSearchResultsComponent extends SearchResultsComponent {
}

@Component({
	selector: 'expiration-video-search-results',
	template: SEARCH_TEMPLATE
})
export class ExpirtaionVideoSearchResultsComponent extends SearchResultsComponent {
}

@Component({
	selector: 'my-subscription-video-search-results',
	template: SEARCH_TEMPLATE
})
export class MySubscriptionVideoSearchResultsComponent extends SearchResultsComponent {
}

export const SEARCH_COMPONENTS = [
	SearchResultsComponent,
	MyVideoSearchResultsComponent,
	GlobalVideoSearchResultsComponent,
	BulkEditVideoSearchResultsComponent,
	CategoryBrowseSearchResultsComponent,
	CategoryDetailsSearchResultsComponent,
	PendingVideoSearchResultsComponent,
	ExpirtaionVideoSearchResultsComponent,
	MySubscriptionVideoSearchResultsComponent
];
