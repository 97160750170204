import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { PushService } from 'rev-shared/push/PushService';

import { ICategory } from './CategoryContract';
import { CategoryManagerItemType, CategoryContributorItemType } from './Constants';

@Injectable()
export class AdminCategoryService {

	constructor(
		private http: HttpClient,
		private PushService: PushService
	) {
	}
	private readonly mapPolicyItem = ({ id, type, categoryManager }: any): any => ({
		id,
		type,
		itemType: categoryManager ? CategoryManagerItemType : CategoryContributorItemType
	});

	public createCategory(category: ICategory): Promise<void> {
		return this.PushService.dispatchCommand('media:AddCategoryToAccount', {
			accountId: category.accountId,
			name: category.name,
			parentCategoryId: category.parentCategoryId,
			restricted: category.restricted,
			categoryPolicyItems: category.categoryPolicyItems?.map(this.mapPolicyItem) || []
		});
	}

	public getCategory(categoryId: string): Promise<any> {
		return lastValueFrom<any>(this.http.get(`/media/categories/${ categoryId }`))
			.then(category => {
				category.categoryPolicyItems = category.categorySecurityPolicy?.categoryPolicyItems
					?.map(({ id, type, itemType }) => ({
						id,
						type,
						categoryManager: itemType === CategoryManagerItemType
					})) || [];
				return category;
			});
	}

	public removeCategory(accountId: string, categoryId: string): Promise<void> {
		return this.PushService.dispatchCommand('media:RemoveCategory', {
			accountId,
			categoryId
		});
	}

	public saveCategory(category: ICategory): Promise<void> {
		return this.PushService.dispatchCommand('media:SaveCategoryDetails', {
			accountId: category.accountId,
			categoryId: category.id,
			name: category.name,
			restricted: category.restricted,
			categoryPolicyItems: category.categoryPolicyItems.map(this.mapPolicyItem)
		});
	}
}
