import { Component, Input } from '@angular/core';

import './playlist-detail.less';
@Component({
	selector: 'playlist-detail-tiles',
	templateUrl: './PlaylistDetailTiles.Component.html'
})
export class PlaylistDetailTilesComponent {
	@Input() public hasMediaEditAuth: boolean;
	@Input() public mediaFeatures: any;
	@Input() public playlist: any;
	@Input() public userId: string;
}
