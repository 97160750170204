<div dropdown>
	<a role="button" [attr.aria-label]="'UI_SortLabel' | translate " dropdownToggle>
		<span class="glyphicons" [ngClass]="MediaStateService.getIsSortDesc() ? 'sort-by-attributes-alt': 'sort-by-attributes'"></span>
		{{ activeLink?.label | translate }}
	</a>

	<ul class="dropdown-menu" [ngClass]="styles.dropdownList" *dropdownMenu role="menu" [attr.aria-label]="'SortFilter_Menu' | translate ">
		<li role="menutem" *ngFor="let link of links">
			<a (click)="setSortField(link)" [ngClass]="MediaStateService.getSortField() === link.sort ? styles.activeAnchor : ''">
				<span [attr.aria-label]="'Selected' | translate " class="glyphicons ok_2" [hidden]="!(MediaStateService.getSortField() === link.sort )"></span>
				{{ link.label | translate }}
			</a>
		</li>
	</ul>
</div>
