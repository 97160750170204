<vb-loading-spinner *ngIf="status.loading" [block]="true" [size]="'large'"></vb-loading-spinner>

<div class="container-fluid notifications-list" *ngIf="status.error">
	<div class="row">
		<div class="col-xs-12">
			<div class="panel panel-default" style="padding:1px;">
				<div class="centered-copy">
					<div class="alert alert-danger">
						<span class="glyphicons circle_exclamation_mark"></span>&nbsp;{{ 'UnhandledError' | translate }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container-fluid notification-list" *ngIf="status.active">
	<div class="fake-event-nav">
		<div class="container-fluid event-nav">
			<div class="flex-navigation-bar-wrap">
				<div class="flex-navigation-bar">
					<div class="flex-fill">
						<h2>{{ 'Notifications' | translate }}</h2>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-12">

			<div *ngIf="highNotifications.length" class="notification-section">
				<h3>{{ 'Notifications_Alerts' | translate }}</h3>
				<ul class="row">
					<li class="col-sm-6 col-lg-4 media" *ngFor="let notification of highNotifications">
						<vb-notification [notification]="notification"></vb-notification>
					</li>
				</ul>
			</div>

			<div *ngFor="let group of lowNotifications" class="notification-section">
				<h3 [ngSwitch]="group.offset">
					<span *ngSwitchCase="'0'">{{ 'Notifications_Today' | translate }}</span>
					<span *ngSwitchCase="'1'">{{ 'Notifications_Yesterday' | translate }}</span>
					<span *ngSwitchDefault>{{group.date | vbDateMedium}}</span>
				</h3>

				<ul class="row">
					<li class="col-sm-6 col-lg-4 media" *ngFor="let notification of group.notifications">
						<vb-notification [notification]="notification"></vb-notification>
					</li>
				</ul>
			</div>

		</div>
	</div>
</div>
