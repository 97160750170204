import {
	Component,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';

import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';

import { ApprovalStatus, UNCATEGORIZED } from 'rev-shared/media/MediaConstants';
import { CategoryService } from 'rev-shared/media/Category.Service';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { ICancellableQueue, createPromiseQueue } from 'rev-shared/util/PromiseUtil';
import { ICategory } from 'rev-shared/media/Media.Contract';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { orderBy } from 'rev-shared/util/SortUtil';

import { IVideoPlaybackConfig } from 'rev-portal/media/videos/videoPlayback/IVideoPlaybackConfig';
import { MediaStateService, MediaViewMode, MediaType } from 'rev-portal/media/MediaState.Service';
import { SearchFilterStateService } from 'rev-portal/media/search/SearchFilterState.Service';
import { SearchService } from 'rev-portal/search/Search.Service';
import { VideoSelectionModelService } from 'rev-portal/media/search/bulkEdit/VideoSelectionModel.Service';

import styles from './Search.module.less';
import { ParentUIViewInject, UIView } from '@uirouter/angular';

type ICancellablePromise = Promise<any> & { cancelled: boolean };

@Component({
	selector: 'video-search-results',
	templateUrl: './VideoSearchResults.Component.html'
})
export class VideoSearchResultsComponent implements OnDestroy, OnInit {
	@Input() public accountId: string;
	@Input() public bulkEdit: boolean;
	@Input() public categoryId: string;
	@Input() public categoryRoot: boolean;
	@Input() public forceMediaView: MediaViewMode;
	@Input() public mediaFeatures: any;
	@Input() public searchParams: any;
	@Input() public teamId: string;
	@Input() public userHasEditableVideos: boolean;

	private readonly pageSize: number = 25;

	public readonly styles = styles;
	public readonly approvalStatusOptions = ApprovalStatus;

	private blockReload: boolean;
	private categoryPath: any[];
	private currentLoadOperation: ICancellablePromise;
	private query: string;
	private readonly promiseQueues: Array<ICancellableQueue<any>> = [];
	private scrollId: string;
	private searchFilterStateSub: Subscription;
	private totalVideos: number;
	private unsubscribePush: IUnsubscribe;
	private userId: string;

	public categories: any[];
	public category: any;
	public hasMediaEditAuth: boolean;
	public isGuest: boolean;
	public isLoadComplete: boolean;
	public mediaState: any;
	public pauseInfiniteScroll: boolean;
	public selectionModel: any;
	public sortAscending: boolean;
	public sortField: string;
	public status: { [key: string]: boolean } = { active: true };
	public uncategorizedCatEntry: any;
	public videos: any[];
	public viewMode: string;
	public currentStateName: string;

	@ViewChild('downloadCsvDialog')
	public downloadCsvDialog: VbConfirmationDialogComponent;

	constructor(
		private $state: StateService,
		private CategoryService: CategoryService,
		private DateParsers: DateParsersService,
		private MediaStateService: MediaStateService,
		private PushBus: PushBus,
		private SearchFilterState: SearchFilterStateService,
		private SearchService: SearchService,
		private SecurityContext: SecurityContextService,
		private UserContext: UserContextService,
		private VideoSelectionModel: VideoSelectionModelService,
		@Inject(UIView.PARENT_INJECT) private parent: ParentUIViewInject
	) {}

	public ngOnInit(): void {
		this.isGuest = this.UserContext.isGuest();
		this.mediaState = this.MediaStateService.getMediaState();
		this.query = this.searchParams.query;
		this.sortAscending = !this.MediaStateService.getIsSortDesc();
		this.sortField = this.MediaStateService.getSortField();
		this.viewMode = this.forceMediaView || this.MediaStateService.getViewMode();
		this.pauseInfiniteScroll = false;
		this.userId = this.UserContext.getUser().id;
		this.hasMediaEditAuth = !!this.SecurityContext.checkAuthorization('media.edit');
		this.MediaStateService.searchResultsState = { searchQuery: this.query };
		this.currentStateName = this.parent.context.name;

		this.status = {
			loading: true
		};

		this.initialize()
			.then(() => this.status = { active: true })
			.catch(() => this.status = { error: true });

		this.searchFilterStateSub = this.SearchFilterState.change$.subscribe(() => this.reloadVideos());
	}

	public ngOnDestroy(): void {
		this.searchFilterStateSub.unsubscribe();

		if (this.unsubscribePush) {
			this.unsubscribePush();
		}

		this.promiseQueues.forEach(queue => queue.cancel());

		this.closeScrollId();
	}

	public readonly forceLoadAllSearchResults = (): Promise<void> => {
		this.blockReload = true;

		return this.loadRemaining()
			.finally(() => this.blockReload = false);
	};

	public get videoPlaybackConfig(): IVideoPlaybackConfig {
		return this.MediaStateService.videoPlaybackConfig;
	}

	private get hasLockedVideos(): boolean {
		return this.VideoSelectionModel.allSelectedVideoInLegalHold;
	}

	private getVideoFromVideos(videoId: string): any {
		return this.videos.find(video => video.id === videoId);
	}

	private hasEditVideoAuth(video: any): boolean {
		return video && (video.editAcl || []).includes(this.userId);
	}

	private initialize(): Promise<void> {
		if (this.categoryRoot) {
			return this.initializeCategoryRoot();
		}

		if (this.bulkEdit) {
			this.initializeBulkEdit();
		}

		this.initCategoryContext();
		this.initializePush();
		return this.loadNextPageInternal();
	}

	private initializeBulkEdit(): void {
		this.selectionModel = this.VideoSelectionModel;

		this.MediaStateService.searchResultsState.getSelectedCount = () =>
			this.VideoSelectionModel.selectionCount;

		this.loadLegalHoldVideoCount();
	}

	private initializeCategoryRoot(): Promise<any> {
		//Videos list is not shown on root categories page
		this.pauseInfiniteScroll = true;

		return Promise.all([
			this.CategoryService.getRootCategories()
				.then(result => this.initCategories(result.categories)),
			this.loadUncategorized()
		]);
	}

	private loadUncategorized(): Promise<void> {
		return this.CategoryService
			.getUncategorizedActiveSearchContent(this.accountId)
			.then(result => {
				if (result.videoCount) {
					this.uncategorizedCatEntry = {
						isUncategorized: true,
						id: UNCATEGORIZED,
						videoCount: result.videoCount,
						thumbnails: result.thumbnails,
						ready: true
					} as any;
				}
			});
	}

	private initCategoryContext(): void {
		if (!this.categoryId || this.categoryId === UNCATEGORIZED) {
			this.categoryPath = this.searchParams.isUncategorized ? [{ id: null }] : undefined;

			this.MediaStateService.searchResultsState = {
				...this.MediaStateService.searchResultsState,
				categoryPath: this.categoryPath
			};
			return;
		}

		const categoryContent = this.searchParams.categoryContent;
		if (categoryContent) {
			const categoryPath = categoryContent.path;

			const categories = categoryContent.categories;
			this.category = categoryPath[categoryPath.length - 1];
			this.categoryPath = categoryPath;
			this.MediaStateService.searchResultsState = {
				...this.MediaStateService.searchResultsState,
				categoryPath
			};

			this.initCategories(categories);
		}
	}

	private initializePush(): void {
		this.unsubscribePush = this.PushBus.subscribe(this.accountId, 'Media.Videos', {
			VideoAnalyzed: data => {
				const video = this.getVideoFromVideos(data.videoId);
				if (video) {
					video.duration = this.DateParsers.parseTimespan(data.duration);
					video.status = data.status;
				}
			},

			OriginalVideoInstanceReplaced: data => {
				const video = this.getVideoFromVideos(data.videoId);
				if (video) {
					video.duration = this.DateParsers.parseTimespan(data.duration);
					video.status = data.status;
				}
			},

			OriginalVideoInstanceSwitched: data => {
				this.setStatus(data.videoId, data.status);
			},

			VideoProcessingFailed: data => {
				const video = this.getVideoFromVideos(data.videoId);
				if (video) {
					video.status = VideoStatus.PROCESSING_FAILED;
				}
			},

			VideoTranscoded: data => {
				this.setStatus(data.videoId, VideoStatus.READY);
			},

			VideoFastStartSet: data => {
				this.setStatus(data.videoId, data.status);
			},

			VideoInstanceStoringFinished: data => {
				this.setStatus(data.videoId, data.status);
			}
		});
	}

	private isReady(video: any): boolean { // unused?
		return video.status === VideoStatus.READY;
	}

	private setStatus(videoId: string, status: VideoStatus): void {
		const video = this.getVideoFromVideos(videoId);

		if (video) {
			video.status = status;
		}
	}

	private loadLegalHoldVideoCount(): Promise<void> {
		return this.SearchService.getFilteredVideos({
			accountId: this.accountId,
			legalHold: true,
			parsedQuery: this.SearchFilterState.buildQuery(),
			count: 0,
			noScroll: true
		})
			.then(result => this.VideoSelectionModel.setLegalHoldVideos(result.totalHits));
	}

	public loadNextPage(): void {
		if (!this.categoryRoot && !this.currentLoadOperation && !this.isLoadComplete) {
			this.loadNextPageInternal();
		}
	}

	private loadNextPageInternal(): Promise<void> {
		this.pauseInfiniteScroll = true;
		const thisLoadOperation = this.currentLoadOperation = this.SearchService.getVideos(
			{
				...this.getVideoSearchParams(),
				count: this.pageSize,
				scrollId: this.scrollId
			})
			.then(result => {
				if ((thisLoadOperation as any).cancelled) {
					this.SearchService.closeScrollId(this.accountId, result.scrollId);
					return;
				}

				this.videos = this.videos ? [...this.videos, ...result.videos] : result.videos;
				this.totalVideos = result.totalHits;
				this.scrollId = result.scrollId;

				if (this.bulkEdit) {
					this.VideoSelectionModel.setVideos(this.videos, this.totalVideos);
				}

				this.isLoadComplete = this.videos.length >= this.totalVideos;
				this.pauseInfiniteScroll = this.isLoadComplete;
				this.currentLoadOperation = null;

				if(!this.MediaStateService.searchResultsState.mediaType || this.MediaStateService.searchResultsState.mediaType === MediaType.VIDEO) {
					this.MediaStateService.searchResultsState = {
						...this.MediaStateService.searchResultsState,
						categoryPath: this.categoryPath,
						mediaCount: this.totalVideos,
						mediaType: MediaType.VIDEO
					};
				}
			}) as ICancellablePromise;
		return thisLoadOperation;
	}

	public initiateInventoryReportDownload(): void {
		this.SearchService.getVideos({
			...this.getVideoSearchParams(),
			downloadSearch: true,
		})
			.then(() => this.downloadCsvDialog.open());
	}

	private getVideoSearchParams(): any {
		const parsedQuery = this.SearchFilterState.buildQuery();

		return {
			accountId: this.accountId,
			query: this.query,
			parsedQuery,
			useEditAcl: this.searchParams.useEditAcl,
			sortField: this.sortField,
			sortAscending: this.sortAscending,
			subtitles: true
		};
	}

	private loadRemaining(): Promise<any> {
		if (!this.isLoadComplete) {
			return this.loadNextPageInternal().then(() => this.loadRemaining());
		}

		return Promise.resolve();
	}

	private initCategories(categories: ICategory[]): void {
		const promiseQueue = createPromiseQueue<any>();
		this.promiseQueues.push(promiseQueue);
		this.categories = [];
		categories.forEach(category => promiseQueue.enqueue(() =>
			Promise.resolve(this.CategoryService.getCategoryActiveSearchContent(this.accountId, category.id))
				.then(result => {
					if(result.videoCount > 0 || category.canEdit) {
						this.categories = orderBy([
							...this.categories, {
								...category,
								...result
							}
						],
						(c: ICategory) => c.name?.toLowerCase(),
						true);
					}
				})));
	}

	private onReset(): void {
		this.VideoSelectionModel.reset();
	}

	private reloadVideos(): void {
		if (!this.blockReload) {
			if (this.currentLoadOperation) {
				this.currentLoadOperation.cancelled = true;
			}

			this.closeScrollId();

			if (this.bulkEdit) {
				this.loadLegalHoldVideoCount();
				this.onReset();
			}

			Object.assign(this, {
				videos: [],
				status: { loading: true },
				pauseInfiniteScroll: false
			});

			this.loadNextPageInternal()
				.then(() => this.status = { active: true })
				.catch(() => this.status = { error: true });
		}
	}

	public sortVideos(field: string, defaultDescending: boolean): void {
		this.MediaStateService.setIsSortDesc(this.sortField === field ? this.sortAscending : defaultDescending);
		this.MediaStateService.setSortField(field);
		this.$state.go('.', this.MediaStateService.getQueryParams(), { reload: true });
	}

	private closeScrollId(): void {
		if(this.scrollId) {
			this.SearchService.closeScrollId(this.accountId, this.scrollId);
			this.scrollId = null;
		}
	}

	public showSpinner(): boolean {
		return this.status.loading ||
			this.status.active && !this.videos && !this.categoryRoot;
	}
}
