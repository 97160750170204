import { Component, ElementRef, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/angular';

import { WEBCAST_FULLSCREEN_STATE, WEBCAST_VIEW_STATE } from 'rev-portal/scheduledEvents/webcast/Constants';

const THEMED_STATES = [WEBCAST_VIEW_STATE, WEBCAST_FULLSCREEN_STATE, 'portal.userProfile'];
const ADMIN_STATES = ['portal.admin'];

@Component({
	selector: 'vb-portal-styles',
	template: ''
})
export class VbPortalStylesComponent implements OnInit {
	constructor(
		private elementRef: ElementRef,
		private $state: StateService,
		private $transitions: TransitionService
	) {}

	public ngOnInit(): void {
		const element = this.elementRef.nativeElement.parentElement.parentElement;
		this.$transitions.onSuccess({}, () => {
			this.applyCssClass(element);
		});
	}

	private applyCssClass(element): void {
		element.classList.toggle('admin', this.isAdminState);
		element.classList.toggle('admin-light', this.isAdminState);
		element.classList.toggle('system', !this.$state.includes('portal') && !this.isThemedState);
		element.classList.toggle('theme-primary', this.isThemedState);
	}

	private get isThemedState(): boolean {
		return THEMED_STATES.some( state => this.$state.includes(state));
	}

	private get isAdminState(): boolean {
		return ADMIN_STATES.some(state => this.$state.includes(state));
	}

	private get isSystemState(): boolean {
		return !this.$state.includes('portal') && !this.isThemedState;
	}
}
