import { Component, Input } from '@angular/core';

import { StateService } from '@uirouter/angular';

import { VideoStatus } from 'rev-shared/media/VideoStatus';

import { VideoSearchResultsComponent } from './VideoSearchResults.Component';

import styles from './VideoSearchResultsTable.module.less';

@Component({
	selector: 'video-search-results-table',
	templateUrl: './VideoSearchResultsTable.Component.html'
})
export class VideoSearchResultsTableComponent {
	constructor(
		private $state: StateService,
		public videoSearchResults: VideoSearchResultsComponent
	) {}

	public readonly styles = styles;

	public get baseStateName(): string {
		return this.$state.current.name?.replace(/\.[^\.]*-sidebar$/g, '');
	}

	public isProcessing(video: any): boolean {
		return video.status === VideoStatus.PROCESSING ||
			video.status === VideoStatus.UPLOADING_FINISHED;
	}

	public isUploading(video: any): boolean {
		return video.status === VideoStatus.NOT_UPLOADED ||
			video.status === VideoStatus.UPLOADING;
	}

	public processingFailed(video: any): boolean {
		return video.status === VideoStatus.UPLOAD_FAILED ||
			video.status === VideoStatus.READY_BUT_PROCESSING_FAILED ||
			video.status === VideoStatus.PROCESSING_FAILED;
	}

	public hideTable(): boolean {
		return !(this.videoSearchResults.status.active &&
			(this.videoSearchResults.videos?.length || this.videoSearchResults.categories?.length));
	}
}
