<a target="_blank" rel="noopener noreferrer" [ngClass]="{
	'theme-accent-link': isTwoTierTheme,
	'main-nav-btn line-height-1 theme-header-txt': !isTwoTierTheme
	}" [hidden]="hasCustomHelpLinks" [href]="helpUri" [attr.aria-label]="'Help' | translate ">
	<span *ngIf="buttonMode" class="glyphicons circle_question_mark line-height-1"></span>
	<span *ngIf="!buttonMode">{{ 'Help' | translate }}</span>
</a>

<div class="dropdown align-right help-tray table-cell" dropdown #helpMenuDropDown="bs-dropdown" [hidden]="!hasCustomHelpLinks">
	<button type="button" class="dropdown-toggle" dropdownToggle [ngClass]="{
			'btn main-nav-btn': buttonMode,
			'theme-accent-link': !buttonMode,
			'btn-transparent util-uppercase': isTwoTierTheme
		 }" [attr.aria-label]="('UI_Menu' | translate) + '-' + ('Help' | translate )">
		<span class="glyphicons circle_question_mark line-height-1" *ngIf="buttonMode">
		</span>
		<span class="theme-accent-link" *ngIf="!buttonMode">
			{{ 'Help' | translate }}
			<span vbCaretDirective></span>
		</span>
	</button>
	<ul *dropdownMenu class="dropdown-menu help-nav navdrop-menu theme-header-bg theme-header-txt">
		<li>
			<a class="help-nav-link theme-header-bg theme-header-txt btn-icon-left" target="_blank" rel="noopener noreferrer" [href]="helpUri">
				<span class="glyphicons new_window_alt theme-accent-txt"></span>{{ 'Help' | translate }}
			</a>
		</li>
		<li class="divider"></li>
		<li *ngFor="let link of helpLinks.customHelpLinks">
			<a class="help-nav-link theme-header-bg theme-header-txt btn-icon-left" [target]="link.uri?.startsWith('mailto') ? undefined : '_blank'" rel="noopener noreferrer" [href]="link.uri">
				<span class="glyphicons new_window_alt theme-accent-txt"></span>{{link.label}}
			</a>
		</li>
	</ul>
</div>
