import { EMPTY, Observable } from 'rxjs';
import { switchMap, filter, startWith } from 'rxjs/operators';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

const UserRoute = 'User';

export function initUserContextMessageHandlers(
	LoginRedirectService: LoginRedirectService,
	PushBus: PushBus,
	UserContext: UserContextService) {

	UserContext.userIdChanged$.pipe(
		startWith<void>(null),
		switchMap(() => {
			if(!UserContext.isUserAuthenticated()) {
				return EMPTY;
			}

			const user = UserContext.getUser();
			return PushBus.getObservable(user.id, UserRoute, {
				LoggedOff: logOut,
				LoggedOffAutomatically: logOut,
				LockedOut: logOut,
				UserSuspended: logOut,
				UserDeleted: logOut,

				UserProfileUpdated: userInfo => {
					UserContext.updateUserInfo(userInfo);
					return EMPTY;
				},

				ApiUserProfileImageUpdated: userInfo => {
					UserContext.updateProfileImageUri(userInfo.profileImageUri);
					return EMPTY;
				}
			});
		})
	)
		.subscribe();

	function logOut(): Observable<void> {
		UserContext.logOutUser(true);
		LoginRedirectService.redirectToLogout();
		return EMPTY;
	}
}
