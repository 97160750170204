<dashboard-carousel [items]="teams" [headerText]="'Dashboard_Teams' | translate" headerSref="portal.team">

		<div class="carousel-cell" [ngClass]="styles.carouselCellItem" *ngFor="let team of teams">

			<section [ngClass]="[styles.carouselCell, styles.eventCell]">

				<div [ngClass]="styles.imageWrapper">

					<div class="fixed-ratio-wrapper" [ngClass]="styles.fixedRatioWrapper" [style.background-color]="team.bgColor">
						<div class="fixed-ratio">
							<a class="fixed-aspect-item" uiSref="portal.media.all" [uiParams]="{team: team.id}" [attr.aria-label]="team.name">
								<img [alt]="'ThumbnailForAria' | translate : { '0': team.name }" class="preview-image anchored-thumbnail fill-thumbnail" style="opacity: .5" *ngIf="team.thumbnailUri" [attr.data-flickity-lazyload]="team.thumbnailUri">
							</a>
						</div>
					</div>

					<div [ngClass]="styles.eventInfoContainer">
						<div [ngClass]="styles.eventInfoTitle" class="theme-primary-txt">
							<a class="theme-primary-txt" [title]="team.name" uiSref="portal.media.all" [uiParams]="{team: team.id}">
								{{team.name}}
							</a>
						</div>
						<span [ngClass]="styles.eventInfoDetails">
							{{'Media_NumVideos' | translate : {'0' : team.totalVideos } }}
						</span>
					</div>
				</div>
			</section>
		</div>
</dashboard-carousel>
