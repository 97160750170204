import { Component } from '@angular/core';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { SmallDisplayWidth } from 'rev-shared/ui/size/Size.Constants';

import { MainHeaderComponent } from './MainHeader.Component';

@Component({
	selector: 'nettrix-header',
	templateUrl: './NettrixHeader.Component.html'
})
export class NettrixHeader extends MainHeaderComponent {

	public themeStyleOverrides: IRules;

	public ngOnInit(): void {
		this.themeServiceSub = this.ThemeService.brandingSettings$.subscribe(brandingSettings => this.applyCssRules(brandingSettings));
	}

	public ngOnDestroy(): void {
		this.themeServiceSub?.unsubscribe();
	}

	public applyCssRules(brandingSettings: BrandingSettings): void {
		const themeAccentColor = brandingSettings.themeSettings.accentColor;
		const themeAccentFontColor = brandingSettings.themeSettings.accentFontColor;
		const headerFontColor = brandingSettings.headerSettings.fontColor;
		const headerBackgroundColor = brandingSettings.headerSettings.backgroundColor;
		this.themeStyleOverrides = {
			[`@media all and (max-width: ${SmallDisplayWidth}px)`]: {
				'main-header nav.main-nav': {
					'background': `${headerBackgroundColor}`
				}
			},
			[`
				.dropdown.netrix-dropdown.open:before,
				.dropdown.netrix-dropdown.open > .btn,
				.search-icon:after,
				.search-cell,
				.search-dropdown.open > .btn
			`]: {
				'background': `${themeAccentColor}`
			},
			'.dropdown.netrix-dropdown.open > .btn .theme-header-txt': {
				'color': `${themeAccentFontColor} !important`
			},
			[`
				.dropdown.netrix-dropdown:not(.open) > .btn:hover .theme-header-txt,
				search-header-dropdown .dropdown:not(.open) > .btn:hover .theme-header-txt
			`]: {
				'color': `${themeAccentColor} !important`
			},
			'notifications-menu-button .notifications-count': {
				'background': `${themeAccentColor}`,
				'color': `${themeAccentFontColor}`,
				'border-color': `${headerFontColor} !important`
			},
			'.theme-Nettrix .main-nav-btn.dropdown-toggle:not(.btn-mobile):after': {
				'background': `${headerBackgroundColor}`
			}
		};
	}
}
