import { Component, Input } from '@angular/core';

import styles from './VideoTile.Component.module.less';
import './video-tile.less';
import { VideoTileComponent } from './VideoTile.Component';

@Component({
	selector: 'video-tile-v2',
	templateUrl: './VideoTileV2.Component.html',
	host: {
		'[class]': 'hostClass'
	},
})

export class VideoTileV2Component extends VideoTileComponent {
	@Input() public hideVideoStatusText: boolean;

	public readonly styles = styles;
	public hostClass = this.styles.root;

	public get ownerName(): string {
		return this.video.ownerName ? this.video.ownerName : [this.video.ownerFirstName, this.video.ownerLastName].filter(Boolean).join(' ');
	}

	public get uploadDate(): Date {
		return this.video.whenUploaded ? this.video.whenUploaded : this.video.createdBy.when;
	}
}
