<ng-template [vbCssRules]="themeStyleOverrides"></ng-template>

<vb-flickity-carousel [flickityConfig]="flickityConfig" (onReady)="onCarouselReady($event.carousel)" (onSelect)="onCarouselSelect()">
	<div class="carousel-cell" *ngFor="let video of videos; index as $index">
		<div class="carousel-video" vbWatchDimensions [padding]="0" (dimensionsChanged)="height=$event.height;width=$event.width">
			<div class="carousel-item-wrapper" [ngStyle]="{
					'height': (height) + 'px',
					'width': (width) + 'px'
				}">
					<div class="slide-info-overlay theme-accent-btn" [hidden]="!(!isPlayerLoaded && video.playbacks)" uiSref="portal.video-playlist-playback" [uiParams]="{videoId: video.id, playlistId: 'featured'}">
						<h1>
							<a>
								{{ video.title }}
								<span class="glyphicons chevron-right"></span>
							</a>
						</h1>
					</div>

					<div class="overlay-play theme-hover-zone" role="button" tabindex="0" (click)="showPlayer($index)" [attr.aria-label]="'Video_Playback_PlayFeaturedVideoAria' | translate : { '0': video.title }" [hidden]="!(!isPlayerLoaded && video.playbacks)">

						<video-360-indicator *ngIf="video.is360">
						</video-360-indicator>

						<div class="circle theme-accent-btn">
							<span class="glyphicons play"></span>
						</div>
					</div>

					<img [attr.alt]="'ThumbnailForAria' | translate : { '0': video.title }" [attr.data-flickity-lazyload]="video.thumbnailUri" [hidden]="isPlayerLoaded" [ngStyle]="{
						'height': (height) + 'px',
						'width': (width) + 'px'}"/>

					<div class="centered-text-block video-playback-status-msg" [hidden]="video.playbacks">
						<div class="centered-text-block-content video-playback-error-msg">
							<h3>
								<a uiSref="portal.video" [uiParams]="{videoId: video.id}">
									<span class="glyphicons warning_sign"></span>
									{{ 'Video_Playback_UnhandledPlaybackError' | translate }}</a>
							</h3>
							<h5>
								<a uiSref="portal.video" [uiParams]="{videoId: video.id}">
									{{ 'Video_Playback_SeeVideoPageForDetails' | translate }}
								</a>
							</h5>
						</div>
					</div>

					<vb-video-player *ngIf="
							video.playbacks &&
							$index === carousel?.selectedIndex &&
							(displayPlayer === $index || isMobile)" [style.opacity]="isMobile && displayPlayer !== $index ? 0 : 1" [thumbnailUri]="video.thumbnailUri" [thumbnailCfg]="video.thumbnailCfg" [hasAudioOnly]="video.hasAudioOnly" [playbacks]="video.playbacks" [subtitles]="video.subtitles" (onPlaybackEnded)="hidePlayer()" [durationMs]="video.duration" [live]="(video.linkedUrl?.type === 'Live')" [autoPlay]="true" [videoId]="video.id" [sourceType]="video.sourceType" (onPlayerReady)="onPlayerReady($event.vgAPI)" [heartbeatIntervalSecs]="video.heartbeatInterval" [isCaptionsAvailable]="video.closedCaptionsEnabled" [is360]="video.is360" [videoTitle]="video.title">
					</vb-video-player>
			</div>
		</div>
	</div>
</vb-flickity-carousel>
