import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	NgZone
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAccountLicense } from 'rev-shared/security/IAccountLicense';
import { IDeclarations, IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { SmallDisplayWidth, MinWidthSmallMediaQuery, MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';
import { noop } from 'rev-shared/util';

import { FeaturedVideosSettings, BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { FeaturedCarouselType } from 'rev-portal/branding/FeaturedCarouselType';
import { MainHeaderPositionService } from 'rev-portal/navigation/mainHeader/MainHeaderPosition.Service';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

import './featured-videos.less';

const mobileFeaturedType = FeaturedCarouselType.InlinePlayer;

@Component({
	selector: 'featured-videos',
	templateUrl: './FeaturedVideos.Component.html'
})
export class FeaturedVideosComponent implements OnInit, OnDestroy {
	@Input() public accountLicense: IAccountLicense;
	@Input() public videos: any[];

	public brandingSettings: BrandingSettings;

	public readonly cssRules$ = combineLatest(
		this.MainHeaderPosition.height$,
		this.ThemeService.brandingSettings$
	).pipe(
		map(([headerHeight, brandingSettings]) => {
			this.brandingSettings = brandingSettings;
			return brandingSettings && this.getCssRules(headerHeight, brandingSettings);
		})
	);

	private isMobile = window.innerWidth <= SmallDisplayWidth;

	public get featuredCarouselType(): FeaturedCarouselType {
		return this.isMobile ? mobileFeaturedType : this.ThemeService.brandingSettings.featuredVideosSettings.featuredCarouselType;
	}

	constructor(
		public ThemeService: ThemeService,
		public MainHeaderPosition: MainHeaderPositionService,
		public zone: NgZone
	) {
	}

	public ngOnInit(): void {
		window.addEventListener('resize', this.resizeListener);
	}

	public ngOnDestroy(): void {
		window.removeEventListener('resize', this.resizeListener);
	}

	public get mediaViewingAllowed(): boolean {
		return this.accountLicense.mediaViewingAllowed;
	}

	private getCssRules(headerHeight, brandingSettings): IRules {
		const featuredSettings = brandingSettings.featuredVideosSettings;
		const featuredWrap = 'featured-videos .featured-videos-wrap';

		return {
			[featuredWrap]: this.getBgStyles(featuredSettings),

			[`@media ${MinWidthSmallMediaQuery}`]: {
				[featuredWrap]: {
					'min-height': `calc(66vh - ${headerHeight || 0}px)`
				}
			},

			[`@media ${MaxWidthSmallMediaQuery}`]: {
				[featuredWrap]: {
					'min-height': `32vh`
				}
			}
		};
	}

	private getBgStyles(featuredSettings: FeaturedVideosSettings): IDeclarations {
		if(featuredSettings.backgroundImageUri) {
			return {
				'background-image': `url(${featuredSettings.backgroundImageUri})`
			};
		}
		return {
			'background-color': featuredSettings.backgroundColor
		};
	}

	private resizeListener = () => {
		const isMobile = window.innerWidth <= SmallDisplayWidth;
		if(isMobile !== this.isMobile){
			this.isMobile = isMobile;
			this.zone.run(noop);
		}
	};
}
