<vb-title [title]="'ManageSubscriptions' | translate"></vb-title>
<vb-page-header [headerText]="'ManageSubscriptions' | translate"></vb-page-header>

<vb-ui-tab-panel [ngClass]="styles.tabPanel" flex="fill" class="height-full" [verticalFill]="false">
	<vb-ui-tab #channelsTab *ngIf="MediaFeaturesService.accountFeatures.enableContentNotifications" [heading]="'Title_Teams' | translate">
		<vb-ui-data-grid *ngIf="channelsTab.isActive" [ngClass]="styles.dataGrid" [rowData]="channels" [autoSizeAllColumns]="true" (gridReady)="onChannelGridReady($event.api)">
			<vb-ui-data-grid-column field="label" sort="asc" [sortable]="true" [minWidth]="500" [headerName]="'Channel_Name' | translate">
			</vb-ui-data-grid-column>

			<vb-ui-data-grid-column field="emailNotifications" [sortable]="true" [headerName]="'UserSubscription_EmailNotification' | translate" cellRenderer="toggles">
			</vb-ui-data-grid-column>
		</vb-ui-data-grid>
	</vb-ui-tab>
	<vb-ui-tab #categoriesTab [heading]="'Title_Categories' | translate" *ngIf="MediaFeaturesService.accountFeatures.enableCategories">
		<vb-ui-data-grid *ngIf="categoriesTab.isActive" [ngClass]="styles.dataGrid" [rowData]="categories" [autoSizeAllColumns]="true" (gridReady)="onCategoryGridReady($event.api)">
			<vb-ui-data-grid-column field="label" sort="asc" [sortable]="true" [minWidth]="500" [headerName]="'CategoryName' | translate" [cellRendererFramework]="CategoryCellRendererComponent">
			</vb-ui-data-grid-column>

			<vb-ui-data-grid-column *ngIf="MediaFeaturesService.accountFeatures.enableContentNotifications" field="emailNotifications" [sortable]="true" [headerName]="'UserSubscription_EmailNotification' | translate" cellRenderer="toggles">
			</vb-ui-data-grid-column>

			<vb-ui-data-grid-column *ngIf="MediaFeaturesService.accountFeatures.enableSpark" field="webexTeamsNotifications" [sortable]="true" [headerName]="'UserSubscription_WebexTeamsNotification' | translate" cellRenderer="toggles">
			</vb-ui-data-grid-column>
		</vb-ui-data-grid>
	</vb-ui-tab>
</vb-ui-tab-panel>