import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import './SpeechSearchResultsList.less';

@Component({
	selector: 'speech-search-results-list',
	templateUrl: './SpeechSearchResultsList.Component.html'
})
export class SpeechSearchResultsListComponent implements OnInit {
	@Input() public query: string;
	@Input() public results: any[];
	@Input() public videoId: string;

	public readonly maxShownByDefault: number = 3;
	public shownResults: any[];

	public isExpanded: boolean;

	public ngOnInit(): void {
		this.isExpanded = false;
		this.shownResults = this.results.slice(0, this.maxShownByDefault);
	}

	public toggleExpansion(): void {
		this.isExpanded = !this.isExpanded;
		this.shownResults = this.isExpanded ? this.results : this.results.slice(0, this.maxShownByDefault);
	}
}
