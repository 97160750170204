<vb-title [title]="playlist.name"></vb-title>

<div class="media-layout" layout="column" layout-gt-md="row" flex-wrap="false">
	<div class="media-layout-content" flex="fill">
		<playlist-details-table *ngIf="isTableViewMode; else tiles" class="box-block margin-top-10" [canEdit]="canEdit" [hasMediaEditAuth]="hasMediaEditAuth" [playlist]="playlist" [userId]="userId">
		</playlist-details-table>
		<ng-template #tiles>
			<playlist-detail-tiles class="box-block margin-top-10" [hasMediaEditAuth]="hasMediaEditAuth" [mediaFeatures]="mediaFeatures" [playlist]="playlist" [userId]="userId">
			</playlist-detail-tiles>
		</ng-template>
	</div>

	<!-- right sidebar -->
	<div class="right-sidebar" bottom-anchor="0" [vbUiSrefActive]="'is-open'" [activeState]="'.sharing-sidebar'" flex-gt-md="48px" flex-align-self="stretch">

		<div ui-view="right-sidebar-content" class="right-sidebar-content theme-primary-important">
		</div>

		<ul class="sidebar-buttons theme-primary">

			<!-- home -->
			<li class="mobile-visible-992">
				<a uiSref="." [attr.aria-label]="'SideBar_Home' | translate">
					<span class="glyphicons home"></span>
				</a>
			</li>

			<!-- Sharing -->
			<li [tooltip]="'Media_Sharing' | translate" placement="left" container="body" [hidden]="!canShare">
					<a uiSref=".sharing-sidebar" [uiOptions]="{location: false}" uiSrefActive="active theme-accent-link" vbUiSrefToggle [attr.aria-label]="'Media_Sharing' | translate">
							<span class="glyphicons share_alt"></span>
					</a>
			</li>

			<!-- Remove Selected -->
			<li class="sidebar-item-light" *ngIf="canEdit && isTableViewMode" [tooltip]="'Media_Playlists_RemoveVideos' | translate" placement="left" container="body">
				<button (click)="removeSelectedVideos()" [attr.aria-label]="'Media_Playlists_RemoveVideos' | translate">
					<span class="glyphicons bin"></span>
				</button>
			</li>

		</ul>
	</div>
</div>
