import { Component } from '@angular/core';

import { IPlaylist } from 'rev-shared/media/IPlaylist';
import { orderBy } from 'rev-shared/util/SortUtil';

import { BrowseUserPlaylistsComponent } from './BrowseUserPlaylists.Component';

import styles from './BrowseUserPlaylistsTable.module.less';

@Component({
	selector: 'browse-user-playlists-table',
	templateUrl: './BrowseUserPlaylistsTable.Component.html'
})
export class BrowseUserPlaylistsTableComponent extends BrowseUserPlaylistsComponent {
	public readonly styles = styles;

	public ngOnInit(): void {
		super.ngOnInit();
		this.updatePlaylistTypes();
	}

	public sortNonFeaturedPlaylist(): void {
		this.nonFeaturedPlaylists = orderBy(this.nonFeaturedPlaylists, playlist => playlist[this.sortProperty], this.ascending);
	}
}
