import {
	AfterViewInit,
	Component,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { StateService } from '@uirouter/angular';
import { partition } from 'underscore';
import { KEY_DELETE } from 'keycode-js';

import { PushBus } from 'rev-shared/push/PushBus.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { noop } from 'rev-shared/util';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';
import { IPlaylist } from 'rev-shared/media/IPlaylist';

import { MediaStateService } from 'rev-portal/media/MediaState.Service';

import {
	FEATURED_PLAYLIST_DETAIL_STATE_NAME,
	PLAYLIST_DETAIL_STATE_NAME
} from './StateDeclarations';

@Component({
	selector: 'browse-user-playlists',
	template: ''
})
export class BrowseUserPlaylistsComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() public playlists: any[];
	@Input() public userId: string;

	public readonly PLAYLIST_DETAIL_STATE_NAME = PLAYLIST_DETAIL_STATE_NAME;
	public readonly FEATURED_PLAYLIST_DETAIL_STATE_NAME = FEATURED_PLAYLIST_DETAIL_STATE_NAME;

	public all: boolean;
	public ascending: boolean;

	@ViewChild('deletePlaylistsConfirmation')
	public deletePlaylistsConfirmation: VbConfirmationDialogComponent;

	public selectedPlaylistIds: string[];
	public sortProperty: string;
	public featuredPlaylists: IPlaylist[];
	public nonFeaturedPlaylists: IPlaylist[];

	private onKeydownDocumentHandler: any;
	private playlistDeletedUnsubscribe: any;

	constructor(
		@Inject(DOCUMENT) private $document: Document,
		private $state: StateService,
		public MediaStateService: MediaStateService,
		private PlaylistService: PlaylistService,
		private PushBus: PushBus,
		private Translate: TranslateService
	) {}

	public ngOnInit(): void {
		Object.assign(this, {
			all: false,
			ascending: true,
			selectedPlaylistIds: [],
			sortProperty: 'name'
		});

		this.onKeydownDocumentHandler = (event: any) => this.onKeydown(event);
		this.$document.addEventListener('keydown', this.onKeydownDocumentHandler);
		this.MediaStateService.searchResultsState.getSelectedCount = () => this.selectedPlaylistIds?.length;

		this.subscribeToPush();
	}

	public ngAfterViewInit(): void {
		this.applyFeaturedPlaylistName();
	}

	public ngOnDestroy(): void {
		this.$document.removeEventListener('keydown', this.onKeydownDocumentHandler);
		this.playlistDeletedUnsubscribe();
	}

	public toggleAll(): void {
		const userPlaylists: any[] = this.playlists.filter((p: any) => !p.featured);

		this.all = !this.all;

		this.selectedPlaylistIds = this.all ? userPlaylists.map((p: any) => p.playlistId) : [];
		userPlaylists.forEach((p: any) => p.selected = this.all);

	}

	public toggleSelection(playlist: any): void {
		const selectedIds: string[] = this.selectedPlaylistIds;

		if (playlist.selected) {
			const index: number = selectedIds.findIndex((id: string) => id === playlist.playlistId);

			selectedIds.splice(index, 1);
		} else {
			selectedIds.push(playlist.playlistId);
		}

		playlist.selected = !playlist.selected;
	}

	public goToEditPlaylist(playlist): void {
		playlist.featured ?
			this.$state.go(FEATURED_PLAYLIST_DETAIL_STATE_NAME) :
			this.$state.go(PLAYLIST_DETAIL_STATE_NAME, { playlistId: playlist.playlistId });
	}

	public deleteSelectedPlaylists(): any {
		if (!this.selectedPlaylistIds.length) {
			return;
		}
		this.deletePlaylistsConfirmation
			.open().result
			.then(() => {
				const playlistsToRemove = this.splitByPlaylistIds(this.playlists, this.selectedPlaylistIds);

				this.playlists = playlistsToRemove.rejections;
				this.updatePlaylistTypes();
				this.selectedPlaylistIds = [];

				return this.deletePlaylists(playlistsToRemove.selections);
			})
			.catch(noop);
	}

	private applyFeaturedPlaylistName(): void {
		const featuredPlaylist: any = this.playlists.find((playlist: any) => playlist.featured);

		if (featuredPlaylist) {
			featuredPlaylist.name = this.Translate.instant('Media_Videos_Playlists_FeaturedVideos');
		}
	}

	private deletePlaylists(playlists: any[]): any {
		return Promise.all(playlists.map((playlist: any) =>
			this.PlaylistService.deletePlaylist(playlist.playlistId)
				.catch(() => {
					playlist.selected = false;
					playlist.error = true;
					this.playlists.push(playlist);
					this.updatePlaylistTypes();
				})
		)
		);
	}

	private splitByPlaylistIds(playlists: any[], ids: string[]): any {
		const [selections, rejections] = partition(playlists, (p: any) => ids.includes(p.playlistId));

		return {
			rejections,
			selections
		};
	}

	protected onKeydown(event: any): void {
		if (event.which === KEY_DELETE) {
			this.deleteSelectedPlaylists();
		}
	}

	protected subscribeToPush(): void {
		this.playlistDeletedUnsubscribe = this.PushBus.subscribe(this.userId, {
			PlaylistDeleted: (data: any) => {
				const playlistIndex: number = this.playlists.findIndex((p: any) => p.playlistId === data.playlistId);

				if (playlistIndex !== -1) {
					this.playlists.splice(playlistIndex, 1);
					this.updatePlaylistTypes();
				}
			}
		});
	}

	public updatePlaylistTypes(): void {
		this.featuredPlaylists = this.playlists.filter(playlist => playlist.featured);
		this.nonFeaturedPlaylists = this.playlists.filter(playlist => !playlist.featured);
	}

	public trackByIndex(index: number): number {
		return index;
	}
}
