import { Component, Input, OnInit } from '@angular/core';
import { MediaStateService } from '../MediaState.Service';

@Component({
	selector: 'browse-playlists-host',
	templateUrl: './BrowsePlaylistsHost.Component.html'
})
export class BrowsePlaylistsHostComponent implements OnInit {
	@Input() public playlists: any[];
	@Input() public userId: string;

	public isTableMode: boolean;

	constructor(
		private MediaStateService: MediaStateService
	){}

	public ngOnInit(): void {
		this.playlists = this.sortPlaylist();
		this.isTableMode = this.MediaStateService.isTableViewMode;
	}

	private sortPlaylist(): any[] {
		return this.playlists.sort((x, y) => {
			let valA: any = x.featured;
			let valB: any = y.featured;

			if (valA === valB) {
				valA = x.name.toUpperCase();
				valB = y.name.toUpperCase();
				return valA < valB ? -1 : valA > valB ? 1 : 0;
			}

			return valB > valA ? 1 :
				valB < valA ? -1 : 0;
		});
	}
}
