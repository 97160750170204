import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';

import { UIRouterModule } from '@uirouter/angular';

import { SparkModule } from 'rev-portal/media/spark/Spark.Module';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DataGridModule } from 'rev-shared/ui/dataGrid/DataGrid.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { TabPanelModule } from 'rev-shared/ui/tabPanel/TabPanel.Module';
import { VbPageHeaderModule } from 'rev-shared/ui/pageHeader/VbPageHeader.Module';

import { CategoryCellRendererComponent } from './CategoryCellRenderer.Component';
import { ManageSubscriptionsComponent } from './ManageSubscriptions.Component';
import { VbUserSubscriptionSelectComponent } from './VbUserSubscriptionSelect.Component';
import { VbUserSubscriptionsComponent } from './VbUserSubscriptions.Component';
import { states } from './StateDeclarations';

const components = [
	CategoryCellRendererComponent,
	ManageSubscriptionsComponent,
	VbUserSubscriptionSelectComponent,
	VbUserSubscriptionsComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		BsDropdownModule,
		ButtonsAngularModule,
		CommonModule,
		DataGridModule,
		DialogAngularModule,
		DirectivesAngularModule,
		FormsModule,
		HttpClientModule,
		SparkModule,
		TabPanelModule,
		TranslateModule,
		TranslateModule,
		UIRouterModule.forChild({
			states
		}),
		VbPageHeaderModule
	]
})
export class VbUserSubscriptionModule {}
