import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { VbTextAreaModule } from 'rev-shared/ui/textArea/VbTextArea.Module';
import { VbTimespanInputModule } from 'rev-shared/ui/timespanInput/VbTimespanInput.Module';
import { VbUiTooltipModule } from 'rev-shared/ui/tooltip/VbUiTooltip.Module';

import { VbUiShareLink } from './VbUiShareLink.Component';

@NgModule({
	declarations: [
		VbUiShareLink
	],
	exports: [
		VbUiShareLink
	],
	imports: [
		ButtonsAngularModule,
		CheckboxAngularModule,
		ClipboardModule,
		CommonModule,
		DirectivesAngularModule,
		FormsModule,
		TextInputAngularModule,
		TooltipModule,
		TranslateModule,
		ValidationAngularModule,
		VbTextAreaModule,
		VbTimespanInputModule,
		VbUiTooltipModule
	]
})
export class VbUiShareLinkModule {}
