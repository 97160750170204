import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { MediaUploadBtnModule } from 'rev-shared/ui/mediaUploadBtn/MediaUploadBtn.Module';
import { SignInBtnModule } from 'rev-shared/ui/signInBtn/SignInBtn.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { WindowSizeAngularModule } from 'rev-shared/ui/size/WindowSize.AngularModule';

import { MediaSearchToolbarModule } from './search/toolbar/MediaSearchToolbar.Module';
import { PlaylistsAngularModule } from './playlists/Playlists.AngularModule';
import { SearchModule } from './search/Search.Module';
import { SparkModule } from './spark/Spark.Module';
import { VideosLoaderModule } from './videos/VideosLoader.Module';

import { MediaContainerComponent } from './MediaContainer.Component';
import { configureMediaRouter, states } from './MediaStateDeclarations';

import './media-layout.less';

const declarations = [
	MediaContainerComponent
];

@NgModule({
	declarations,
	exports: declarations,
	imports: [
		CommonModule,
		DirectivesAngularModule,
		MediaSearchToolbarModule,
		MediaUploadBtnModule,
		PlaylistsAngularModule,
		SearchModule,
		SignInBtnModule,
		SparkModule,
		SpinnerAngularModule,
		UIRouterModule.forChild({ config: configureMediaRouter, states }),
		VideosLoaderModule,
		TranslateModule,
		WindowSizeAngularModule
	]
})
export class MediaModule {}
