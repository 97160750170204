import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { VbFlickityCarouselComponent } from 'rev-shared/ui/flickityCarousel/VbFlickityCarousel.AngularComponent';

import './video-playlist-carousel.less';

export interface IOnSelectionChangedEvent {
	videoId: string;
}

@Component({
	selector: 'video-playlist-carousel',
	templateUrl: './VideoPlaylistCarousel.Component.html'
})
export class VideoPlaylistCarousel {
	@Input() public currentVideoId: string;
	@Input() public videos: any[];
	@Output() protected onSelectionChanged: EventEmitter<IOnSelectionChangedEvent> = new EventEmitter();
	@ViewChild('carousel') public carousel: VbFlickityCarouselComponent;

	public readonly flickityConfig = {
		cellAlign: 'left',
		contain: true,
		draggable: true,
		groupCells: true,
		pageDots: false,
		prevNextButtons: false,
		setGallerySize: false
	};

	public onVideoClick(videoId: string): void {
		this.onSelectionChanged.emit({
			videoId
		});
	}
}
