<vb-title [title]="'Media_Playlists' | translate"></vb-title>

<div class="container-fluid media-layout-content">
	<div class="row tile-grid">
		<div class="col-xl-2 col-lg-3 col-sm-4 col-tablet-sm-6 col-xs-12" *ngFor="let playlist of playlists">
			<div class="tile-item tile-collection-item">
	 			<a [uiSref]="playlist.featured ? FEATURED_PLAYLIST_DETAIL_STATE_NAME : PLAYLIST_DETAIL_STATE_NAME" [uiParams]="playlist.featured ? {} : {playlistId: playlist.playlistId}" [attr.aria-label]="'Media_Playlists_GoToEditPlaylist' | translate">
					<div class="fixed-ratio-wrapper grid-item-list">
						<div class="fixed-ratio">
							<div class="col-sm-6" *ngFor="let thumbnailUri of playlist.thumbnailUris trackBy trackByIndex">
								<div class="image-wrapper">
									<div class="fixed-ratio-wrapper">
										<div class="fixed-ratio">
											<img [alt]="'ThumbnailForAria' | translate : { '0': playlist.name }" [src]="thumbnailUri || ( video.hasAudioOnly?'/shared/img/audio-thumbnail.png':'/shared/img/default-thumbnail.png')">
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6" *ngFor="let item of [1,2,3,4]">
								<div class="image-wrapper">
									<div class="fixed-ratio-wrapper">
										<div class="fixed-ratio">
											<div class="no-item"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="fixed-ratio-wrapper">
						<div class="info-container fixed-ratio">
							<h3>
								<span>{{ playlist.name }}</span>
							</h3>
							<h4 [ngPlural]="playlist.videoThumbnails?.length">
								<ng-template ngPluralCase="1">{{ 'Media_OneVideo' | translate }}</ng-template>
								<ng-template ngPluralCase="other">{{ 'Media_NumVideos' | translate : { '0': playlist?.videoThumbnails?.length } }}</ng-template>
							</h4>
						</div>
					</div>

					<div class="tile-footer group">
						<span class="pull-right">{{playlist.date | date:'mediumDate'}}</span>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>
