<div class="carousel-container">
	<button type="button" class="btn-carousel btn-carousel-prev" [attr.aria-label]="'Carousel_Previous' | translate" [disabled]="carousel.isPrevButtonDisabled" (click)="carousel.movePrevious()">
		<span class="glyphicon glyphicon-chevron-left"></span>
	</button>

	<vb-flickity-carousel class="flickity-carousel" #carousel [carouselItemModel]="videos" [flickityConfig]="flickityConfig">
		<div *ngFor="let playlistVideo of videos" class="carousel-cell" [ngClass]="playlistVideo.id === currentVideoId ? 'selected' : ''">
			<a class="cell-title" [attr.aria-label]="playlistVideo.title" [tooltip]="playlistVideo.title" [container]="'body'" [placement]="'top'" (click)="onVideoClick(playlistVideo.id)">
				<img [alt]="'ThumbnailForAria' | translate : { '0': playlistVideo.title }" [src]="playlistVideo.thumbnailUri || (playlistVideo.hasAudioOnly ? '/shared/img/audio-thumbnail.png' : '/shared/img/default-thumbnail.png')">
				{{ playlistVideo.title }}
			</a>
		</div>
	</vb-flickity-carousel>

	<button type="button" class="btn-carousel btn-carousel-next" [attr.aria-label]="'Carousel_Next' | translate" [disabled]="carousel.isNextButtonDisabled" (click)="carousel.moveNext()">
		<span class="glyphicon glyphicon-chevron-right"></span>
	</button>
</div>