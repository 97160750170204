<form class="theme-primary" #templateForm="ngForm" name="templateForm" [ngClass]="styles.wrapper" (submit)="submit()">
	<div class="modal-header theme-accent-bg theme-accent-border" [ngClass]="styles.header">
		<span class="theme-accent-txt">
			{{ (templateInfo.id
				? 'Rename'
				: 'Templates_SaveAsTemplate') | translate
			}}
		</span>
	</div>
	<div class="modal-body">
		<vb-ui-form-group [hidden]="hasTemplateSaved">
			<label for="templateName" #uiLabel>
				{{ 'Templates_TemplateName' | translate }}
			</label>
			<input #templateName="ngModel" type="text" name="templateName" id="templateName" vbRequired [(ngModel)]="templateInfo.name" uiControl vbUiTextInput vbClearOnChange="duplicateName" trim="blur"/>
			<div uiValidation>
				<div *ngIf="templateName.invalid" role="alert" class="margin-bottom-10" vbUiErrorMessage>
					<ng-container *ngIf="templateName.errors.required">
						{{ 'ThisFieldIsRequired' | translate }}
					</ng-container>
					<ng-container *ngIf="templateName.errors.duplicateName">
						{{ 'Templates_DuplicateNameError' | translate }}
					</ng-container>
				</div>
			</div>
		</vb-ui-form-group>

		<div [hidden]="!hasTemplateSaved" class="text-align-center" role="alert">
			{{ 'Templates_SaveSuccessMessage' | translate }}
		</div>
	</div>
	<div class="modal-footer new-panel-footer">
		<div class="pull-right">
			<button type="button" class="margin-right-5" [hidden]="hasTemplateSaved" [disabled]="isProcessing" (click)="closePopup()" vbUiBtnSecondaryNgx>
				{{ 'Cancel' | translate }}
			</button>
			<button type="submit" [hidden]="hasTemplateSaved" [disabled]="templateForm.invalid || isProcessing" vbUiBtnPrimaryNgx>
				<span [hidden]="isProcessing">
					{{ 'Save' | translate }}
				</span>
				<span [hidden]="!isProcessing">
					<vb-loading-spinner [size]="'small'"></vb-loading-spinner>
					{{ 'Processing' | translate }}
				</span>
			</button>
			<button type="button" [hidden]="!hasTemplateSaved" (click)="closePopup()" vbUiBtnPrimaryNgx>
				{{ 'Ok' | translate }}
			</button>

		</div>
	</div>
</form>
