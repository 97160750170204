<vb-confirmation-dialog #removeVideosConfirmation [title]="'Media_Playlists_RemoveVideos' | translate" [cancelText]="'Cancel' | translate" [actionText]="'Ok' | translate">
	<ng-container [ngPlural]="selectedVideos?.length">
		<ng-template ngPluralCase="1">{{ 'Media_Playlists_RemoveOneVideo' | translate }}</ng-template>
		<ng-template ngPluralCase="other">{{ 'Media_Playlists_RemoveNumVideos' | translate : { '0': selectedVideos?.length } }}</ng-template>
	</ng-container>
</vb-confirmation-dialog>

<div class="container-fluid">
	<div class="row">
		<div class="col-sm-12">
			<div class="file-list-wrap">
				<div class="file-list">
					<!-- bulk actions -->
					<div class="file-list-row file-list-header flex-container flex-table bulk-actions">
						<div class="file-list-header-col table-cell table-cell-order">
							<button [attr.aria-checked]="!!all" [attr.aria-label]="'Media_Playlists_SelectAllVideos_Aria' | translate" class="btn btn-white btn-select-all" role="checkbox" type="button" (click)="toggleAll()" [hidden]="!canEdit">
								<span class="glyphicons" [ngClass]="{'ok_2': all}"></span>
							</button>
						</div>

						<div class="file-list-header-col table-cell flex-fill">

						</div>
						<div class="file-list-header-col table-cell"></div>
						<div class="file-list-header-col table-cell"></div>
					</div>

					<!-- sorting header row -->
					<div class="file-list-row file-list-header clickable flex-container flex-table">
						<div class="file-list-header-col table-cell table-cell-order" (click)="sortPlaylists('playlistIndex')" role="button" [attr.aria-label]="'Media_Playlist_SortByVideoIndex' | translate">
							<span [ngClass]="{
									active: sort.property === 'playlistIndex',
									up: sort.ascending
								}">
								<span class="caret"></span>
							</span>
						</div>
						<div class="file-list-header-col table-cell flex-fill text-align-left">
							<a role="button" [ngClass]="{
									active: sort.property === 'title',
									up: sort.ascending
								}" (click)="sortPlaylists('title')">
								{{ 'Media_Videos_Name' | translate }}
								<span class="caret"></span>
							</a>
						</div>
						<div class="file-list-header-col table-cell">
								{{ 'Type' | translate }}
						</div>
						<div class="file-list-header-col table-cell">
							<a role="button" [ngClass]="{
									active: sort.property === 'duration',
									up: sort.ascending
								}" (click)="sortPlaylists('duration')">
								{{ 'Media_Videos_Time' | translate }}
								<span class="caret"></span>
							</a>
						</div>
					</div>
				</div>

				<!-- content -->

				<div class="file-list" [ngClass]="{ draggable: sort.property === 'playlistIndex' }" cdkDropList [cdkDropListDisabled]="!canEdit || sort.property !== 'playlistIndex'" (cdkDropListDropped)="savePlaylistOrder($event)">
					<div cdkDrag *ngFor="let video of playlist.videos" class="file-list-row animate-repeat flex-container flex-table" [ngClass]="{
							selected: video.selected,
							error: video.error,
							draggable: sort.property === 'playlistIndex',
							'video-owner': userId === video.uploaderUserId,
							'not-selectable': !canEdit,
							'not-ready': video.status === 'Uploading' || video.status === 'Processing',
							'uploading': video.status==='Uploading',
						    'inactive': video.isActive===false,
							'pending-approval': video.approvalStatus
								&& video.approvalStatus !== approvalStatusOptions.APPROVED
						    	&& video.approvalStatus !== approvalStatusOptions.REJECTED,
			                'rejected': video.approvalStatus === approvalStatusOptions.REJECTED
						}" (click)="toggleSelection(video); $event.stopPropagation();">
						<div class="table-cell table-cell-order" [hidden]="video.selected" [ngClass]="{'hide-on-hover': canEdit }">
							<span>{{ video.playlistIndex + 1 }}</span>
						</div>
						<div class="table-cell table-cell-order table-cell-remove full-cell-buttons show-on-hover" [hidden]="!canEdit">
							<button [attr.aria-checked]="!!video.selected" [attr.aria-label]="'Media_Playlists_SelectVideo_Aria' | translate : { '0': video.title }" class="btn btn-checkbox no-margin" role="checkbox" type="button" [ngClass]="{ active: video.selected }">
								<span class="glyphicons ok_2"></span>
							</button>
						</div>

						<div class="error-dismissable show-on-error">
							{{ 'Media_Playlists_ErrorTag' | translate }}
							<button class="btn btn-transparent" [attr.aria-label]="'Remove' | translate" (click)="video.error = false; $event.stopPropagation()">
								<span class="glyphicons circle_remove white"></span>
							</button>
						</div>

						<div class="flex-fill primary-cell table-cell thumbnail-cell">
							<div class="file-icon">
								<span class="glyphicons lock" [hidden]="!video.legalHold">
								</span>
								<span class="glyphicons volume_up" [hidden]="!video.hasAudioOnly">
								</span>
								<a class="image-wrapper" [attr.aria-label]="'ThumbnailForAria' | translate : { '0': video.title }" uiSref="portal.video-playlist-playback" [uiParams]="{ videoId: video.id, playlistId: playlist.id }">
									<img role="presentation" [src]="video.thumbnailUri || (video.hasAudioOnly ? '/shared/img/audio-thumbnail.png' : '/shared/img/default-thumbnail.png')" [alt]="'ThumbnailForAria' | translate : { '0': video.title }">
									<video-thumbnail-status [approvalStatus]="video.approvalStatus" [videoIsActive]="video.isActive" [videoStatus]="video.status" [videoThumbnailUri]="video.thumbnailUri">
									</video-thumbnail-status>
								</a>
							</div>

							<a uiSref="portal.video-playlist-playback" [uiParams]="{ videoId: video.id, playlistId: playlist.id }" class="file-name">{{ video.title }}</a>

							<unlisted-video-indicator [isVideoUnlisted]="video.unlisted" [hasMediaEditAuth]="hasMediaEditAuth || hasEditVideoAuth" [showOnlyIcon]="true">
							</unlisted-video-indicator>
							<video-360-indicator *ngIf="video.is360">
							</video-360-indicator>
						</div>

						<div class="table-cell">{{ (video.isLive ? 'Live' : 'VOD') | translate  }}</div>
						<div class="table-cell">{{ (video.duration | vbTimespan) || ('NotAvailable' | translate) }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
