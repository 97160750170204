<vb-title [title]="'Media_Playlists' | translate"></vb-title>

<vb-confirmation-dialog #deletePlaylistsConfirmation [title]="'Media_Playlists_DeletePlaylists' | translate" [cancelText]="'Cancel' | translate" [actionText]="'Delete' | translate">

	<ng-container [ngPlural]="selectedPlaylistIds?.length">
		<ng-template ngPluralCase="1">{{ 'Media_DeleteOnePlaylist' | translate }}</ng-template>
		<ng-template ngPluralCase="other">{{ 'Media_DeleteNumPlaylists' | translate : { '0': selectedPlaylistIds?.length } }}</ng-template>
	</ng-container>
</vb-confirmation-dialog>

<div class="media-layout" layout="column" layout-gt-md="row" flex-wrap="false">
	<div class="container-fluid media-layout-content" flex="fill">
		<div class="row">
			<div class="col-sm-12">
				<div class="file-list-wrap">
					<div class="file-list list-row-select">
					<!-- bulk actions -->
						<div class="file-list-row file-list-header flex-container flex-table bulk-actions">

							<div class="file-list-header-col table-cell table-cell-order">
								<button [attr.aria-checked]="!!all" [attr.aria-label]="'Media_Playlists_SelectAllPlaylists_Aria' | translate" class="btn btn-checkbox" role="checkbox" type="button" [ngClass]="all ? 'active' : ''" (click)="toggleAll()">
									<span class="glyphicons ok_2"></span>
								</button>
							</div>
							<div class="file-list-header-col table-cell flex-fill"></div>
							<div class="file-list-header-col table-cell"></div>
						</div>

						<!-- sorting header row -->
						<div class="file-list-row file-list-header clickable flex-container flex-table">
							<div class="file-list-header-col table-cell table-cell-order"></div>
							<div class="file-list-header-col table-cell flex-fill text-align-left">
								<a href="javascript:" [ngClass]="[
										sortProperty === 'name' ? 'active' : '',
										ascending ? 'up' : ''
									]" (click)="ascending = !ascending; sortProperty = 'name'; sortNonFeaturedPlaylist()" role="button">
										{{ 'Media_Playlists_Name' | translate }}
										<span class="caret"></span>
								</a>
							</div>
							<div class="file-list-header-col table-cell mobile-hidden">
								<a [ngClass]="{
										active: sortProperty === 'videoThumbnails',
										up: ascending
									}" (click)="ascending = !ascending; sortProperty='videoThumbnails'; sortNonFeaturedPlaylist()" role="button">
										{{ 'Media_Playlists_VideoCount' | translate }}
										<span class="caret"></span>
								</a>
							</div>
						</div>

						<!-- | filter: { featured: true} -->
						<div class="file-list-row not-selectable flex-container flex-table" *ngFor="let playlist of featuredPlaylists">
	 						<a [uiSref]="FEATURED_PLAYLIST_DETAIL_STATE_NAME" [attr.aria-label]="'Media_Playlists_GoToEditPlaylist' | translate" [ngClass]="styles.playlistLink">
							</a>
							<div class="table-cell table-cell-order disabled"></div>
							<div class="file-name table-cell flex-fill thumbnail-cell">
								<div class="icon">
									<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 35" enable-background="new 0 0 50 35" xml:space="preserve">
									<rect fill="#E6E6E6" width="50" height="35"/>
									<rect x="4" y="4" fill="#CCCCCC" width="12" height="8"/>
									<rect x="20" y="4" fill="#CCCCCC" width="26" height="8"/>
									<rect x="4" y="16" fill="#CCCCCC" width="12" height="8"/>
									<rect x="20" y="16" fill="#CCCCCC" width="26" height="8"/>
									<rect x="4" y="28" fill="#CCCCCC" width="12" height="7"/>
									<rect x="20" y="28" fill="#CCCCCC" width="26" height="7"/>
									<text transform="matrix(1 0 0 1 8.5723 12)" fill="#E6E6E6" font-size="8">1</text>
									<text transform="matrix(1 0 0 1 7.5801 24)" fill="#E6E6E6" font-size="8">2</text>
									<text transform="matrix(1 0 0 1 7.5122 35.8125)" fill="#E6E6E6" font-size="8">3</text>
									</svg>
								</div>
								<a (click)="goToEditPlaylist(playlist)">{{ playlist.name }}</a>
							</div>
							<div class="table-cell mobile-hidden">{{ playlist.videoThumbnails.length }}</div>
						</div>

						<!-- | filter: { featured: false } | orderBy: sortProperty: !ascending -->
						<div *ngFor="let playlist of nonFeaturedPlaylists" class="file-list-row animate-repeat flex-container flex-table" [ngClass]="{error: playlist.error}">
	 						<a [uiSref]="PLAYLIST_DETAIL_STATE_NAME" [uiParams]="{playlistId: playlist.playlistId}" [attr.aria-label]="'Media_Playlists_GoToEditPlaylist' | translate" [ngClass]="styles.playlistLink">
							</a>
							<div class="table-cell table-cell-buttons">
								<button [attr.aria-checked]="!!playlist.selected" [attr.aria-label]="'Media_Playlists_SelectPlaylist_Aria' | translate : { '0': playlist.name }" class="btn btn-checkbox" role="checkbox" type="button" [ngClass]="{'active': playlist.selected}" (click)="toggleSelection(playlist)">
									<span class="glyphicons ok_2"></span>
								</button>
							</div>

							<div class="error-dismissable show-on-error">
								{{ 'Media_Playlists_ErrorTag' | translate }}
								<button class="btn btn-transparent" (click)="playlist.error = false; $event.stopPropagation()" [attr.aria-label]="'Remove' | translate">
									<span class="glyphicons circle_remove white"></span>
								</button>
							</div>

							<div class="table-cell flex-fill thumbnail-cell">
								<div class="icon">
									<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 35" enable-background="new 0 0 50 35" xml:space="preserve">
									<rect fill="#E6E6E6" width="50" height="35"/>
									<rect x="4" y="4" fill="#CCCCCC" width="12" height="8"/>
									<rect x="20" y="4" fill="#CCCCCC" width="26" height="8"/>
									<rect x="4" y="16" fill="#CCCCCC" width="12" height="8"/>
									<rect x="20" y="16" fill="#CCCCCC" width="26" height="8"/>
									<rect x="4" y="28" fill="#CCCCCC" width="12" height="7"/>
									<rect x="20" y="28" fill="#CCCCCC" width="26" height="7"/>
									<text transform="matrix(1 0 0 1 8.5723 12)" fill="#E6E6E6" font-size="8">1</text>
									<text transform="matrix(1 0 0 1 7.5801 24)" fill="#E6E6E6" font-size="8">2</text>
									<text transform="matrix(1 0 0 1 7.5122 35.8125)" fill="#E6E6E6" font-size="8">3</text>
									</svg>
								</div>

								<span class="file-name">{{ playlist.name }}</span>
							</div>
							<div class="table-cell mobile-hidden">{{ playlist.videoThumbnails?.length }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- right sidebar -->
	<div class="right-sidebar" bottom-anchor="0" uiSrefActive="{'is-open': '**.*-sidebar.**'}" flex-gt-md="48px" flex-align-self="stretch">

		<div ui-view="right-sidebar-content" class="right-sidebar-content">
		</div>

		<ul class="sidebar-buttons theme-primary" role="menu" [attr.aria-label]="'SideBarMenu' | translate">

			<!-- home -->
			<li class="mobile-visible-992" role="none">
				<a uiSref="." [attr.aria-label]="'SideBar_Home' | translate" role="menuitem">
					<span class="glyphicons home"></span>
				</a>
			</li>

			<!-- Delete Selected Playlists -->
			<li class="sidebar-item-light" *ngIf="MediaStateService.isTableViewMode" [tooltip]="'Media_Playlists_DeletePlaylists' | translate" placement="left" container="true" role="none">
				<button (click)="deleteSelectedPlaylists()" role="menuitem" [attr.aria-label]="'Media_Playlists_DeletePlaylists' | translate">
					<span class="glyphicons bin"></span>
				</button>
			</li>

		</ul>
	</div>
</div>
