<vb-title *ngIf="bulkEdit" [title]="'Title_BulkEditVideos' | translate"></vb-title>
<vb-confirmation-dialog #downloadCsvDialog [title]="'InitiateDownload' | translate" [message]="'InitiateDownload_Confirmation' | translate" [actionText]="'Ok' | translate">
</vb-confirmation-dialog>

<div class="media-layout" layout="column" layout-gt-md="row" flex-wrap="false">

	<div class="media-layout-content fadein-effect" flex="fill">

		<div class="container-fluid" infiniteScroll (scrolled)="loadNextPage()" [infiniteScrollDisabled]="pauseInfiniteScroll" [infiniteScrollDistance]="2">

			<vb-loading-spinner [block]="true" [size]="'large'" [hidden]="!showSpinner()"></vb-loading-spinner>

			<vb-search-filter-list class="row" *ngIf="!mediaState.isFilteringDisabled"></vb-search-filter-list>

			<div [ngSwitch]="viewMode">
				<video-search-results-table *ngSwitchCase="'table'"></video-search-results-table>
				<video-search-results-tiles *ngSwitchCase="'tiles'"></video-search-results-tiles>
			</div>

			<!--No results display-->
			<div class="centered-text-block-content" [hidden]="!isLoadComplete || videos?.length || categories?.length">
				<h2>{{ 'UI_NoResultsFound' | translate }}</h2>
			</div>
		</div>
	</div>
	<!-- right sidebar -->
	<div class="right-sidebar" [vbUiSrefActive]="'is-open'" [activeState]="'-sidebar'" [ngClass]="[
			bulkEdit ? 'bulk-edit-sidebar' : '',
			bulkEdit && hasLockedVideos ? 'force-closed' : ''
		]" *ngIf="!mediaState.isFilteringDisabled" flex-gt-md="48px" flex-align-self="stretch">

		<div ui-view="right-sidebar-content" class="right-sidebar-content">
		</div>

		<ul class="sidebar-buttons theme-primary">

			<!-- home -->
			<li class="mobile-visible-992">
				<a uiSref="." [attr.aria-label]="'Home' | translate">
					<span class="glyphicons home"></span>
				</a>
			</li>

			<!-- filter -->
			<li [tooltip]="'Media_FiltersTooltip' | translate" placement="left" container="body">
				<a uiSref=".filters-sidebar" [uiParams]="{ filters: null }" [uiOptions]="{location: false, relative: currentStateName}" uiSrefActive="active theme-accent" vbUiSrefToggle [attr.aria-label]="'Media_FiltersTooltip' | translate">
					<span class="glyphicons filter"></span>
				</a>
			</li>

			<!-- bulk edit -->
			<li [hidden]="!MediaStateService.showBulkEdit
				|| !userHasEditableVideos
				|| mediaState.isBulkEditDisabled" [tooltip]="'Media_BulkEdit' | translate" placement="left" container="body">
				<a [title]="'Media_BulkEdit' | translate" [attr.aria-label]="'Media_BulkEdit' | translate" uiSref="portal.media.edit" [uiParams]="{categoryId: categoryId, q: query}" uiSrefActive="active theme-accent" vbUiSrefToggle>
					<span class="glyphicons edit"></span>
				</a>
			</li>

			<!-- Bulk Edit Options -->
		<li class="sidebar-item-light bulk-edit-settings" [hidden]="hasLockedVideos" *ngIf="bulkEdit" [tooltip]="'Media_BulkEdit_EditTooltip' | translate" placement="left" container="body">
			<a class="icon-dark" uiSref=".bulk-edit-sidebar" [uiOptions]="{location: false}" uiSrefActive="active theme-accent" vbUiSrefToggle [attr.aria-label]="'Media_BulkEdit_EditTooltip' | translate">
				<span class="glyphicons cogwheel"></span>
			</a>
		</li>
		<li class="sidebar-item-light" [hidden]="hasLockedVideos" *ngIf="bulkEdit" [tooltip]="'Media_BulkEdit_DeleteVideos' | translate" placement="left" container="body">
			<vb-delete-videos-button [loadSearchResults]="forceLoadAllSearchResults">
			</vb-delete-videos-button>
		</li>

		<li *ngIf="bulkEdit && mediaFeatures.enableLegalHold">
			<legal-hold-sidebar></legal-hold-sidebar>
		</li>


		<li class="sidebar-item-light bulk-edit-cancel" *ngIf="bulkEdit" [tooltip]="'Media_BulkEdit_Cancel' | translate" placement="left" container="body">
			<a class="icon-dark" [href]="mediaState.backLink" [attr.aria-label]="'Media_BulkEdit_Cancel' | translate">
				<span class="glyphicons circle_remove"></span>
			</a>
		</li>

		<li *ngIf="hasMediaEditAuth" [tooltip]="'InventoryReportsDownload' | translate" placement="left" container="body" class="download-inventory-reports">
			<a [attr.aria-label]="'InventoryReportsDownload' | translate" role="button" (click)="initiateInventoryReportDownload()">
				<span [ngClass]="styles.sidebarVbIcon" class="vb-icon vb-icon-csv"></span>
			</a>
		</li>

		</ul>
	</div>
</div>
