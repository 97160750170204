import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { SelectAngularModule } from 'rev-shared/ui/select/SelectAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbTextAreaModule } from 'rev-shared/ui/textArea/VbTextArea.Module';
import { VbTimespanInputModule } from 'rev-shared/ui/timespanInput/VbTimespanInput.Module';
import { VbUiColorSelectorModule } from 'rev-shared/ui/color-selector/VbUiColorSelector.Module';
import { VbUiTooltipModule } from 'rev-shared/ui/tooltip/VbUiTooltip.Module';

import { VbUiShareEmbedComponent } from './VbUiShareEmbed.Component';


@NgModule({
	declarations: [
		VbUiShareEmbedComponent
	],
	exports: [
		VbUiShareEmbedComponent
	],
	imports: [
		ButtonsAngularModule,
		ClipboardModule,
		CommonModule,
		FormsModule,
		SelectAngularModule,
		TextInputAngularModule,
		TranslateModule,
		ValidationAngularModule,
		ValidationModule,
		VbTextAreaModule,
		VbTimespanInputModule,
		VbUiColorSelectorModule,
		VbUiTooltipModule
	]
})
export class VbUiShareEmbedModule {}
