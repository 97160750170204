import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, AfterViewInit, NgZone, OnDestroy } from '@angular/core';

import { ApprovalStatus } from 'rev-shared/media/MediaConstants';
import { VideoStatus } from 'rev-shared/media/VideoStatus';

import { IVideoPlaybackConfig } from 'rev-portal/media/videos/videoPlayback/IVideoPlaybackConfig';

import './video-tile.less';
import { IThumbnailSheetConfig } from '../thumbnailSheetPreview/VbUiThumbnailSheetPreview.Component';

@Component({
	selector: 'video-tile',
	templateUrl: './VideoTile.Component.html'
})

export class VideoTileComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() public config: IVideoPlaybackConfig;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public isGuest: boolean;
	@Input() public mediaFeatures: any;
	@Input() public playlist: any;
	@Input() public userId: string;
	@Input() public video: any;

	@ViewChild('fixedRatioWrapper') public fixedRatioWrapper: ElementRef;

	constructor(private zone: NgZone) {}

	public readonly approvalStatusOptions = ApprovalStatus;
	public readonly videoStatusOptions = VideoStatus;
	public thumbnailWidth: number;
	public thumbnailHeight: number;
	public thumbnailSheets: IThumbnailSheetConfig;
	private resizeObserver: ResizeObserver;

	public ngOnInit(): void {
		if (typeof this.video.thumbnailSheets === 'string') {
			this.thumbnailSheets = JSON.parse(this.video.thumbnailSheets);
		} else if (this.video.thumbnailSheets) {
			this.thumbnailSheets = this.video.thumbnailSheets;
		} else {
			this.thumbnailSheets = undefined;
		}
	}

	public ngAfterViewInit(): void {
		this.resizeObserver = new ResizeObserver(entries => {
			this.zone.run(() => {
				this.thumbnailWidth = entries[0].contentRect.width;
				this.thumbnailHeight = entries[0].contentRect.height;
			});
		});
		this.resizeObserver.observe(this.fixedRatioWrapper.nativeElement);
	}

	public ngOnDestroy(): void {
		this.resizeObserver.unobserve(this.fixedRatioWrapper.nativeElement);
	}

	public get videoStatus(): string {
		return this.video.status;
	}

	public get ownerName(): string {
		return [this.video.ownerFirstName, this.video.ownerLastName].filter(Boolean).join(' ');
	}

	public get uploaderName(): string {
		return [this.video.uploaderFirstName, this.video.uploaderLastName].filter(Boolean).join(' ');
	}

	public get hasEditVideoAuth(): boolean {
		return this.video && (this.video.editAcl || []).includes(this.userId);
	}

	public get isUploading(): boolean {
		return this.videoStatus === VideoStatus.NOT_UPLOADED ||
			this.videoStatus === VideoStatus.UPLOADING;
	}

	public get isProcessing(): boolean {
		return this.videoStatus === VideoStatus.PROCESSING ||
			this.videoStatus === VideoStatus.UPLOADING_FINISHED;
	}

	public get isProcessingFailed(): boolean {
		return this.videoStatus === VideoStatus.UPLOAD_FAILED ||
			this.videoStatus === VideoStatus.PROCESSING_FAILED ||
			this.videoStatus === VideoStatus.READY_BUT_PROCESSING_FAILED;
	}

	public get isPasswordProtected(): boolean {
		return this.isGuest && this.video.hasPassword;
	}
}
