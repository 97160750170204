import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';

import { AccessControl } from 'rev-shared/media/MediaConstants';
import { ConfirmationDialogAngularComponent } from 'rev-shared/ui/dialog/ConfirmationDialogAngular.Component';
import { DateUtil } from 'rev-shared/date/DateUtil';
import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { FieldType } from 'rev-shared/metadata/FieldType';
import { IAccessControlEntity } from 'rev-shared/media/videoSettings/VideoSettings.Contract';
import { IInsightRecord } from 'rev-shared/ui/insight/ngx/Insight.Contract';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserAuthorizationService } from 'rev-shared/security/UserAuthorization.Service';
import { firstValueFrom } from 'rev-shared/rxjs/firstValueFrom';
import { formatIsoDate, formatShortTime } from 'rev-shared/date/DateFormatters';
import { noop } from 'rev-shared/util';
import { VbTranslateService } from 'rev-shared/util/VbTranslate.Service';

import { InsightSearchHelper, ISearchResult } from 'rev-portal/search/InsightSearchHelper.Service';
import { MediaStateService } from 'rev-portal/media/MediaState.Service';
import { VideoSearchResultsComponent } from 'rev-portal/media/search/VideoSearchResults.Component';

import { IBulkEditOperation, BulkEditOperator, BulkEditFormStateService, IBulkEditForm } from './BulkEditFormState.Service';
import { BulkEditService } from './BulkEdit.Service';
import { VideoSelectionModelService } from './VideoSelectionModel.Service';

import './bulk-edit.less';
import 'rev-less/media/sidebar.less';

@Component({
	selector: 'bulk-edit-videos',
	templateUrl: './BulkEditVideos.Component.html',
	host: {
		class: 'container-fluid'
	}
})

export class BulkEditVideosComponent implements OnDestroy, OnInit {
	@Input() public accountId: string;
	@Input() public categories: any[];
	@Input() public expirationRules: any[];
	@Input() public mediaFeatures: any;
	@Input() public teams: any[];

	public readonly AccessControl = AccessControl;
	public readonly BulkEditOperator = BulkEditOperator;
	public readonly FieldType = FieldType;
	public readonly removeReplaceAppendOptions = [BulkEditOperator.append, BulkEditOperator.replace, BulkEditOperator.remove];
	public readonly removeReplaceOptions = [BulkEditOperator.replace, BulkEditOperator.remove];
	public readonly today: Date = DateUtil.getToday();
	public readonly midnight: string = formatShortTime(this.today);
	public readonly uiEnabledDisabled = [
		{ value: true, label: 'UI_Enabled' },
		{ value: false, label: 'UI_Disabled' }
	];
	public readonly accessControlLabels = {
		Public: 'Media_Videos_Settings_Public',
		Private: 'Media_Videos_Settings_Private',
		AllUsers: 'Media_Videos_Settings_AllUsers',
		Teams: 'Media_Videos_Settings_Teams',
	};

	public accessControlInsightOptions: any;
	public accessOptions: AccessControl[];
	public expirationConfig: any = { expiryRuleType: 'Date' };
	public fields: IBulkEditForm;
	public searchQuery: (query: string) => () => Promise<ISearchResult>;
	public showAccessControlEntities: boolean;
	public userTagsInsightOptions: any;

	private preservedExpirationValue;
	private processingDialog: ConfirmationDialogAngularComponent;
	private processingDialogTimer: number;

	constructor(
		private $state: StateService,
		private BulkEditFormState: BulkEditFormStateService,
		private BulkEditService: BulkEditService,
		private Dialog: DialogService,
		private InsightSearchHelper: InsightSearchHelper,
		private MediaStateService: MediaStateService,
		private SecurityContext: SecurityContextService,
		private UserAuthorization: UserAuthorizationService,
		private VbTranslate: VbTranslateService,
		public VideoSelectionModel: VideoSelectionModelService,
		public readonly translate: TranslateService,
		public videoSearchResults: VideoSearchResultsComponent
	) {	}

	public ngOnInit(): void {
		this.fields = this.BulkEditFormState.fields;

		this.accessOptions = [
			this.SecurityContext.checkAuthorization('media.setVideoPublic') && this.mediaFeatures.enablePublicVideoAccess && AccessControl.Public,
			AccessControl.AllUsers,
			AccessControl.Private
		].filter(Boolean);

		this.userTagsInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: true
		});

		this.expirationConfig = {
			expiryRuleType: 'Date'
		};
		this.searchQuery = this.InsightSearchHelper.getUserSearchLoader(this.accountId, []);
	}

	public ngOnDestroy(): void {
		this.closeProcessingDialog();
		this.fields.accessControlEntities.operation = null;
		this.fields.accessControlEntities.value = null;
	}

	public cancel(): void {
		if (this.BulkEditFormState.getBulkOperations().length) {
			 this.Dialog.openConfirmationDialog({
				title: this.translate.instant('VideoSettings'),
				message: this.translate.instant('Media_BulkEdit_CancelConfirmation'),
				cancelText: this.translate.instant('Cancel'),
				actionText: this.translate.instant('Ok')
			}).result
				.then(() => this.onCancel(), noop);

			return;
		}

		this.onCancel();
	}

	private ensureSelectionLoaded(): Promise<any> {
		return Promise.resolve(this.VideoSelectionModel.isSelectAll && this.videoSearchResults.forceLoadAllSearchResults());
	}

	public filterCustomFieldOperations(isRequired: boolean): BulkEditOperator[] {
		return isRequired
			? [BulkEditOperator.replace]
			: this.removeReplaceOptions;
	}

	public onAccessControlChange(): void {
		this.addEditAccessToEntities();
	}

	private onAccessControlEntitiesChange(): void {
		this.addEditAccessToEntities();
	}

	public onAccessControlEntitiesOperationChange(): void {
		this.showAccessControlEntities = false;
		const hasContentCreatorRights = this.UserAuthorization.hasContentCreatorRights();
		this.accessControlInsightOptions = undefined;
		this.accessControlInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: this.accountId,
			users: hasContentCreatorRights || this.fields.accessControlEntities.operation === BulkEditOperator.remove,
			groups: hasContentCreatorRights || this.fields.accessControlEntities.operation === BulkEditOperator.remove,
			teams: true,
			showEditAccessInput: (item: IInsightRecord) => item.type !== 'Team'
		});
		setTimeout(() => this.showAccessControlEntities = this.fields.accessControlEntities.operation !== null, 100);
	}

	private addEditAccessToEntities(): void {
		const forceEditAccess: boolean = this.fields.accessControl.value && this.fields.accessControl.value !== AccessControl.Private;
		if (forceEditAccess) {
			this.fields.accessControlEntities.value.forEach((entity: IAccessControlEntity) => {
				entity.canEdit = true;
			});
		}
	}

	private onCancel(): void {
		this.BulkEditFormState.init();
		this.$state.go('^');
	}

	public onExpirationChange(expirationValue: any): void {
		if (expirationValue && expirationValue.expirationDate) {
			expirationValue.expirationDate = formatIsoDate(expirationValue.expirationDate);
		}

		this.fields.expiration.value = expirationValue;
	}

	public onStatusChange(): void {
		const isActive: boolean = this.fields.isActive.value;

		if (!isActive && this.fields.expiration.value) {
			this.preserveExpirationValue();
		} else if (isActive && this.preservedExpirationValue) {
			this.restoreExpirationValue();
		}
	}

	private preserveExpirationValue(): void {
		this.preservedExpirationValue = this.fields.expiration.value;
		this.onExpirationChange(null);
	}

	private restoreExpirationValue(): void {
		this.onExpirationChange(this.preservedExpirationValue);
		this.preservedExpirationValue = null;
	}

	public get hasAnyOperations(): boolean {
		return this.BulkEditFormState.getBulkOperations().length > 0;
	}

	public submit(): void {
		const operations = this.BulkEditFormState.getBulkOperations();

		if(!this.VideoSelectionModel.selectionCountValid || !operations.length) {
			return;
		}

		this.Dialog.openConfirmationDialog({
			title: this.translate.instant('VideoSettings'),
			message: this.translate.instant('Media_BulkEdit_SaveConfirmation', { 0: this.VideoSelectionModel.selectionCount }),
			cancelText: this.translate.instant('Cancel'),
			actionText: this.translate.instant('Save')
		})
			.result
			.then(() => this.saveVideos(operations), noop);
	}

	private saveVideos(operations: IBulkEditOperation[]): void {
		this.openProcessingDialog();

		this.ensureSelectionLoaded()
			.then(() => this.BulkEditService.updateVideos(
				this.VideoSelectionModel.selectedVideos.map(v => v.id),
				operations)
			)
			.then(({ message }) => {
				this.closeProcessingDialog();

				return firstValueFrom(
					this.Dialog.getDialog('bulkEditSubmission')
						.open({
							initialState: {
								legalHoldVideosCount: (message.legalHoldVideoIds || []).length,
								isEditing: true
							}
						}).onHide
				).then(() => this.MediaStateService.navigateBack());
			})
			.catch(e => {
				console.error('unable to submit bulk action', e);
				this.Dialog.openAlertDialog(...this.VbTranslate.translateKeys([
					'Error',
					'Media_BulkEdit_ProcessingError',
					'Ok'])
				);
			})
			.finally(() => this.closeProcessingDialog());
	}

	private openProcessingDialog(): void {
		this.processingDialogTimer = window.setTimeout(() =>
			this.processingDialog = this.Dialog.openConfirmationDialog({
				title: this.translate.instant('VideoSettings'),
				message: this.translate.instant('Media_BulkEdit_Processing')
			}),
		1000);
	}

	private closeProcessingDialog(): void {
		window.clearTimeout(this.processingDialogTimer);

		if (this.processingDialog) {
			this.processingDialog.hide();
		}

		this.processingDialogTimer = this.processingDialog = null;
	}

	public getOperatorLabel(op: BulkEditOperator): string {
		return this.translate.instant({
			Remove: 'Media_BulkEdit_Remove',
			Replace: 'Media_BulkEdit_Replace',
			Append: 'Media_BulkEdit_Append',
			Public: 'Media_Videos_Settings_Public',
			Private: 'Media_Videos_Settings_Private',
			AllUsers: 'Media_Videos_Settings_AllUsers',
			Teams: 'Media_Videos_Settings_Teams'
		}[op]);
	}

	public log(obj: any): void{
		console.log(obj);
	}

	private validatePublishDateFuture(): boolean {
		//Check if selection contains/will contain active videos post update
		return this.fields?.isActive?.value ?? this.VideoSelectionModel.hasActive();
	}

	public get maxPublishDate(): Date | undefined {
		return this.validatePublishDateFuture() ? this.today : undefined;
	}

	public get publishDateOperations(): BulkEditOperator[] {
		return this.validatePublishDateFuture() ? [BulkEditOperator.replace] : this.removeReplaceOptions;
	}

	public get showImmediateActiveText(): boolean {
		return this.fields?.publishDate?.value <= this.today
			&& (this.fields?.isActive?.value === false || this.VideoSelectionModel.hasInactive());
	}

	public get showFutureActiveText(): boolean {
		return this.fields?.publishDate &&
			(!this.fields.publishDate.value || this.fields.publishDate.value > this.today);
	}


	public trackByIndex(index: number): number {
		return index;
	}
}
