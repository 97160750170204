import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CacheFactory } from 'rev-shared/util/CacheFactory';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushService } from 'rev-shared/push/PushService';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { SharedDeviceService } from 'rev-shared/device/SharedDevice.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { WebRtcListenerConnectionService } from 'rev-shared/webrtc/WebRtcListenerConnection.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { promiseFromTimeout } from 'rev-shared/util/PromiseUtil';

import { updateWaitingOrStartingStatus } from './WebcastStatusUpdateHelper';
import { WebcastModel } from './model/WebcastModel';
import { PollsModelService } from '../polls/PollsModel.Service';
import { WebcastPresentationService } from '../presentations/WebcastPresentation.Service';

@Injectable({
	providedIn: 'root'
})
export class WebcastService {
	private webcastCache = new CacheFactory<WebcastModel>(3);

	constructor(
		private DateParsers: DateParsersService,
		private ListenerConnection: WebRtcListenerConnectionService,
		private PollsModelSvc: PollsModelService,
		private PushBus: PushBus,
		private PushService: PushService,
		private SecurityContext: SecurityContextService,
		private SharedDevice: SharedDeviceService,
		private UserContext: UserContextService,
		private WebcastPresentationSvc: WebcastPresentationService,
		private http: HttpClient,
		private zone: NgZone
	) {}

	public getWebcast(webcastId: string, refetch?: boolean, noCache?: boolean): Promise<WebcastModel> {
		const cachedWebcast = !refetch ? this.webcastCache.get(webcastId) : null;

		if (cachedWebcast){
			return Promise.resolve(cachedWebcast);
		}

		return Promise.resolve(this.SecurityContext.$promise)
			.then(() => lastValueFrom(this.http.get<any>(`/scheduled-events/${webcastId}`)))
			.then( eventResult => {
				const scheduledEvent = eventResult.scheduledEvent;

				Object.assign(scheduledEvent, {
					id: webcastId,
					accountId: this.UserContext.getAccount().id,
					attendees: scheduledEvent.attendees || [],
					backgroundImages: eventResult.backgroundImages,
					endDate: this.DateParsers.parseUTCDate(scheduledEvent.endDate),
					moderatorIds: scheduledEvent.moderatorIds || [],
					startDate: this.DateParsers.parseUTCDate(scheduledEvent.startDate),
					thumbnailUri: eventResult.thumbnailUri,
					mediaCachingEnabled: eventResult.mediaCachingEnabled,
					videoHeartbeatInterval: eventResult.videoHeartbeatInterval,
					environmentId: eventResult.environmentId,
					recordingVideoStatus: eventResult.recordingVideoStatus,
					customFields: eventResult.customFields,
					eventHostName: eventResult.eventHostName,
					liveSubtitles: {
						isLiveSubtiltesEnabled: !!scheduledEvent.liveSubtitles,
						...scheduledEvent.liveSubtitles
					},
					webcastBrandingSettings: {
						logoUri: eventResult.logoUri,
						...scheduledEvent.webcastBrandingSettings
					},
					vodInfo: scheduledEvent.vodInfo && {
						...scheduledEvent.vodInfo,
						id: scheduledEvent.vodId,
						durationMs: this.DateParsers.parseTimespan(scheduledEvent.vodInfo.duration)
					}
				});

				if(scheduledEvent.presentationFile){
					scheduledEvent.presentationFile.lastUpdated = this.DateParsers.parseUTCDate(scheduledEvent.presentationFile.lastUpdated);
				}

				if(scheduledEvent.preProduction){
					scheduledEvent.preProduction.durationMs =
						this.DateParsers.parseTimespan(scheduledEvent.preProduction.duration);
				}

				const canEditEvents = this.SecurityContext.checkAuthorization('events.edit');

				const isAccountAdmin = this.SecurityContext.checkAuthorization('admin.accounts.edit')
					// No parent/ancestor account admin
					&& this.UserContext.getAccount().id == scheduledEvent.accountId;

				const webcast = new WebcastModel(scheduledEvent, {
					canEditEvents,
					canJoinPreProduction: eventResult.canJoinPreProduction,
					canJoinMainEvent: eventResult.canJoinMainEvent,
					canViewReports: eventResult.canViewReports,
					isAccountAdmin,
					allowRemoveAttendees: !!eventResult.allowRemoveAttendees
				}, {
					DateParsers: this.DateParsers,
					ListenerConnection: this.ListenerConnection,
					PollsModelService: this.PollsModelSvc,
					PushBus: this.PushBus,
					UserContext: this.UserContext,
					WebcastPresentationService: this.WebcastPresentationSvc,
					WebcastService: this,
					zone: this.zone
				});

				updateWaitingOrStartingStatus(webcast);

				if (!noCache) {
					this.webcastCache.put(webcastId, webcast);
				}

				return webcast;
			});
	}

	public getWebcastInfo(webcastId: string): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/scheduled-events/${webcastId}/access-control`))
			.then(webcast => {
				webcast.registrationFields?.forEach(f => f.value = '');
				webcast.startDate = this.DateParsers.parseUTCDate(webcast.startDate);
				webcast.endDate = this.DateParsers.parseUTCDate(webcast.endDate);
				return webcast;
			});
	}

	public registerGuestUser(webcastId: string, userInfo: any): Promise<any> {
		return lastValueFrom(this.http.post(`/auth/scheduled-events/${webcastId}/guests`, {
			name: userInfo.name,
			email: userInfo.email,
			password: userInfo.password,
			registrationFieldsAnswers: userInfo.registrationFieldsAnswers,
			skipPreRegistration: userInfo.skipPreRegistration
		}));
	}

	public registerAnonymousGuestUser(webcastId: string, userInfo: any): Promise<any> {
		return lastValueFrom(this.http.post(`/auth/scheduled-events/${webcastId}/anonymous-guests`, {
			password: userInfo.password
		}));
	}

	public cancelScheduledAutomatedWebcast(webcastId: string, runNumber: number): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:CancelScheduledAutomatedWebcast', { webcastId, runNumber });
	}

	public endWebcast(webcastId: string, runNumber: number, discardRecording: boolean): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:EndWebcast', { webcastId, runNumber, discardRecording });
	}

	public startBroadcasting(webcast: WebcastModel ): Promise<void> {
		return Promise.resolve(webcast.isWebrtcSinglePresenter && !webcast.currentUser.isSinglePresenter &&
			this.PushService.dispatchCommand('scheduledEvents:StartBroadcastCountdown', { webcastId: webcast.id })
				.then(() => promiseFromTimeout(5000))
		).then(() =>
			this.PushService.dispatchCommand('scheduledEvents:StartBroadcasting', {
				webcastId: webcast.id,
				autoRecording: webcast.recording.initiateAutoRecording
			}));
	}

	public stopBroadcasting(webcast: WebcastModel): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:StopBroadcasting', {
			webcastId: webcast.id
		});
	}

	public startRecording(webcast: WebcastModel): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:StartRecording', {
			webcastId: webcast.id
		});
	}

	public getRecordingDeviceDetails(deviceId: string): Promise<any> {
		return this.SharedDevice.getDmeDevice(deviceId);
	}

	public stopRecording(webcast: WebcastModel): Promise<any> {
		const isAlreadyProcessing = webcast.recording.isProcessingRequest;

		webcast.recording.isProcessingRequest = true;

		return new Promise((resolve, reject) => {
			const unsubscribe = this.PushBus.subscribe(webcast.id, {
				StopRecordingFailed: msgData => {
					unsubscribe();
					webcast.recording.isProcessingRequest = false;
					reject(msgData);
				},

				RecordingStopped: msgData => {
					unsubscribe();
					webcast.recording.isProcessingRequest = false;
					resolve(msgData);
				}
			});

			if (!isAlreadyProcessing) {
				this.PushService.dispatchCommand('scheduledEvents:StopRecording', {
					webcastId: webcast.id
				})
					.catch(err => {
						webcast.recording.isProcessingRequest = false;
						unsubscribe();

						return reject(err);
					});
			}
		});
	}

	public addWebcastComment(webcast: WebcastModel, comment: any): Promise<string> {
		return this.PushService.dispatchCommand('scheduledEvents:AddWebcastComment', {
			webcastId: webcast.id,
			runNumber: webcast.currentRun.runNumber,
			isEventAdminPM: comment.isEventAdminPM,
			comment: comment.comment
		}, 'CommandFinished') //Force signalr impl
			.$commandId;
	}

	public getWebcastComments(webcast: WebcastModel): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/scheduled-events/${webcast.id}/${webcast.currentRun.runNumber}/comments`))
			.then(result =>
				(result.comments || []).map(comment => {
					comment.date = this.DateParsers.parseUTCDate(comment.date);
					return comment;
				}));
	}

	public leave(webcast: WebcastModel, discardRecording: boolean = false): Promise<void> {
		if (webcast.currentUser.isEventAdmin && !webcast.isAutomated){
			return this.endWebcast(webcast.id, webcast.currentRun.runNumber, discardRecording);
		}
		return Promise.resolve();
	}

	public fetchPreRegisteredGuestInfo(webcastId: string): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/scheduled-events/${webcastId}/prereg-guest`));
	}
}
