import { NgModule } from '@angular/core';
import { VbMediaTableAngularComponent } from './VbMediaTableAngular.Component';
import { VbMediaTableColumnAngularComponent } from './VbMediaTableColumnAngular.Component';
import { VbMediaTableColumnHeaderAngularComponent } from './VbMediaTableColumnHeaderAngular.Component';
import { VbMediaTableSelectColumnHeaderAngularComponent } from './VbMediaTableSelectColumnHeaderAngular.Component';
import { CommonModule } from '@angular/common';

const exportComponents = [
	VbMediaTableAngularComponent,
	VbMediaTableColumnAngularComponent,
	VbMediaTableColumnHeaderAngularComponent,
	VbMediaTableSelectColumnHeaderAngularComponent
];

@NgModule({
	imports: [
		CommonModule
	],
	declarations: exportComponents,
	exports: exportComponents,
})
export class VbMediaTableAngularModule { }
