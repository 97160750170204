import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmbedSize } from 'rev-shared/media/shareEmbed/EmbedSize';

import { Menus } from 'rev-shared/media/shareEmbed/VbUiShareEmbed.Component';

import './playlist-sharing.less';

enum PlaylistSharingViews {
	LINK = 'Link',
	EMBED = 'Embed'
}

@Component({
	selector: 'playlist-sharing',
	templateUrl: './PlaylistSharing.Component.html'
})
export class PlaylistSharing implements OnInit {
	@Input() public embedsEnabled: boolean;
	@Input() public playlistId: string;
	@Input() public playlistName: string;
	@Input() public playlistEmbedUrl: string;
	@Input() public playlistPlaybackUrl: string;

	public readonly PlaylistSharingViews = PlaylistSharingViews;

	public embedPresetSizes: EmbedSize[];
	public currentShareView: string = PlaylistSharingViews.LINK;
	public playlistSharingEmailBody: string;
	public playlistSharingEmailSubject: string;

	constructor(
		private Translate: TranslateService
	) {}

	public readonly embedOptionsDisplayContext = {
		startAt: false,
		[Menus.Controls]: false,
		[Menus.Styling]: false,
		[Menus.Layout]: {
			popoutPlayer: false,
			size: true
		},
		[Menus.Playback]: {
			autoPlay: true
		}
	};


	public ngOnInit(): void {
		this.embedPresetSizes = this.getEmbedPresetSizes();
		this.playlistSharingEmailBody = this.getEmailBody();
	}

	protected getEmailBody(): string {
		// mailTo does not work with html body well. So, getting the plain text rather than html...
		return `${this.Translate.instant('Media_Videos_Playlists_Sharing_Email_Intro')}\n\n${this.playlistName}\n${this.playlistPlaybackUrl}`;
	}

	protected getEmbedPresetSizes(): EmbedSize[] {
		return [
			new EmbedSize(560, 315, 'small', this.Translate.instant('Media_Videos_Embed_Small')),
			new EmbedSize(640, 360, 'medium', this.Translate.instant('Media_Videos_Embed_Medium')),
			new EmbedSize(853, 480, 'large', this.Translate.instant('Media_Videos_Embed_Large'))
		];
	}
}
