<div [ngClass]="styles.tileItem">
	 <a uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }">
		<div class="fixed-ratio-wrapper grid-item-list">
			<div class="fixed-ratio">
				<div class="col-sm-6" *ngFor="let link of links">
					<div class="image-wrapper">
						<div class="fixed-ratio-wrapper">

							<div class="fixed-ratio">
								<img *ngIf="link.uri; else noUri;" [src]="link.uri" [alt]="'ThumbnailForAria' | translate : { '0': link.title }"/>

								<ng-template #noUri>
									<div class="no-item"></div>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="fixed-ratio-wrapper">
			<div class="fixed-ratio">
				<div class="fixed-aspect-item" [ngClass]="styles.infoContainer">
					<h2>
						<span>
							{{category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name}}
						</span>
					</h2>
				</div>
			</div>
		</div>
	</a>
	<div [ngClass]="styles.categoryTileFooter" layout="row" layout-wrap="false">
		<a flex="fill" [ngClass]="styles.inactiveAnchor" uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }" [ngPlural]="category.videoCount || 0">
			<ng-template ngPluralCase="=0"></ng-template>
			<ng-template ngPluralCase="=1">
				{{'Media_OneVideo' | translate}}
			</ng-template>
			<ng-template ngPluralCase="other">
				{{'Media_NumVideos' | translate: { '0': (category.videoCount | number) } }}
			</ng-template>
		</a>
		<span>
			<a class="margin-right-5" uiSref="portal.edit-category" [uiParams]="{ categoryId: category.id }" *ngIf="category.canEdit" [attr.aria-label]="'EditCategory' | translate">
				<span class="glyphicons cogwheel"></span>
			</a>

			<a class="glyphicons lock margin-right-5" [ngClass]="styles.inactiveAnchor" uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }" *ngIf="category.restricted">
			</a>
			<a uiSref="portal.media.category-detail" [ngClass]="styles.inactiveAnchor" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }" class="glyphicons folder_closed">
			</a>
		</span>
	</div>
</div>
