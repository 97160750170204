import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { isEqual } from 'rev-shared/util';

import { SearchQueryBuilder } from 'rev-portal/search/SearchQueryBuilder';

import { Filter } from './SearchFilterTypes';
import { SearchFilterDefinitionsService } from './SearchFilterDefinitions.Service';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

export interface ISearchFilters {
	[key: string]: Filter;
}

@Injectable({
	providedIn: 'root'
})
export class SearchFilterStateService {
	private readonly changeSubject$: Subject<ISearchFilters> = new Subject<ISearchFilters>();
	public change$: Observable<ISearchFilters> = this.changeSubject$.asObservable();
	public paramsSubscription: Subscription;
	public filters: ISearchFilters;

	constructor(
		private SearchFilterDefinitions: SearchFilterDefinitionsService,
		private $uiRouterGlobals: UIRouterGlobals
	) {}

	public startService(): void {
		this.paramsSubscription = this.$uiRouterGlobals.params$.subscribe((params: any) => {
			this.setFilters(params.filters);
		});
	}

	public stopService(): void {
		this.initialize();
		this.paramsSubscription?.unsubscribe();
	}

	public setFilters(jsonString: string): void {
		if (jsonString) {
			this.update(JSON.parse(jsonString));
		}
	}

	public sanitizeFilterObject(filters: ISearchFilters): ISearchFilters {
		const saniFilters: ISearchFilters = {};
		Object.keys(filters).forEach(key => {
			if (filters[key].hasValue) {
				saniFilters[key] = this.getFilterValue(filters[key]);
			}
		});
		return saniFilters;
	}

	public buildQuery(queryBuilder?: SearchQueryBuilder): string {
		queryBuilder = queryBuilder || new SearchQueryBuilder();

		this.forEachFilter(filter => filter.addToQuery(queryBuilder));

		return queryBuilder.buildQuery();
	}

	public clear(notify: boolean = true): void {
		this.forEachFilter(filter => filter.clear());

		if (notify) {
			this.changeSubject$.next(this.filters);
		}
	}

	public clearAllOverrides(): void {
		this.forEachFilter(filter => filter.clearValueOverride());
	}

	public clone(): ISearchFilters {
		const clone = {};

		this.forEachFilter((field, key) => clone[key] = field.clone());

		return clone;
	}

	public initialize(): void {
		this.filters = this.SearchFilterDefinitions.getStaticFilters();
	}

	public update(filterValues?: any): void {
		this.forEachFilter((filter, key) => {
			const filterValue = filterValues[key];

			if (filterValue !== undefined || filter.hasValue) {
				filter.update(filterValues[key]);
			}
		});

		this.changeSubject$.next(this.filters);
	}

	private forEachFilter(fn: (field: Filter, key: string) => any): void {
		if (this.filters) {
			Object.keys(this.filters)
				.forEach(key => fn(this.filters[key], key));
		}
	}

	public getFilterValue(filter: Filter): any {
		return filter.isSingleValue ? filter.getQueryValue() : filter.value.map(v => {
			const f = { name: v.name };
			f[filter.queryProperty] = v[filter.queryProperty];
			return f;
		});
	}

	public go(filters: any, $state: StateService): void {
		filters = Object.keys(filters).length > 0 ? filters: '';
		$state.go('.', { filters: JSON.stringify(filters) }, { reload: false });
	}
}
