import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { UtilAngularModule } from 'rev-shared/util/UtilAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbUiShareEmbedModule } from 'rev-shared/media/shareEmbed/VbUiShareEmbed.Module';
import { VbUiShareLinkModule } from 'rev-shared/media/shareLink/VbUiShareLink.Module';
import { VideoTileAngularModule } from 'rev-shared/ui/videoTile/VideoTile.AngularModule';

import { BrowsePlaylistsHostComponent } from './BrowsePlaylistsHost.Component';
import { BrowseUserPlaylistsComponent } from './BrowseUserPlaylists.Component';
import { BrowseUserPlaylistsTableComponent } from './BrowseUserPlaylistsTable.Component';
import { BrowseUserPlaylistsTilesComponent } from './BrowseUserPlaylistsTiles.Component';
import { PlaylistDetailComponent } from './PlaylistDetail.Component';
import { PlaylistDetailTilesComponent } from './PlaylistDetailTiles.Component';
import { PlaylistDetailsTableComponent } from './PlaylistDetailsTable.Component';
import { PlaylistSharing } from './PlaylistSharing.Component';
import { states } from './StateDeclarations';

const entryComponents = [
	BrowsePlaylistsHostComponent,
	BrowseUserPlaylistsComponent,
	BrowseUserPlaylistsTableComponent,
	BrowseUserPlaylistsTilesComponent,
	PlaylistSharing,
	PlaylistDetailTilesComponent,
	PlaylistDetailsTableComponent,
	PlaylistDetailComponent
];

@NgModule({
	imports: [
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		DateAngularModule,
		DialogAngularModule,
		DirectivesAngularModule,
		DragDropModule,
		FormsModule,
		NgxTrimDirectiveModule,
		SpinnerAngularModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule.forChild({ states }),
		UtilAngularModule,
		ValidationAngularModule,
		ValidationModule,
		VbUiShareEmbedModule,
		VbUiShareLinkModule,
		VideoTileAngularModule
	],
	declarations: entryComponents,
	exports: entryComponents,
	entryComponents,
})
export class PlaylistsAngularModule {}

