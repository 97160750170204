import { Component, Input } from '@angular/core';

import { VideoSearchResultsComponent } from './VideoSearchResults.Component';

@Component({
	selector: 'video-search-results-tiles',
	templateUrl: './VideoSearchResultsTiles.Component.html'

})
export class VideoSearchResultsTilesComponent {
	constructor(
		public videoSearchResults: VideoSearchResultsComponent
	) {}
}
