import { Directive, Input, ElementRef, HostListener, forwardRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { parseTimespan } from 'rev-shared/date/DateParsers.Service';
import { formatTimespanShort } from 'rev-shared/date/DateFormatters';

@Directive({
	selector: '[vbTimespanInput]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbTimespanInputDirective),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbTimespanInputDirective),
			multi: true,
		}
	],
})
export class VbTimespanInputDirective implements ControlValueAccessor, Validator {
	@Input() public includeHours: boolean;
	@Input() public includeSeconds: boolean | undefined;

	private nativeElement: HTMLInputElement;
	private onChange: (value: number) => void;

	public onTouched: () => void;

	constructor(
		Element: ElementRef,
		private renderer: Renderer2
	) {
		this.nativeElement = Element.nativeElement;
	}

	@HostListener('input', ['$event.target.value'])
	public input(value: string): void {
		this.onTouched();
		this.onChange(this.parse(value));
	}

	@HostListener('blur')
	public onBlur(): void {
		this.onTouched();
	}

	public writeValue(value: number): void {
		this.renderer.setProperty(this.nativeElement, 'value', formatTimespanShort(value, this.includeHours, this.includeSeconds));
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public parse(value: string): number {
		return !value ? null : parseTimespan(value, this.includeSeconds);
	}

	public validate({ value }: FormControl): ValidationErrors {
		return value !== undefined ? null : { timespan: true };
	}
}
