import { Component } from '@angular/core';

import { MediaMenuService } from './MediaMenu.Service';

@Component({
	selector: 'my-videos-menu-items',
	templateUrl: './MyVideosMenuItems.Component.html'
})
export class MyVideosMenuItemsComponent {

	constructor(
		public MediaMenuService: MediaMenuService
	) { }

}
