import { NgModule } from '@angular/core';
import { UIRouterModule, Transition, StateService } from '@uirouter/angular';
import { once } from 'underscore';

import { buildLoaderStateDeclaration } from 'rev-shared/uiRouterUtils/LazyLoaderStateUtil';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { WEBCAST_SHORTCUT_STATE, WEBCAST_ROOT_STATE, WEBCAST_FULLSCREEN_STATE, WEBCAST_ROOT_STATE_URL } from './webcast/Constants';
import { EVENT_REGISTRATION_STATE } from './eventRegistration/Constant';

const loadChildren = once(() =>
	import(/* webpackChunkName: 'scheduled-events' */ './ScheduledEventsAngular.Module')
		.then(mod => mod.ScheduledEventsAngularModule)
);

@NgModule({
	imports: [
		SpinnerAngularModule,
		UIRouterModule.forChild({
			states: [{
				name: `${WEBCAST_ROOT_STATE}.**`,
				url: WEBCAST_ROOT_STATE_URL,
				params: {
					webcastId: null
				},
				loadChildren
			}, {
				name: `${WEBCAST_FULLSCREEN_STATE}.**`,
				url: '/webcast/:webcastId',
				loadChildren
			}, {
				name: `${EVENT_REGISTRATION_STATE}.**`,
				url: '/event-registration',
				loadChildren
			},
			buildLoaderStateDeclaration('portal.scheduledEvents', {
				name: 'portal.loadEvents',
				url: '/load-events'
			}), {
				name: WEBCAST_SHORTCUT_STATE,
				url: '/webcasts/:shortcutName',
				redirectTo(transition: Transition) {
					const shortcutName = transition.params().shortcutName as string;
					const $state = transition.injector().get<StateService>(StateService);
					return $state.target(
						WEBCAST_ROOT_STATE,
						{ shortcutName },
						{ location: false });
				}
			}]

		})
	]
})

export class ScheduledEventsLoaderModule {
}
