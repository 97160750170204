<textarea [ngClass]="styles.txtArea" readonly="readonly" rows="4" [value]="embedCode" [attr.aria-label]="'Media_Videos_Sharing_Embed' | translate" vbUiTextArea #clipboardTarget>
</textarea>

<div [ngClass]="styles.actionWrapper" layout-wrap>

	<button type="button" [ngClass]="styles.toolbarBtn" [ngxClipboard]="clipboardTarget" *ngxClipboardIfSupported>
		{{ 'Media_Videos_Sharing_Copy' | translate }}
	</button>

	<section [ngClass]="styles.embedOptionWrapper" display="flex" layout="column" ngForm>

		<div flex="fill" class="margin-bottom-10">{{ 'Media_Videos_Embed_EmbedOptions' | translate }}</div>

		<div *ngIf="optionsDisplayContext.startAt" layout="row" flex-align="center" class="margin-bottom-10">
			<vb-toggle-button name="showStartAt" [(ngModel)]="showStartAt" (change)="onToggleStartAt()" [ariaLabel]="'Media_Videos_Sharing_StartAt' | translate">
			</vb-toggle-button>

			<label for="txtStartAt">{{ 'Media_Videos_Sharing_StartAt' | translate }}</label>
			<div *ngIf="showStartAt" [ngClass]="styles.startAt">
				<input type="text" name="startAt" id="txtStartAt" #startAtInput="ngModel" [(ngModel)]="startAt" (change)="updateEmbedCode()" vbUiTextInput vbTimespanInput [includeHours]="true" [vbMin]="0"/>
				<div *ngIf="startAtInput.invalid" role="alert" vbUiErrorMessage>
					<div *ngIf="startAtInput.errors.timespan">
						{{ 'Media_Videos_Sharing_InvalidStartAt' | translate : { '0': 'hh:mm:ss' } }}
					</div>
				</div>
			</div>
		</div>

		<div layout="row" *ngIf="optionsDisplayContext[menus.Layout]">
			<h4 [ngClass]="styles.embedOptionCategory" (click)="activateMenu(menus.Layout)">
				{{ 'Layout' | translate }}
				<span class="glyphicon glyphicon-chevron-right" [ngClass]="{'glyphicon-chevron-down': activeMenu == menus.Layout}"></span>
			</h4>
		</div>

		<div *ngIf="activeMenu == menus.Layout">
			<div layout="row" *ngIf="optionsDisplayContext[menus.Layout].popoutPlayer">
				<vb-toggle-button name="showVideoOnly" [toggleId]="'ShowVideoOnly'" [(ngModel)]="isPopupPlayer" (change)="updateEmbedCode()">
				</vb-toggle-button>

				<label [ngClass]="styles.label" flex="fill">
					{{ 'Media_Videos_Sharing_EmbedType' | translate }}
					<vb-ui-tooltip [iconName]="'circle_question_mark'" [placement]="'top'" [text]="'Media_Videos_Sharing_EmbedType_Tooltip' | translate">
					</vb-ui-tooltip>
				</label>
			</div>

			<div layout="row" class="margin-top-10" *ngIf="optionsDisplayContext[menus.Layout].size">
				<div [ngClass]="styles.playerSize">
					<label [ngClass]="styles.label">{{ 'Media_Videos_Embed_Size' | translate }}</label>
				</div>
				<div [ngClass]="styles.playerDimensions">
					<label [ngClass]="styles.label">{{ 'Media_Videos_Embed_Width' | translate }}</label>
					<label [ngClass]="styles.label">{{ 'Media_Videos_Embed_Height' | translate }}</label>
				</div>
			</div>

			<div layout="row" *ngIf="optionsDisplayContext[menus.Layout].size">
				<vb-ui-select [ngClass]="styles.playerSize">
					<select #selectElement name="sizeSelect" [(ngModel)]="sizeName" (ngModelChange)="onSizeMenuChange()">
						<option *ngFor="let size of sizes" [ngValue]="size.name">{{ size.label }}</option>
						<option [ngValue]="'responsive'">{{ 'Media_Videos_Embed_Responsive' | translate }}</option>
						<option [ngValue]="'custom'">{{ 'Media_Videos_Embed_Custom' | translate }}</option>
					</select>
				</vb-ui-select>
				<div *ngIf="sizeName !== 'responsive'" [ngClass]="styles.playerDimensions">
					<input type="number" [ngClass]="styles.input" class="margin-right-5" name="width" min="1" step="1" pattern="\d+" required [placeholder]="'Media_Videos_Embed_Width' | translate" #widthInput="ngModel" [(ngModel)]="width" (ngModelChange)="onEmbedWidthChange()" vbUiTextInput>
					<input type="number" disabled="disabled" [ngClass]="styles.input" name="height" [(ngModel)]="height" [placeholder]="'Media_Videos_Embed_Height' | translate" vbUiTextInput>

					<div *ngIf="widthInput.invalid" role="alert" vbUiErrorMessage>
						{{ 'MustContainNumberGreaterThan' | translate : { '0': 0 } }}
					</div>
				</div>
			</div>
		</div>

		<div layout="row" *ngIf="optionsDisplayContext[menus.Styling]">
			<h4 [ngClass]="styles.embedOptionCategory" (click)="activateMenu(menus.Styling)">
				{{ 'Media_Videos_Embed_Styling' | translate }}
				<span class="glyphicon glyphicon-chevron-right" [ngClass]="{'glyphicon-chevron-down': activeMenu == menus.Styling}"></span>
			</h4>
		</div>

		<div *ngIf="activeMenu == menus.Styling">

			<div layout="row" class="margin-bottom-10" *ngIf="optionsDisplayContext[menus.Styling].playerAccentColor">
				<vb-toggle-button name="playerAccentColor" [toggleId]="'PlayerAccentColor'" [(ngModel)]="useAccentColor" (change)="onEmbedWidthChange()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill"> {{ 'Media_Videos_Embed_PlayerAccentColor' | translate }} </label>
				<vb-ui-color-selector *ngIf="useAccentColor" name="accentColorPicker" [(ngModel)]="accentColor" [defaultColor]="ThemeService.accentColor" (ngModelChange)="colorChange()">
				</vb-ui-color-selector>
			</div>

			<!-- this will be brought back in a later sprint
			<div layout="row" class="margin-bottom-10">
				<vb-toggle-button
					name="playerLogo"
					[toggleId]="'PlayerLogo'"
					[(ngModel)]="useLogo"
					(change)="onEmbedWidthChange()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">
					{{ 'Media_Videos_Embed_Logo' | translate }}
				</label>
				<image-selector
					style="width:100%;"
					*ngIf="useLogo"
					image-component-metadata="imageComponentMetadata"
					image-data="imageData"
					default-image="{ url: ThemeService.brandingSettings.themeSettings.logoUri }"
					on-image-selection="onEmbedWidthChange()">
				</image-selector>
			</div>

			<div layout="row" class="margin-bottom-10">
				<vb-toggle-button
					name="playerLogoLink"
					[toggleId]="'PlayerLogoLink'"
					[(ngModel)]="logoHasLink"
					(change)="onEmbedWidthChange()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">
					{{ 'Media_Videos_Embed_LogoLink' | translate }}
				</label>
				<input  *ngIf="logoHasLink"
					style="width:100%;"
					[(ngModel)]="logoLink"
					(change)="onEmbedWidthChange()"
					vbUiTextInput />
			</div> -->

		</div>

		<div layout="row" *ngIf="optionsDisplayContext[menus.Controls]">
			<h4 [ngClass]="styles.embedOptionCategory" (click)="activateMenu(menus.Controls)">
				{{ 'Media_Videos_Embed_Controls' | translate }}
				<span class="glyphicon glyphicon-chevron-right" [ngClass]="{'glyphicon-chevron-down': activeMenu == menus.Controls}"></span>
			</h4>
		</div>

		<div *ngIf="activeMenu == menus.Controls">

			<div layout="row" *ngIf="optionsDisplayContext[menus.Controls].allControls">
				<vb-toggle-button name="allControlsToggle" [toggleId]="'AllControlsToggle'" [(ngModel)]="allControlsToggle" (change)="toggleAll()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_AllControls' | translate }}</label>
			</div>

			<div layout="row" *ngIf="optionsDisplayContext[menus.Controls].centerButtons">
				<vb-toggle-button name="centerButtonsToggle" [toggleId]="'centerButtons'" [(ngModel)]="controls.centerButtons" (change)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_CenterButtons' | translate }}</label>
			</div>

			<div layout="row" *ngIf="optionsDisplayContext[menus.Controls].playbar">
				<vb-toggle-button name="playBarToggle" [toggleId]="'playBar'" [(ngModel)]="controls.playBar" (change)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_PlayBar' | translate }}</label>
			</div>

			<div layout="row" *ngIf="ccEnabled && optionsDisplayContext[menus.Controls].cc">
				<vb-toggle-button name="closedCaptionsToggle" [toggleId]="'ClosedCaptions'" [(ngModel)]="controls.closedCaptions" (change)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_ClosedCaptions' | translate }}</label>
			</div>

			<div layout="row" *ngIf="optionsDisplayContext[menus.Controls].settings">
				<vb-toggle-button name="settingsToggle" [toggleId]="'Settings'" [(ngModel)]="controls.settings" (change)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Settings' | translate }}</label>
			</div>

			<div layout="row" *ngIf="optionsDisplayContext[menus.Controls].fullscreen">
				<vb-toggle-button name="fullscreenToggle" [toggleId]="'Fullscreen'" [(ngModel)]="controls.fullscreen" (change)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_Fullscreen' | translate }}</label>
			</div>

			<div layout="row" *ngIf="false && chaptersEnabled && optionsDisplayContext[menus.Controls].chapters">
				<vb-toggle-button name="layoutsToggle" [toggleId]="'Layouts'" [(ngModel)]="controls.layouts" (change)="checkToggle()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Layouts' | translate }}</label>
			</div>
		</div>

		<div layout="row" *ngIf="optionsDisplayContext[menus.Playback]">
			<h4 [ngClass]="styles.embedOptionCategory" (click)="activateMenu(menus.Playback)">
				{{ 'Media_Videos_Embed_Playback' | translate }}
				<span class="glyphicon glyphicon-chevron-right" [ngClass]="{'glyphicon-chevron-down': activeMenu == menus.Playback}"></span>
			</h4>
		</div>

		<div *ngIf="activeMenu == menus.Playback">

			<div layout="row" *ngIf="optionsDisplayContext[menus.Playback].loop && !video?.isLive">
				<vb-toggle-button name="loopVideo" [toggleId]="'LoopVideo'" [(ngModel)]="playback.loopVideo" (change)="onEmbedWidthChange()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_Loop' | translate }}</label>
			</div>

			<div layout="row" *ngIf="optionsDisplayContext[menus.Playback].autoPlay">
				<vb-toggle-button name="autoplay" [toggleId]="'Autoplay'" [(ngModel)]="playback.autoplay" (change)="updateEmbedCode()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Autoplay' | translate }}</label>
			</div>

			<div layout="row" *ngIf="chaptersEnabled && optionsDisplayContext[menus.Playback].chapters">
				<vb-toggle-button name="hideChapters" [toggleId]="'HideChapters'" [(ngModel)]="playback.hideChapters" (change)="onEmbedWidthChange()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_HideChapters' | translate }}</label>
			</div>

			<div layout="row" *ngIf="ccEnabled && optionsDisplayContext[menus.Playback].cc">
				<vb-toggle-button name="forceClosedCaptions" [toggleId]="'ForceClosedCaptions'" [(ngModel)]="playback.forceClosedCaptions" (change)="onEmbedWidthChange()">
				</vb-toggle-button>
				<label [ngClass]="styles.label" flex="fill">{{ 'Media_Videos_Embed_ForceClosedCaptions' | translate }}</label>
			</div>

		</div>

	</section>
</div>
