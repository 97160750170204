
<ng-template #accessEntityOptionRow let-item="item" let-insight="insight">
	<access-entity-option-row [item]="item" [insight]="insight"></access-entity-option-row>
</ng-template>

<ng-template #accessEntityAssignedRow let-item="item" let-insight="insight">
	<access-entity-assigned-row [item]="item" [insight]="insight"></access-entity-assigned-row>
</ng-template>

<div class="container-fluid bulk-edit">
	<header class="theme-primary right-sidebar-content-header">
		<h4>{{ 'VideoSettings' | translate }}:</h4>
	</header>

	<form class="sidebar-form" #form="ngForm" (submit)="submit()">
		<fieldset>
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="isActive" #uiLabel>
					{{ 'Status' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select name="isActive" [(ngModel)]="fields.isActive.value" #selectElement (ngModelChange)="onStatusChange()">
						<option [ngValue]="null"></option>
						<option [ngValue]="true">{{'Active' | translate}}</option>
						<option [ngValue]="false">{{'Inactive' | translate}}</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>
		</fieldset>

		<fieldset *ngIf="fields.isActive.value">
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="expirationOp" #uiLabel>
					{{ 'Expiration' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select name="expirationOp" [(ngModel)]="fields.expiration.operation" #selectElement>
						<option value=""></option>
						<option *ngFor="let operation of removeReplaceOptions" [value]="operation">
							{{ getOperatorLabel(operation) }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-video-expiration-options *ngIf="fields.expiration.operation === BulkEditOperator.replace" [expirationRuleEnabled]="mediaFeatures.enableExpirationRules" [expirationRules]="expirationRules" [expirationDetails]="expirationConfig" (onExpirationChange)="onExpirationChange($event)" [isLarge]="true">
			</vb-video-expiration-options>
		</fieldset>

		<fieldset>
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="publishOp" #uiLabel>
					{{ 'Publish' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select name="publishOp" [(ngModel)]="fields.publishDate.operation" #selectElement>
						<option value=""></option>
						<option *ngFor="let operation of publishDateOperations" [value]="operation">
							{{ getOperatorLabel(operation) }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group *ngIf="fields.publishDate.operation === BulkEditOperator.replace" [ngClass]="{'has-error': publishDate?.dirty && publishDate?.invalid}">
				<div uiControl>
					<vb-ui-date-picker required name="publishDate" #publishDate="ngModel" [(ngModel)]="fields.publishDate.value" [vbMax]="maxPublishDate" [required]="fields.publishDate.operation === BulkEditOperator.replace">
					</vb-ui-date-picker>
					<div *ngIf="!publishDate?.invalid">
						<span class="help-block" [hidden]="!(showFutureActiveText)">{{ 'PublishHelpText' | translate : { '0': midnight } }}</span>
						<span class="help-block" [hidden]="!(showImmediateActiveText)">{{ 'PublishHelpTextForImmediateActive' | translate }}</span>
					</div>
				</div>
				<div class="required-field"></div>
				<div *ngIf="publishDate?.invalid" role="alert" uiValidation vbUiErrorMessage>
					<div *ngIf="publishDate.errors.vbMax">
						{{ 'PublishDateWarning' | translate }}
					</div>
					<div *ngIf="publishDate.errors.date">
						{{ 'ValidDateRequired' | translate }}
					</div>
					<div *ngIf="publishDate.errors.required">
						{{ 'ThisFieldIsRequired' | translate }}
					</div>
				</div>
			</vb-ui-form-group>
		</fieldset>

		<fieldset>
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="owner" #uiLabel>
					{{ 'Media_Videos_Settings_Owner' | translate }}:
				</label>
				<vb-ui-single-user-select uiControl name="owner" [(ngModel)]="fields.owner.value" [searchQuery]="searchQuery" [ariaLabel]="'Media_Videos_Settings_Owner' | translate">
				</vb-ui-single-user-select>
			</vb-ui-form-group>
		</fieldset>

		<fieldset>
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="accessControl" #uiLabel>
					{{ 'Media_Videos_Settings_AccessControl' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select name="accessControl" [(ngModel)]="fields.accessControl.value" (change)="onAccessControlChange()" #selectElement>
						<option [ngValue]="null"></option>
						<option *ngFor="let option of accessOptions" [value]="option">
							{{ option === AccessControl.Private ? ( 'Media_Videos_Settings_Users_Groups_Channels' | translate ) : getOperatorLabel(option) }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group class="acl-op">
				<label class="bulk-edit-label" for="accessEntitiesOp" #uiLabel>
					{{ 'Media_Videos_Settings_AccessControlList' | translate }}:
				</label>

				<vb-ui-select uiControl>
					<select name="accessEntitiesOp" #selectElement [(ngModel)]="fields.accessControlEntities.operation" (change)="onAccessControlEntitiesOperationChange()">
						<option [ngValue]="null"></option>
						<option *ngFor="let operation of removeReplaceAppendOptions" [value]="operation">
							{{ getOperatorLabel(operation) }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group class="acl" *ngIf="showAccessControlEntities" [ngClass]="{ 'has-error': accessEntities?.dirty && accessEntities?.invalid }">

				<insight uiControl name="accessEntities" #accessEntities="ngModel" [(ngModel)]="fields.accessControlEntities.value" (ngModelChange)="onAccessControlEntitiesChange()" required [insightOptions]="accessControlInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow">
				</insight>

				<div class="required-field"></div>
				<div *ngIf="accessEntities?.invalid" role="alert" vbUiErrorMessage uiValidation>
					<div *ngIf="accessEntities.errors.required">
						{{ 'ThisFieldIsRequired' | translate }}
					</div>
				</div>
			</vb-ui-form-group>
		</fieldset>

		<fieldset [hidden]="!(mediaFeatures.enableCategories)">
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="categoriesOp" #uiLabel>
					{{ 'Media_VideoCategories' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select type="select" name="categoriesOp" [(ngModel)]="fields.categories.operation" #selectElement>
						<option value=""></option>
						<option *ngFor="let operation of removeReplaceAppendOptions" [value]="operation">
							{{ getOperatorLabel(operation) }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group *ngIf="fields.categories.operation" [ngClass]="{'has-error': categoryPicker?.dirty && categoryPicker?.invalid}">
				<vb-categories-tags-input uiControl required name="categoryPicker" #categoryPicker="ngModel" [(ngModel)]="fields.categories.value" [categories]="categories">
				</vb-categories-tags-input>

				<div class="required-field"></div>
				<div *ngIf="categoryPicker?.invalid" role="alert" uiValidation vbUiErrorMessage>
					<div *ngIf="categoryPicker.errors.required">
						{{ 'ThisFieldIsRequired' | translate }}
					</div>
				</div>
			</vb-ui-form-group>
		</fieldset>


		<fieldset [hidden]="!(mediaFeatures.enableTags)">
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="tagsOp" #uiLabel>
					{{ 'Tags' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select type="select" name="tagsOp" [(ngModel)]="fields.tags.operation" #selectElement>
						<option value=""></option>
						<option *ngFor="let operation of removeReplaceAppendOptions" [value]="operation">
							{{ getOperatorLabel(operation) }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group *ngIf="fields.tags.operation" [ngClass]="{'has-error': tags?.dirty && tags?.invalid}">

				<vb-tags-input uiControl [placeholder]="'TagsInputPlaceholder' | translate " [(ngModel)]="fields.tags.value" name="tags" #tags="ngModel" required [ngClass]="{ 'tags-input-has-items': fields.tags.value.length }">
				</vb-tags-input>

				<div class="required-field"></div>
				<div *ngIf="tags?.invalid" role="alert" vbUiErrorMessage uiValidation>
					<div *ngIf="tags.errors.required">
						{{ 'ThisFieldIsRequired' | translate }}
					</div>
				</div>
			</vb-ui-form-group>
		</fieldset>

		<fieldset>
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="userTagsOp" #uiLabel>
					{{ 'InThisVideo' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select type="select" name="userTagsOp" [(ngModel)]="fields.userTags.operation" #selectElement>
						<option [ngValue]="null"></option>
						<option *ngFor="let operation of removeReplaceAppendOptions" [value]="operation">
							{{ getOperatorLabel(operation) }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>
			<vb-ui-form-group class="acl" [ngClass]="{ 'has-error': userTags?.dirty && userTags?.invalid }" *ngIf="fields.userTags.operation">

				<insight uiControl name="userTags" #userTags="ngModel" [(ngModel)]="fields.userTags.value" required [insightOptions]="userTagsInsightOptions" [optionTemplate]="accessEntityOptionRow" [assignedTemplate]="accessEntityAssignedRow">
				</insight>

				<div class="required-field"></div>
				<div *ngIf="userTags?.invalid" role="alert" uiValidation vbUiErrorMessage>
					<div *ngIf="userTags.errors.required">
						{{ 'ThisFieldIsRequired' | translate }}
					</div>
				</div>
			</vb-ui-form-group>
		</fieldset>

		<fieldset [hidden]="!(mediaFeatures.enableDownloads || mediaFeatures.enableComments || mediaFeatures.enableRatings)">
			<vb-ui-form-group [hidden]="!(mediaFeatures.enableDownloads)">
				<label class="bulk-edit-label" for="downloadingEnabled" #uiLabel>
					{{ 'Event_Downloads' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select type="select" name="downloadingEnabled" [(ngModel)]="fields.downloadingEnabled.value" #selectElement>
						<option [ngValue]="null"></option>
						<option *ngFor="let option of uiEnabledDisabled" [value]="option.value">
							{{ option.label | translate }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group [hidden]="!(mediaFeatures.enableComments)">
				<label class="bulk-edit-label" for="commentsEnabled" #uiLabel>
					{{ 'Media_VideoComments' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select type="select" name="commentsEnabled" [(ngModel)]="fields.commentsEnabled.value" #selectElement>
						<option [ngValue]="null"></option>
						<option *ngFor="let option of uiEnabledDisabled" [value]="option.value">
							{{ option.label | translate }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group [hidden]="!(mediaFeatures.enableRatings)">
				<label class="bulk-edit-label" for="ratingsEnabled" #uiLabel>
					{{ 'Ratings' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select type="select" name="ratingsEnabled" [(ngModel)]="fields.ratingsEnabled.value" #selectElement>
						<option [ngValue]="null"></option>
						<option *ngFor="let option of uiEnabledDisabled" [value]="option.value">
							{{ option.label | translate }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>
		</fieldset>
		<fieldset [hidden]="!(mediaFeatures.enableUnlisted)">
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="unlisted" #uiLabel>
					{{ 'Unlisted' | translate }}:
				</label>
				<vb-ui-select uiControl>
					<select name="unlisted" [(ngModel)]="fields.unlisted.value" #selectElement>
						<option [ngValue]="null"></option>
						<option *ngFor="let option of uiEnabledDisabled" [value]="option.value">
							{{ option.label | translate }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>
		</fieldset>

		<fieldset *ngFor="let field of fields.customFieldValues; trackBy: trackByIndex; let i = index;">
			<vb-ui-form-group>
				<label class="bulk-edit-label" for="customFieldCtrl" #uiLabel>
					{{field.name}}:
				</label>
				<vb-ui-select uiControl>
					<select [name]="'customFieldCtrl' + i" [(ngModel)]="field.operation" #selectElement>
						<option value=""></option>
						<option *ngFor="let operation of filterCustomFieldOperations(field.required)" [value]="operation">
							{{ getOperatorLabel(operation) }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group *ngIf="field.operation === BulkEditOperator.replace" [ngClass]="{ 'has-error': input?.dirty && input?.invalid}">

				<ng-container [ngSwitch]="field.fieldType" uiControl>
					<input type="text" *ngSwitchCase="FieldType.Text" [name]="'customField' + i" #input="ngModel" [attr.aria-label]="field.name" [(ngModel)]="field.value" [required]="field.required" vbUiTextInput/>
					<vb-ui-select *ngSwitchCase="FieldType.Select">
						<select [name]="'customField' + i" [(ngModel)]="field.value" #input="ngModel" #selectElement [required]="field.required">
							<option [ngValue]="null"></option>
							<option *ngFor="let option of field.options" [value]="option">
								{{option}}
							</option>
						</select>
					</vb-ui-select>
				</ng-container>
				<div class="required-field"></div>
				<div *ngIf="input?.invalid" role="alert" uiValidation vbUiErrorMessage>
					{{ 'ThisFieldIsRequired' | translate }}
				</div>
			</vb-ui-form-group>
		</fieldset>


		<vb-toolbar edge-padding class="section-header">
			<div flex="fill"></div>
			<div flex>
				<button type="button" class="btn btn-cancel margin-right-5" (click)="cancel()">{{ 'Cancel' | translate }}</button>
				<button type="submit" class="btn btn-primary" [disabled]="form?.invalid || !hasAnyOperations || !VideoSelectionModel.selectionCountValid">
					{{ 'Save' | translate }}
				</button>
			</div>
		</vb-toolbar>
	</form>
</div>
