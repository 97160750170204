import { NgModule } from '@angular/core';

import { IUserContextAccount } from '../security/UserContext.Service';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

@NgModule()
export class GainsightModule {

	constructor(
		public UserContext: UserContextService
	) {
		const gainsightKey = BootstrapContext.gainsightTagKey;
		if (!gainsightKey) {
			return;
		}

		(function(n, t, a, e, co) {
			var i='aptrinsic';
			n[i] = n[i] || function(...args) {
				(n[i].q = n[i].q || []).push(args);
			}, n[i].p = e;
			n[i].c = co;
			var r = t.createElement('script');
			r.async = !0, r.src = a + '?a=' + e;
			var c = t.getElementsByTagName('script')[0];
			c.parentNode.insertBefore(r, c);
		}(window, document, 'https://web-sdk.aptrinsic.com/api/aptrinsic.js', gainsightKey));

		this.UserContext.user$.subscribe(() => this.onUserContextChange());
	}

	private onUserContextChange(): void {
		const account = this.UserContext.getAccount();
		const id = this.UserContext.getUser().uniqueId;
		const userRoles = this.UserContext.getUser().roles;
		const isInternal = this.UserContext.isUserAuthenticated();
		if (!id) {
			return;
		}
		(window as any).aptrinsic('identify',
			{ id, userRoles, isInternal },
			{
				id: account.id,
				name: account.name
			}
		);
	}
}
