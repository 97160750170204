
<vb-media-teams-header *ngIf="team?.id" [team]="team">
</vb-media-teams-header>

<div class="container-fluid flex-navigation-bar-wrap margin-top-10">
	<div [ngClass]="styles.mediaToolbarContainer">

		<div [ngClass]="styles.mediaToolbarNav" [ngSwitch]="baseStateName">
			<span class="type-bold" *ngSwitchCase="'portal.media.all'">{{ 'Media_AllVideos' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.browse'">{{ 'Media_BrowseCategories' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.my-playlists'">{{ 'Media_Menu_Playlists' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.uploads'">{{ 'Media_UserVideos' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.expirations'">{{ 'Media_Expirations' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.pending-videos'">{{ 'PendingApproval' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.edit'">{{ 'Media_BulkEdit' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.my-subscriptions'">{{ 'Media_MySubscriptions' | translate }}</span>

			<span *ngSwitchCase="'portal.media.category-detail'">

				<span *ngIf="MediaStateService.baseCategoryId; else browseCategoriesLink" class="type-bold">
					{{ 'Media_BrowseCategories' | translate }}
				</span>
				<ng-template #browseCategoriesLink>
					<a class="type-bold" uiSref="portal.media.browse">
						{{ 'Media_BrowseCategories' | translate }}
					</a>
				</ng-template>
				<span *ngFor="let path of categoryPath; let $index = index; let $last = last" [ngClass]="{ 'hidden-xs': !$last, active: $last }">
					<span class="glyphicons chevron-right"></span>
					<span *ngIf="$last || $index < baseCategoryIndex; else pathLink">
						{{ path.name || 'Media_Uncategorized' | translate }}
					</span>
					<ng-template #pathLink>
						<a uiSref="." [uiParams]="{ categoryId: path.id }" [uiOptions]="{ reload: true, relative: $state.current.name }">
							{{path.name }}
						</a>
					</ng-template>
				</span>
			</span>
			<span *ngSwitchCase="'portal.media.playlists-featured-playlist'">
				<a class="type-bold" uiSref="portal.media.my-playlists">
					{{ 'Media_Menu_Playlists' | translate }}
				</a>
				<span class="glyphicons chevron-right"></span>
				{{ 'Media_Videos_Playlists_FeaturedVideos' | translate }}
			</span>
			<span *ngSwitchCase="'portal.media.playlist-detail'">
				<a class="type-bold" uiSref="portal.media.my-playlists">
					{{ 'Media_Menu_Playlists' | translate }}
				</a>
				<span class="glyphicons chevron-right"></span>
				<span [hidden]="isPlaylistEdit">
					{{ searchResultsState.playlist.name }}
					<a class="theme-accent-link" (click)="toggleEditPlaylist()" *ngIf="searchResultsState.playlist.canEdit">
						<small>{{ 'Media_Playlists_Edit' | translate }}</small>
					</a>
				</span>
				<vb-ui-inline-text-edit class="box-inline-block" [required]="true" [value]="searchResultsState.playlist.name" *ngIf="isPlaylistEdit" (onSubmit)="renamePlaylist($event)" (onCancel)="toggleEditPlaylist()">
				</vb-ui-inline-text-edit>
			</span>
			<span *ngSwitchCase="'portal.media.search'">
				<span class="type-bold">
					{{ 'Media_Videos_SearchResults' | translate }}
				</span>
				<span class="glyphicons chevron-right"></span>
				{{ searchResultsState.searchQuery }}
			</span>
			<span *ngSwitchCase="'portal.media.my-subscriptions'">
				<a *ngIf="MediaFeaturesService.accountFeatures?.enableContentNotifications || MediaFeaturesService.accountFeatures?.enableSpark" class="float-right" vbUiBtnPrimaryNgx [uiSref]="'portal.manageSubscriptions'">
					<span class="glyphicons bell theme-accent-txt"></span>
					{{ 'ManageSubscriptions' | translate }}
				</a>
			</span>
		</div>

		<div [ngClass]="styles.mediaToolbarControls">
			<div *ngIf="searchResultsState.mediaType">
				<div class="hidden-xs type-bold" [ngPlural]="searchResultsState.mediaCount">
					<ng-template ngPluralCase="=1">
						{{ mediaLabels[searchResultsState.mediaType][0] | translate }}
					</ng-template>
					<ng-template ngPluralCase="other">
						{{ mediaLabels[searchResultsState.mediaType][1] | translate : { '0': searchResultsState.mediaCount } }}
					</ng-template>

					<ng-container *ngIf="selectedCount">
						{{ 'Media_BulkEdit_SelectionCount' | translate : { '0': selectedCount | number } }}
					</ng-container>
				</div>
				<div *ngIf="selectedCount > videoSelectionLimit" class="text-align-left" role="alert" vbUiErrorMessage>
					{{ 'Media_BulkEdit_SelectionLimitMessage' | translate: { '0': videoSelectionLimit | number } }}
				</div>
			</div>
			<div [ngClass]="styles.mediaToolbarControlBtns">
				<sign-in-btn class="margin-right-10" *ngIf="MediaStateService.showSignInBtn" (onClick)="onSignInClick()">
				</sign-in-btn>

				<vb-user-subscriptions class="margin-right-10" [category]="category" [team]="team">
				</vb-user-subscriptions>

				<a class="btn margin-right-10" [ngClass]="styles.btn" *ngIf="showPendingApproval" uiSref="portal.media.pending-videos" uiSrefActive="active">
					<span>
						<span class="text-label">{{ 'PendingApproval' | translate }}</span>
						<span class="badge badge-pending hidden-sm hidden-xs">{{ pendingVideoCount }}</span>
					</span>
				</a>
				<button class="btn btn-primary margin-right-10" *ngIf="playlist" uiSref="portal.video-playlist-playback" [uiParams]="{ videoId: playlist.videos[0]?.id, playlistId: searchResultsState.playlist.id }" [disabled]="playlist.videos.length === 0" [attr.aria-label]="'PlayAll' | translate">
					<span class="glyphicons play" [ngClass]="styles.playIcon"></span>
					{{'PlayAll' | translate }}
				</button>

				<a *ngIf="category && category.canEdit" class="btn margin-right-10" [ngClass]="styles.btn" uiSref="portal.edit-category" [uiParams]="{parentCategoryId: category.id}">
					{{ 'Admin_AddSubcategory' | translate }}
				</a>

				<a *vbAuthorizationKey="'media.addCategory'" class="btn margin-right-10" [ngClass]="styles.btn" [hidden]="baseStateName !== 'portal.media.browse'" uiSref="portal.edit-category">
					{{ 'Admin_AddCategory' | translate }}
				</a>

				<div class="btn-group" [ngClass]="styles.btnGroup" *ngIf="!MediaStateService.mediaState.isViewModeDisabled">

					<vb-sort-filter class="btn" *ngIf="MediaStateService.mediaState.viewMode === 'tiles' && !MediaStateService.mediaState.isSortingDisabled" [sortField]="MediaStateService.getSortField()" [isSearch]="searchResultsState.searchQuery != null" [showSortRecommended]="MediaStateService.showSortRecommended">
					</vb-sort-filter>

					<button type="button" class="btn" (click)="setViewMode('tiles')" [ngClass]="{active: MediaStateService.mediaState.viewMode === 'tiles'}" [attr.aria-label]="'TileView' | translate " [tooltip]="'GridView' | translate" placement="bottom">
						<span class="glyphicons show_big_thumbnails"></span>
					</button>

					<button type="button" class="btn" (click)="setViewMode('table')" [ngClass]="{active: MediaStateService.mediaState.viewMode === 'table'}" [attr.aria-label]="'ListView' | translate " [tooltip]="'ListView' | translate" placement="bottom right">
						<span class="glyphicons show_lines"></span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
