<vb-loading-spinner [hidden]="!(statusHandler.status.loading)" [block]="true" [size]="'large'">
</vb-loading-spinner>

<vb-confirmation-dialog [title]="'DeleteTeam' | translate" [cancelText]="'Cancel' | translate" [actionText]="'Delete' | translate" [themed]="true">
	<div>{{ 'DeleteTeam' | translate }}: <b>{{ team.name }}</b> </div>
	<div class="margin-top-15">{{ 'Teams_DeleteNote' | translate }}</div>
</vb-confirmation-dialog>

<section class="row" [hidden]="!(statusHandler.status.error)">
	<div class="col-xs-12">
		<div class="panel panel-default padding-full-1">
			<div class="centered-copy">
				<div class="alert alert-danger">
					<span class="glyphicons circle_exclamation_mark"></span>
					{{ 'UnhandledError' | translate }}
				</div>
			</div>
		</div>
	</div>
</section>

<section class="row admin-team" [ngClass]="{'from-media-state': !!previousState}" [hidden]="!(statusHandler.status.active)">
	<div class="col-xs-12">
		<form autocomplete="off" fileUpload method="post" enctype="multipart/form-data" [singleFileUploads]="true" (onAdd)="setLogoImageFile($event)" class="form-horizontal" name="teamForm" #teamForm="ngForm" (submit)="submit()">
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4>{{team.name||'Admin_Team_NewTeam' | translate}}</h4>
					<vb-toolbar flex-wrap="false">
						<a class="btn btn-admin btn-white btn-back margin-right-5 btn-icon-left" [hidden]="!(portalTeamAdminAccess)" uiSref="portal.admin.user-access.teams" [uiParams]="{accountId: accountId}">
							<span class="glyphicons chevron-left"></span>
							{{ 'Admin_Teams' | translate }}
						</a>
						<div flex="fill" [hidden]="!(!statusHandler.status.loading)">
							<div class="btn-toolbar float-right">
								<button type="button" [hidden]="!(team.id)" class="btn btn-admin btn-white" (click)="onDeleteTeam()">
									<span class="glyphicons bin"></span>
									{{ 'Delete' | translate }}
								</button>
								<button type="button" class="btn btn-admin btn-white" (click)="cancel()">
									{{ 'Cancel' | translate }}
								</button>
								<button type="button" (click)="createThenReset()" [hidden]="team.id" [disabled]="teamForm.invalid" class="btn btn-admin btn-white">
									{{ 'Admin_Team_CreateAndAddAnotherTeam' | translate }}
								</button>
								<button type="submit" [disabled]="teamForm.invalid" class="btn btn-primary">
									<span [hidden]="team.id">
										{{ 'Admin_Team_CreateTeam' | translate }}
									</span>
									<span [hidden]="!(team.id)">
										{{ 'Admin_Team_SaveTeam' | translate }}
									</span>
								</button>
							</div>
						</div>
						<div flex="fill" class="mobile-hidden text-align-right" [hidden]="!(statusHandler.status.loading)">
							{{ 'Processing' | translate }}
						</div>
					</vb-toolbar>
				</div>

				<div class="panel panel-default panel">
					<div class="panel-body teams-panel">
						<div class="row media-state-row">
							<div class="col-sm-6 col-md-4">
								<!-- Team Name -->
								<vb-ui-form-group class="form-group">
									<label for="teamName" class="util-uppercase" #uiLabel>
										{{ 'Admin_Team_TeamName' | translate }}
									</label>
									<input type="text" class="form-control" [placeholder]="'Admin_Team_NewTeam' | translate " name="teamName" [(ngModel)]="team.name" [attr.aria-label]="'Admin_Team_TeamName' | translate " #teamName="ngModel" vbRequired [vbClearOnChange]="'teamNameInUse'" autocomplete="off" uiControl trim="blur">
									<div *ngIf="teamName.invalid" role="alert" vbUiErrorMessage uiValidation>
										<div *ngIf="teamName.errors.teamNameInUse">
											{{ 'Admin_Team_TeamNameInUse' | translate }}
										</div>
										<div *ngIf="teamName.errors.required">
											{{ 'ThisFieldIsRequired' | translate }}
										</div>
									</div>
								</vb-ui-form-group>
								<!-- /Team Name -->

								<!-- Team Description -->
								<div class="form-group">
									<h4 class="util-uppercase">
										{{ 'Admin_Team_TeamDescription' | translate }}
									</h4>
									<textarea type="text" class="form-control" name="description" [attr.aria-label]="'Admin_Team_TeamDescription' | translate " rows="8" style="resize:none" [(ngModel)]="team.description">
									</textarea>
								</div>
								<!-- /Team Description -->

								<!-- Team Logo -->
								<div class="form-group" [ngClass]="{
									'has-error': logoImageFileUpload.invalid,
									'has-success': !logoImageFileUpload.invalid}">
									<h4 class="util-uppercase">
										{{ 'Admin_Team_Logo_Image' | translate }}
									</h4>

									<div class="btn-file-wrapper">
										<div class="btn btn-white btn-file" [attr.disabled]="addingImage">
											<span class="glyphicons file margin-right"></span>
											{{ 'ChooseFile' | translate }}
											<input type="file" [attr.aria-label]="'Admin_Team_Logo_Image' | translate ">
										</div>
									</div>

									<input type="hidden" name="logoImageFileUpload" #logoImageFileUpload="ngModel" [(ngModel)]="logoFile.name" class="form-control">

									<div class="preview-wrapper" [hidden]="!(imageUrl || image.name)">
										<div class="row">
											<div class="col-xs-9">
												<div class="team-logo logo-preview">
													<img class="logo" [src]="imageUrl" [hidden]="!(imageUrl)" [alt]="'ThumbnailForAria' | translate : { '0': image.name } "/>
													<span [hidden]="imageUrl">{{image.name}}</span>
												</div>
											</div>
											<div class="col-xs-3">
												<div class="btn-file-wrapper">
													<button type="button" class="btn btn-icon-left btn-white" (click)="removeLogoImage()" [hidden]="!(!logoImageFileUpload.invalid)">
														<span>{{ 'Admin_Team_Logo_Delete' | translate }}</span>
													</button>
												</div>
											</div>
										</div>
									</div>
									<div *ngIf="logoImageFileUpload.invalid">
										<label validation="fileType" class="control-label" [hidden]="!(logoImageFileUpload.errors?.fileType)">
											<span class="glyphicons circle_exclamation_mark"></span>
											{{ 'ImageSelector_FileExtensionError' | translate }}: {{imageFileExtensions.join()}}
										</label>
									</div>
									<span class="logo-info">{{ 'Admin_Team_Logo_Size_Description' | translate }}</span>
								</div>
								<!-- /Team Logo -->

								<!-- Team Title Background Title -->
								<div class="form-group">
									<h4 class="util-uppercase">
										{{ 'Branding_Colors' | translate }}
									</h4>
									<span class="color-title">{{ 'Admin_Team_Background_Title' | translate }}</span>
									<!-- color picker -->
									<div class="position-relative margin-top-10 margin-bottom-10">
										<div class="combined-toolbar vertical-align-top">
											<vb-ui-color-selector [accessibilityLabel]="'Admin_Team_Background_Title' | translate" name="colorPicker" [(ngModel)]="team.bgColor" [defaultColor]="defaultColor">
											</vb-ui-color-selector>
										</div>
									</div>
									<!-- /color picker -->
									<!-- font color selection -->
									<div class="color-title">{{ 'Admin_Team_Font_Color' | translate }}</div>
									<div vbUiRadioBtnGroup class="vb-input-field btn-group btn-group-toggle box-block margin-bottom-10 margin-top-10">
										<button type="button" class="btn btn-white" name="themecolor" [ngClass]="{'active': team.themeColor === '#fff'}" [(ngModel)]="team.themeColor" [vbUiRadioBtn]="'#fff'" [notThemed]="true">
											{{ 'Admin_Team_Font_Color_Light' | translate }}
										</button>
										<button type="button" class="btn btn-white" name="themecolor" [ngClass]="{'active': team.themeColor === '#000'}" [(ngModel)]="team.themeColor" [vbUiRadioBtn]="'#000'" [notThemed]="true">
											{{ 'Admin_Team_Font_Color_Dark' | translate }}
										</button>
									</div>
									<!-- /font color selection -->
								</div>
								<!-- /Team Title Background -->

								<!-- Team Preview -->
								<div class="form-group" [hidden]="!team.name">
									<h4 class="util-uppercase">{{ 'Admin_Team_Preview' | translate }}</h4>
									<div class="preview-wrapper" [ngStyle]="{background: team.bgColor}">
										<div class="row">
											<div class="logo-preview-wrapper col-xs-3" [hidden]="!(imageUrl)">
												<img class="margin-top-minus10 logo" [src]="imageUrl" [hidden]="!imageUrl"/>
											</div>
											<div [ngClass]="{'col-xs-9': imageUrl,
															'col-xs-12': !imageUrl}">
												<h3 class="company-name-preview" [ngStyle]="{color: team.themeColor}">{{team.name}}</h3>
											</div>
										</div>
									</div><!--/preview -wrapper -->
								</div>
								<!-- /Team Preview -->
							</div>

							<!-- Assign User or Group to Team -->
							<div class="col-sm-6 col-md-8">
								<h4 class="util-uppercase">{{ 'Admin_Team_AssignItems' | translate }}</h4>

								<ng-template #optionRow let-item="item" let-insight="insight">
									<access-entity-option-row [item]="item" [insight]="insight"></access-entity-option-row>
								</ng-template>

								<ng-template #assignedRow let-item="item" let-insight="insight">
									<team-admin-assigned-row [item]="item" [insight]="insight"></team-admin-assigned-row>
								</ng-template>

								<insight name="teamAccessEntities" [insightOptions]="teamInsightOptions" [(ngModel)]="team.teamMembers" [optionTemplate]="optionRow" [assignedTemplate]="assignedRow">
								</insight>
							</div>
							<!-- /Assign User or Group to Team -->
						</div>
					</div>
				</div>

				<div class="panel-footer">
					<vb-toolbar flex-wrap="false">
						<a class="btn btn-admin btn-white btn-back margin-right-5 btn-icon-left" [hidden]="!(portalTeamAdminAccess)" uiSref="portal.admin.user-access.teams" [uiParams]="{accountId: accountId}">
							<span class="glyphicons chevron-left"></span>
							{{ 'Admin_Teams' | translate }}
						</a>
						<div flex="fill" [hidden]="!(!statusHandler.status.loading)">
							<div class="btn-toolbar float-right">
								<button type="button" class="btn btn-admin btn-white" (click)="cancel()">
									{{ 'Cancel' | translate }}
								</button>
								<button type="button" (click)="createThenReset()" [hidden]="team.id" [disabled]="teamForm.invalid" class="btn btn-admin btn-white">
									{{ 'Admin_Team_CreateAndAddAnotherTeam' | translate }}
								</button>
								<button type="submit" [disabled]="teamForm.invalid" class="btn btn-primary">
									<span [hidden]="team.id">
										{{ 'Admin_Team_CreateTeam' | translate }}
									</span>
									<span [hidden]="!(team.id)">
										{{ 'Admin_Team_SaveTeam' | translate }}
									</span>
								</button>
							</div>
						</div>
						<div class="mobile-hidden text-align-right" flex="fill" [hidden]="!(statusHandler.status.loading)">
							{{ 'Processing' | translate }}
						</div>
					</vb-toolbar>
				</div>
			</div>
		</form>
	</div>
</section>
