<div class="row tile-grid" [hidden]="!(videoSearchResults.status.active)">

	<ng-container *ngFor="let category of videoSearchResults.categories">
		<category-tile class="col-xl-2 col-lg-3 col-sm-4 col-tablet-sm-6 col-xs-12" *ngIf="category.canEdit || category.videoCount" [category]="category" [thumbnails]="category.thumbnails">
		</category-tile>
	</ng-container>

	<ng-container *ngIf="videoSearchResults.uncategorizedCatEntry">
		<category-tile class="col-xl-2 col-lg-3 col-sm-4 col-tablet-sm-6 col-xs-12" [category]="videoSearchResults.uncategorizedCatEntry" [thumbnails]="videoSearchResults.uncategorizedCatEntry.thumbnails">
		</category-tile>
	</ng-container>

	<video-tile *ngFor="let video of videoSearchResults.videos" [video]="video" [config]="videoSearchResults.videoPlaybackConfig" class="col-xl-2 col-lg-3 col-sm-4 col-tablet-sm-6 col-xs-12" [mediaFeatures]="videoSearchResults.mediaFeatures" [hasMediaEditAuth]="videoSearchResults.hasMediaEditAuth" [userId]="videoSearchResults.userId" [isGuest]="videoSearchResults.isGuest">
	</video-tile>

</div>