
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { VbUiColorSelectorModule } from 'rev-shared/ui/color-selector/VbUiColorSelector.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';

import { teamStates } from './StateDeclarations';
import { TeamListingsService } from './TeamListings.Service';
import { TeamsListComponent } from './TeamsList.Component';

@NgModule({
	imports: [
		ButtonsAngularModule,
		CommonModule,
		FormsModule,
		HttpClientModule,
		TextInputAngularModule,
		TranslateModule,
		UIRouterModule.forChild({
			states: teamStates
		}),
		VbUiColorSelectorModule
	],
	providers: [
		TeamListingsService
	],
	declarations: [
		TeamsListComponent
	]
})
export class TeamsAngularModule {}
