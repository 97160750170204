import { Component, Input } from '@angular/core';
import { GridApi } from '@ag-grid-community/core';
import { TranslateService } from '@ngx-translate/core';

import { SparkService } from 'rev-portal/media/spark/Spark.Service';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { SubscribeSparkCategoryComponent } from 'rev-portal/media/spark/SubscribeSparkCategory.Component';
import { UserSubscriptionsService } from 'rev-shared/userSubscription/UserSubscriptions.Service';

import { CategoryCellRendererComponent } from './CategoryCellRenderer.Component';
import { IManageSubscriptionItem } from './IManageSubscriptionItem';
import { ToggleClickEvent, TogglesCellRendererComponent, TOGGLES_CELL_TOGGLE } from '../../shared/ui/dataGrid/cellRenderers/TogglesCellRenderer.Component';
import styles from './ManageSubscriptions.module.less';

@Component({
	selector: 'manage-subscriptions',
	templateUrl: './ManageSubscriptions.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class ManageSubscriptionsComponent {
	@Input() public categories: IManageSubscriptionItem[];
	@Input() public channels: IManageSubscriptionItem[];

	public readonly styles = styles;
	public readonly TogglesCellRendererComponent = TogglesCellRendererComponent;
	public readonly CategoryCellRendererComponent = CategoryCellRendererComponent;

	private channelGridApi: GridApi;
	private categoryGridApi: GridApi;

	constructor(
		private DialogService: DialogService,
		public MediaFeaturesService: MediaFeaturesService,
		private SparkService: SparkService,
		private UserSubscriptionsService: UserSubscriptionsService,
		private translate: TranslateService
	) {}

	public onChannelGridReady(gridApi: GridApi): void {
		this.channelGridApi = gridApi;
		this.channelGridApi.addEventListener(TOGGLES_CELL_TOGGLE, event => this.onNotificationToggle(event));
	}

	public onCategoryGridReady(gridApi: GridApi): void {
		this.categoryGridApi = gridApi;
		this.categoryGridApi.addEventListener(TOGGLES_CELL_TOGGLE, event => this.onNotificationToggle(event));
	}

	private onNotificationToggle(event: ToggleClickEvent): void {
		event.field === 'emailNotifications' ?
			this.UserSubscriptionsService.saveContentSubscription(
				event.data.id,
				event.data.type,
				event.data.emailNotifications
			) :
			this.handleSparkSubscription(event.data);
	}

	private handleSparkSubscription(manageSubscriptionItem: IManageSubscriptionItem): void {
		manageSubscriptionItem.webexTeamsNotifications ?
			this.subscribeSpark(manageSubscriptionItem) :
			this.unsubscribeSpark(manageSubscriptionItem);
	}

	private subscribeSpark(manageSubscriptionItem: IManageSubscriptionItem): Promise<any> {
		return this.DialogService.getDialog<SubscribeSparkCategoryComponent>('SubscribeToCategoryInSpark').open({
			initialState: {
				category: manageSubscriptionItem
			},
		}).content.result
			.catch(err => {
				manageSubscriptionItem.webexTeamsNotifications = false;
				if (err) {
					console.error('error in sparck subscription', err);
				}
			});
	}

	private unsubscribeSpark(manageSubscriptionItem: IManageSubscriptionItem): Promise<any> {
		return this.DialogService.openConfirmationDialog({
			title: this.translate.instant('Media_Videos_Spark_UnsubscribeTitle'),
			message: this.translate.instant('Media_Videos_Spark_UnsubscribeMessage', { '0': manageSubscriptionItem.label }),
			cancelText: this.translate.instant('Cancel'),
			actionText: this.translate.instant('Ok')
		}).result
			.then(
				() => this.SparkService.cancelCategorySubscription(manageSubscriptionItem.id),
				err => {
					manageSubscriptionItem.webexTeamsNotifications = true;
					if (err) {
						console.error('error in sparck unsubscription', err);
					}
				}
			);
	}
}
