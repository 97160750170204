import { Component, Input, OnChanges } from '@angular/core';

import styles from './Search.module.less';
import { ICategory, IImageLink } from 'rev-shared/media/Media.Contract';

@Component({
	selector: 'category-tile',
	templateUrl: './CategoryTile.Component.html'
})
export class CategoryTileComponent implements OnChanges {
	@Input() public category: ICategory;
	@Input() public thumbnails: IImageLink[];

	public readonly styles = styles;
	public links: IImageLink[];

	public ngOnChanges(): void {
		this.links = Array.from({ length: 4 }).map((_, i) => (this.thumbnails?.[i] || {} as IImageLink));
	}
}
