import { Component, Input, OnInit, AfterViewInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'underscore';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { ApprovalStatus } from 'rev-shared/media/MediaConstants';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { UtilService } from 'rev-shared/util/Util.Service';

import { MediaStateService } from '../MediaState.Service';

import './playlist-detail.less';
import { orderBy } from 'rev-shared/util/SortUtil';

@Component({
	selector: 'playlist-details-table',
	templateUrl: './PlaylistDetailsTable.Component.html'
})
export class PlaylistDetailsTableComponent implements OnInit, OnDestroy {
	@Input() public canEdit: boolean;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public playlist: any;
	@Input() public userId: string;

	@ViewChild('removeVideosConfirmation')
	public removeVideosConfirmation: VbConfirmationDialogComponent;

	public all: boolean;
	private onKeydownHandler: any;
	private previousSort: any;

	public selectedVideos: any[];
	public sort: any;
	public approvalStatusOptions: typeof ApprovalStatus;

	constructor(
		private $state: StateService,
		private MediaStateService: MediaStateService,
		private PlaylistService: PlaylistService,
		private Util: UtilService,
		private Translate: TranslateService
	) {}

	public ngOnInit(): void {
		this.all = false;
		this.selectedVideos = [];
		this.sort = {
			property: 'playlistIndex',
			ascending: true
		};
		this.approvalStatusOptions = ApprovalStatus;

		this.onKeydownHandler = (event: any) => this.onKeydown(event);
		document.addEventListener('keydown', this.onKeydownHandler);
		this.MediaStateService.searchResultsState.getSelectedCount = () => this.selectedVideos?.length;

		if (this.playlist.featured) {
			this.playlist.name = this.Translate.instant('Media_Videos_Playlists_FeaturedVideos');
		}
	}

	public ngOnDestroy(): void {
		document.removeEventListener('keydown', this.onKeydownHandler);
	}

	public hasEditVideoAuth(video: any) {
		return video && (video.editAcl || []).includes(this.userId);
	}

	public removeSelectedVideos(): void {
		const playlist = this.playlist;
		const videos = playlist.videos;
		const videosToKeep = videos.filter((video: any) => !video.selected);

		if(videosToKeep.length === videos.length) {
			return;
		}

		this.removeVideosConfirmation.open().result
			.then(() => {
				playlist.videos = videosToKeep;
				this.updateSelectedVideos();

				this.savePlaylist({
					id: playlist.id,
					name: playlist.name,
					videos: videosToKeep
				})
					.catch(() => {
						videos.forEach((video: any) => {
							if(video.selected) {
								video.selected = false;
								video.error = true;
							}
						});
						this.previousSort = null;
						playlist.videos = videos;
						this.updateSelectedVideos();
					});
			})
			.catch(err => {
				if (err) {
					console.error(err);
				}
			});
	}

	public savePlaylistOrder(event: CdkDragDrop<string[]>): void {
		const previousVideos: any[] = this.playlist.videos?.map(video => video);

		moveItemInArray(this.playlist.videos, event.previousIndex, event.currentIndex);

		this.playlist.videos.forEach((v: any, index: number) => {
			index = this.sort.ascending ? index : this.playlist.videos.length - 1 - index;
			v.playlistIndex = index;
		});

		this.savePlaylist(this.playlist)
			.catch(() => {
				this.previousSort = null;
				this.playlist.videos = previousVideos;
			});
	}

	public sortPlaylists(sortProperty: string): void {
		if (this.sort.property === sortProperty) {
			this.sort.ascending = !this.sort.ascending;
		}

		this.sort.property = sortProperty;
		this.playlist.videos = orderBy(this.playlist.videos, video => video[this.sort.property], this.sort.ascending);
	}

	public toggleAll(): void {
		this.all = !this.all;

		this.playlist.videos.forEach((video: any) => video.selected = this.all);

		this.updateSelectedVideos();
	}

	public toggleSelection(video: any): void {
		if (!this.canEdit) {
			return;
		}

		video.selected = !video.selected;

		this.updateSelectedVideos();
	}

	private onKeydown(event: any): void {
		if(event.which === 46) {
			this.removeSelectedVideos();
		}
	}

	private savePlaylist(playlist: any): any {
		const sortedVideoIds: string[] = sortBy(playlist.videos, 'playlistIndex').map((v: any) => v.id);

		if (this.playlist.isFeatured) {
			return this.PlaylistService.modifyFeaturedVideos(sortedVideoIds);
		}

		return this.PlaylistService.modifyPlaylist({
			playlistId: playlist.id,
			name: playlist.name,
			videoIds: sortedVideoIds
		});
	}

	private updateSelectedVideos(): void {
		this.selectedVideos = this.playlist.videos.filter((video: any) => video.selected);
	}
}
