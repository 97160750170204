import {
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';

import { ApprovalProcessService } from 'rev-shared/media/approvalProcess/ApprovalProcess.Service';
import { ApproverTemplateInfo } from 'rev-shared/media/approvalProcess/ApproverTemplateInfo';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { StateChangeStatus } from 'rev-shared/util/StateChangeStatus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { SparkService } from 'rev-portal/media/spark/Spark.Service';

import { MediaStateService } from './MediaState.Service';

@Component({
	selector: 'media-container',
	templateUrl: './MediaContainer.Component.html'
})
export class MediaContainerComponent implements OnDestroy, OnInit {
	@Input() public approverProcessTemplates: ApproverTemplateInfo[];
	@Input() public expirationsVideoCount: number;
	@Input() public mediaFeatures: IMediaFeatures;
	@Input() public teamBranding: any;
	@Input() public uploadedVideoCount: number;

	public toolbarHeight: number;

	private accountId: string;
	private approvalProcessUnsubscribe: IUnsubscribe;
	private isCategoriesEnabled: boolean;
	private mediaState: any;
	public pendingVideoCount: number;
	private sparkMonitorUnsubscribe: IUnsubscribe;
	private userVideosUnsubscribe: IUnsubscribe;

	constructor(
		private ApprovalProcessService: ApprovalProcessService,
		private PushBus: PushBus,
		private SparkService: SparkService,
		private UserContext: UserContextService,
		public MediaStateService: MediaStateService,
		public StateChangeStatus: StateChangeStatus,
	) {}

	public ngOnInit(): void {
		this.accountId = this.UserContext.getAccount().id;
		this.isCategoriesEnabled = this.mediaFeatures.enableCategories;
		this.mediaState = this.MediaStateService.getMediaState();

		if (this.UserContext.isUserAuthenticated()) {
			const onCreatedHandler = () => this.incrementUploadedVideoCount();

			this.userVideosUnsubscribe = this.PushBus.subscribe(this.UserContext.getUser().id, 'Media.Videos', {
				VideoCreated: onCreatedHandler,
				VideoLinkCreated: onCreatedHandler,
				VideoAddedToAccountForVcRecording: onCreatedHandler
			});

			if (this.mediaFeatures.enableSpark) {
				this.sparkMonitorUnsubscribe = this.SparkService.monitorCategorySubscriptions();
			}
		}

		if (this.approverProcessTemplates.length) {
			this.approvalProcessUnsubscribe = this.ApprovalProcessService
				.monitorPendingApproval(this.approverProcessTemplates, count => this.pendingVideoCount = count);
		}
	}

	public ngOnDestroy(): void {
		if (this.userVideosUnsubscribe) {
			this.userVideosUnsubscribe();
		}

		if (this.sparkMonitorUnsubscribe) {
			this.sparkMonitorUnsubscribe();
		}

		if (this.approvalProcessUnsubscribe) {
			this.approvalProcessUnsubscribe();
		}
	}

	private incrementUploadedVideoCount(): void {
		this.uploadedVideoCount++;
	}

	public onToolbarHeightChange(height: number): void {
		window.setTimeout(() => this.toolbarHeight = height);
	}
}
